import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './views/home/home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { HomeadmComponent } from './adm/homeadm/homeadm.component';
import { AuthenticationComponent } from './layout/authentication/authentication.component';
import { HomelayoutComponent } from './layout/homelayout/homelayout.component';
import { httpInterceptorProviders } from './http-interceptors';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { ContasComponent } from './adm/contas/contas.component';
import { RelatoriosComponent } from './adm/relatorios/relatorios.component';
import { ParceirosComponent } from './adm/parceiros/parceiros.component';
import { TrasacoesComponent } from './adm/trasacoes/trasacoes.component';
import { NgxPaginationModule } from 'ngx-pagination'; // Módulo da dependência de paginação
import { EditarcontasComponent } from './adm/contas/editarcontas/editarcontas.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosComponent } from './adm/usuarios/usuarios.component';
import { EditarusuariosComponent } from './adm/usuarios/editarusuarios/editarusuarios.component';
import { CarteirasComponent } from './adm/carteiras/carteiras.component';
import { EditarcarteirasComponent } from './adm/carteiras/editarcarteiras/editarcarteiras.component';
import { EditarcarteiraspComponent } from './adm/carteiras/editarcarteirasp/editarcarteirasp.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NovacarteirasComponent } from './adm/carteiras/novacarteiras/novacarteiras.component';
import { PermissoesComponent } from './adm/usuarios/permissoes/permissoes.component';
import { CriarusuariosComponent } from './adm/usuarios/criarusuarios/criarusuarios.component';
import { CriarpermissaoComponent } from './adm/usuarios/criarpermissao/criarpermissao.component';

import { TarifasComponent } from './adm/tarifas/tarifas.component';
import { EditarTarifasComponent } from './adm/tarifas/editar-tarifas/editar-tarifas.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ChartsModule } from 'ng2-charts';
import { CriarTarifasComponent } from './adm/tarifas/criar-tarifas/criar-tarifas.component';
import { LimitesComponent } from './adm/usuarios/limites/limites.component';
import { LimitesContasComponent } from './adm/contas/limites-contas/limites-contas.component';
import { SenhaComponent } from './views/senha/senha.component';
import { DocumentosContaComponent } from './adm/contas/documentos-conta/documentos-conta.component';
import { BeneficiosComponent } from './adm/beneficios/beneficios.component';
import { BeneficiosvalorComponent } from './adm/contas/beneficios/beneficios.component';
import { BeneficiariosComponent } from './adm/beneficios/beneficiarios/beneficiarios.component';
import { CriarBeneficiosComponent } from './adm/beneficios/criar-beneficios/criar-beneficios.component';
import { EditarBeneficiosComponent } from './adm/beneficios/editar-beneficios/editar-beneficios.component';
import { BeneficiarioVincularComponent } from './adm/beneficios/beneficiarios/beneficiario-vincular/beneficiario-vincular.component';
import {PasswordComponent} from './shared/components/password/password.component';
import { LojistasComponent } from './adm/beneficios/lojistas/lojistas.component';
import { CriarContaComponent } from './adm/contas/criar-conta/criar-conta.component';
import { ContaTransacaoComponent } from './adm/contas/conta-transacao/conta-transacao.component';
import { ListarCadastroComponent } from './adm/cadastros/listar-cadastro/listar-cadastro.component';
import { EditarCadastroComponent } from './adm/cadastros/editar-cadastro/editar-cadastro.component';
import { DocumentosCadastroComponent } from './adm/cadastros/documentos-cadastro/documentos-cadastro.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ConvitesComponent } from './adm/convites/convites.component';
import { CriarContapjComponent } from './adm/contas/criar-contapj/criar-contapj.component';
import { ConfiguracaoComponent } from './adm/configuracao/configuracao/configuracao.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeadmComponent,
    AuthenticationComponent,
    HomelayoutComponent,
    ContasComponent,
    RelatoriosComponent,
    ParceirosComponent,
    TrasacoesComponent,
    EditarcontasComponent,
    UsuariosComponent,
    EditarusuariosComponent,
    CarteirasComponent,
    EditarcarteirasComponent,
    EditarcarteiraspComponent,
    NovacarteirasComponent,
    PermissoesComponent,
    CriarusuariosComponent,
    CriarpermissaoComponent,
    TarifasComponent,
    EditarTarifasComponent,
    CriarTarifasComponent,
    LimitesComponent,
    LimitesContasComponent,
    SenhaComponent,
    DocumentosContaComponent,
    BeneficiosComponent,
    BeneficiosvalorComponent,
    BeneficiariosComponent,
    CriarBeneficiosComponent,
    EditarBeneficiosComponent,
    BeneficiarioVincularComponent,
    PasswordComponent,
    LojistasComponent,
    CriarContaComponent,
    ContaTransacaoComponent,
    ListarCadastroComponent,
    EditarCadastroComponent,
    DocumentosCadastroComponent,
    ConvitesComponent,
    CriarContapjComponent,
    ConfiguracaoComponent
  ],
    imports: [
        BrowserModule,
        CurrencyMaskModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,

        // for HttpClient use:
        LoadingBarHttpClientModule,
        MatDialogModule,
        // for Router use:
        LoadingBarRouterModule,

        // for Core use:
        LoadingBarModule,
        NgxMaskModule.forRoot(maskConfig),
        NgxPaginationModule,
        NgbModule,
        ColorPickerModule,
        ChartsModule,
        MatSlideToggleModule
    ],
  exports: [ PasswordComponent ],
  providers: [
    httpInterceptorProviders,
    {provide: LOCALE_ID, useValue: 'pt-PT'},
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
