import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {AccountService} from '../../account/shared/account.service';
import {Router, ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import { filter } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-senha',
  templateUrl: './senha.component.html',
  styleUrls: ['./senha.component.css']
})
export class SenhaComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  token: any;
  usu = {password: '', rpassword: ''};
  errologin = { erro: false, msg: ''  };

  senhaControlmail = new FormControl('', [
    Validators.required,
  ]);
  rsenhaControlmail = new FormControl('', [
    Validators.required,
  ]);
  matcher = new MyErrorStateMatcher();





  constructor( private accountService: AccountService, private router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.token = params.token; });

  }

  // tslint:disable-next-line:typedef
  mostrarSenha(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  // tslint:disable-next-line:typedef
  async logar(){

    try {
      // tslint:disable-next-line:triple-equals
      if ( this.usu.password != this.usu.rpassword){
          this.errologin.erro = true;
          this.errologin.msg = 'As senhas não são iguais.';
      }else{

        this.enviarDadosConta().subscribe(cidade => {
          console.log(cidade);
          // tslint:disable-next-line:triple-equals
          if (cidade == 0){
              this.errologin.erro = true;
              this.errologin.msg = 'Seu token não é valido';
            }else{

            }

        });
      }


    } catch (error) {
      console.log(error);
    }


  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){
  // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/updatesenha/` + this.token, this.usu);
  }
}
