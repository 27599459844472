<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/beneficiarios']">
          <span class=" d-sm-inline" style="margin-left: 20px;"> Beneficiários Vinculados</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/vincular']">
          <span class=" d-sm-inline" style="margin-left: 20px;"> Vincular Beneficiários </span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>



    </ul>
  </div>


  <div class="col-md-8" style="padding: 30px;" >


    <h2 style="color: #33343E; font-weight: normal;"> Beneficiários Vinculados</h2>
    <button (click)="importarmodal()" type="button"style="float: right;" class="btn btn-light2 float-right btn_bluedark">Importar planilha</button>

    <div class="table-responsive">
      <div class="col-sm-12 " >

        <div class="input-group" style="padding: 20px 0;">
          <div class="input-group-prepend">
            <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
          </div>
          <input type="text" style=""  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por Beneficiários ...">
        </div>
      </div>
      <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

        <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
        <button (click)="fitrar('ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>
        <button (click)="fitrar('bloqueadas')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Bloqueados</button>


        <div class="btn-group" style="float: right;">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a (click)="deletar( )" class="dropdown-item" >Deletar</a>
            <a (click)="broquear( )" class="dropdown-item" >Bloquear</a>
            <a (click)="ativar( )" class="dropdown-item" >Ativar</a>

          </div>
        </div>
      </div>



      <table class="table "    style="border-spacing: 0 8px;">
        <thead>
        <tr style="font-size: 13px!important;" >
          <td>

            <input type="checkbox" class="form-check-input" id="Sim" name="prazoTermino" value="Sim" (change)="selecionartudo( $event )" >

          </td>
          <td  *ngIf=" order == 'users.name' && order_tipo == 'asc' || order != 'users.name' ">BENEFICIÁRIO <span (click)="ordenar('users.name')" style=" margin-left: 10px; font-size: 12px; color: #4AD9A4; cursor: pointer; "><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
          <td  *ngIf="order == 'users.name' && order_tipo == 'desc'">BENEFICIÁRIO <span (click)="ordenar('users.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

          <td  *ngIf=" order == 'users.cpf' && order_tipo == 'asc' || order != 'users.cpf' ">CPF/CNPJ <span (click)="ordenar('users.cpf')" style=" margin-left: 10px; font-size: 12px; color: #4AD9A4; cursor: pointer; "><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
          <td  *ngIf="order == 'users.cpf' && order_tipo == 'desc'">CPF/CNPJ <span (click)="ordenar('users.cpf')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

          <td  *ngIf=" order == 'users.saldo' && order_tipo == 'asc' || order != 'users.saldo' ">SALDO <span (click)="ordenar('users.saldo')" style=" margin-left: 10px; font-size: 12px; color: #4AD9A4; cursor: pointer; "><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
          <td  *ngIf="order == 'users.saldo' && order_tipo == 'desc'">SALDO <span (click)="ordenar('users.saldo')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


          <td  *ngIf="order == 'beneficios.name' && order_tipo == 'asc' || order != 'beneficios.name'">BENEFÍCIO<span (click)="ordenar('beneficios.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
          <td  *ngIf="order == 'beneficios.name' && order_tipo == 'desc'">BENEFÍCIO<span (click)="ordenar('beneficios.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


          <td></td>

        </tr>
        </thead>
        <tbody >
        <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
          <td>
            <!-- Example split danger button -->


            <input type="checkbox" class="form-check-input" style="margin-top: 10px;" [(ngModel)]="usuario.selected"  name="{{ usuario.id }}" id="{{usuario.id}}" (change)="onChange(usuario.id, $event)">


          </td>
          <td > <label for="{{usuario.id}}">{{usuario.nome_usuario}}</label></td>
          <td > <label for="{{usuario.id}}">{{usuario.cpf}}</label></td>
          <td > <label for="{{usuario.id}}">{{usuario.saldo | currency:'BRL':true}}</label></td>
          <td><label for="{{usuario.id}}">{{usuario.nome_beneficio}}</label></td>

          <td>
            <button *ngIf="usuario.status == 'ativo'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Ativo</span></button>
            <button *ngIf="usuario.status == 'bloqueadas'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red;"></div> <span style="margin-left: 10px; font-size: 12px;">Bloqueado</span></button>
          </td>

        </tr>
        </tbody>
      </table>

      <nav aria-label="Page navigation example" class="">
        <ul class="pagination justify-content-end">
          <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
            <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
          </li>
          <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
            <a class="page-link" (click)="proximo(1)" >1</a>
          </li>

          <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
            <a class="page-link"  >...</a>
          </li>


          <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

          <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
            <a class="page-link"  >...</a>
          </li>

          <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
            <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
          </li>



          <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
            <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
          </li>
        </ul>
      </nav>

    </div>


  </div>

  <div *ngIf="modalimportar == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #000; border-bottom: none!important;" >
          <h3>Importar planilha</h3>
          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: left;">
          <div  (click)="fileInput.click()" style="width: 60%; margin-left: 20%; margin-top: 20px; margin-bottom: 20px; border-radius: 10px; cursor: pointer; background-color: #FFF; padding: 10px 5px; -webkit-box-shadow: -4px 1px 29px 3px rgba(0,0,0,0.10); box-shadow: -4px 1px 29px 3px rgba(0,0,0,0.10); ">
          <span><svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.2" cx="24.9627" cy="24" rx="24.5373" ry="24" fill="#4AD9A4"/>
<rect x="13.7164" y="16" width="22.4925" height="16" rx="2" stroke="#4AD9A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7164 22H36.209" stroke="#4AD9A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> &nbsp;&nbsp;&nbsp;
 Selecionar planilha</span>
            <input #fileInput type="file" (change)="onSelectFile($event)" style="display:none;" />
          </div>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-secondary">Cancelar</button>
          <button type="button" (click)="importar()"  class="btn btn-success">Importar</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="modalacao == true" id="exampleModalLive4" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #000; border-bottom: none!important;" >
          <h2>Ações</h2>
          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center; font-size: 20px; margin-bottom: 30px;">
          {{modalacaotexto}}
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-secondary">Cancelar</button>
          <button *ngIf="tipoacao == 'ativar'" type="button" (click)="fazeracao()"  class="btn btn-success">Ativar</button>
          <button *ngIf="tipoacao == 'broquear'" type="button" (click)="fazeracao()"  class="btn btn-warning">Bloquear</button>
          <button *ngIf="tipoacao == 'deletar'" type="button" (click)="fazeracao()"  class="btn btn-danger">Deletar</button>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="modal == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> importado com sucesso!</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> Erro ao importar, tente novamente mais tarde!</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-danger">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>







