import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-assinaturas',
  templateUrl: './assinaturas.component.html',
  styleUrls: ['./assinaturas.component.css']
})
export class AssinaturasComponent implements OnInit {



  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;
  public filtro = 'tudo';
  pesquisa = {texto: ''};
  usuarios: any = [];
  url: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  modalpagar = false;
  modalerro = false;
  modal = false;
  texteerromodal = '';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  idbeneficio: any;
  pagando: any = false;
  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    this.getAll().subscribe(users => {
      this.usuarios = users;
    });
  }

  // tslint:disable-next-line:typedef
  getAll(){
    return this.http.get(`${environment.api}/appdados/assinaturas/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
  }

  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/appdados/assinaturas/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;

    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  editar(id: any){

    localStorage.setItem('assinatura', id);

    this.router.navigate(['assinaturas/editar']);
    // @ts-ignore

  }
  // tslint:disable-next-line:typedef
  irFaturas(id: any,tipo:any){
    localStorage.setItem('assinatura', id);
    localStorage.setItem('assinaturatipo', tipo);
    this.router.navigate(['assinaturas/faturas']);
  }
  // tslint:disable-next-line:typedef
  lancarmento(id: any){
    localStorage.setItem('beneficio', JSON.stringify(id));
    this.router.navigate(['beneficios/lancamentos']);
  }
  // tslint:disable-next-line:typedef
  criarnovo(){
    this.router.navigate(['assinaturas/criar']);
  }
  // tslint:disable-next-line:typedef
  cancelar(){
    this.modalpagar = false;
    this.modal = false;
    this.modalerro = false;
    this.pagando = false;
  }
  // tslint:disable-next-line:typedef
  ok(){
    this.modalpagar = false;
    this.modal = false;
    this.modalerro = false;
    //  window.location.reload();
  }
  // tslint:disable-next-line:typedef
  pagar(){
    this.modalpagar = false;
    this.pagando = true;
    setTimeout(() => {
      this.pagando = false;
    }, 500000);
    // tslint:disable-next-line:max-line-length
    this.pagarbene().subscribe(users => {
      console.log(users);
      // tslint:disable-next-line:triple-equals
      if ( users == '1'){
        this.modal = true;
      }
      // tslint:disable-next-line:triple-equals
      if ( users == '0'){
        this.modalerro = true;
        this.texteerromodal = 'Erro ao Realizar pagamento, Tente novamente mais tarde';
      }
      // tslint:disable-next-line:triple-equals
      if ( users == '2'){
        this.modalerro = true;
        this.texteerromodal = 'Erro ao Realizar pagamento, Saldo insuficiente';
      }
      // tslint:disable-next-line:triple-equals
      if ( users == '3'){
        this.modalerro = true;
        this.texteerromodal = 'Não foi possível processar o pagamento. A data configurada para o pagamento é anterior à data atual. Verifique as informações e tente novamente.';
      }
      this.pagando = false;
      // tslint:disable-next-line:triple-equals
    });
  }// tslint:disable-next-line:typedef
  pagarmodal(id: any){
    this.modalpagar = true;
    this.idbeneficio = id;
  }
  // tslint:disable-next-line:typedef
  pagarbene(){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/appdados/assinaturas/pagarbenemanual/` + this.idbeneficio);
  }

}

