<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por Usuario, email...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>
    <button (click)="fitrar('bloqueadas')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Bloqueados</button>
  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >

      <td  *ngIf="order == 'name' && order_tipo == 'asc' || order != 'name'">USUARIOS <span (click)="ordenar('name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'name' && order_tipo == 'desc'">USUARIOS <span (click)="ordenar('name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td *ngIf="order == 'carteira_id' && order_tipo == 'asc' || order != 'carteira_id'">CARTEIRAS <span (click)="ordenar('carteira_id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'carteira_id' && order_tipo == 'desc'">CARTEIRAS <span (click)="ordenar('carteira_id')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td *ngIf="order == 'email' && order_tipo == 'asc' || order != 'email'"> EMAIL <span (click)="ordenar('email')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'email' && order_tipo == 'desc'">EMAIL <span (click)="ordenar('email')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td *ngIf="order == 'telefone' && order_tipo == 'asc' || order != 'telefone'"> TELEFONE <span (click)="ordenar('telefone')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'telefone' && order_tipo == 'desc'">TELEFONE <span (click)="ordenar('telefone')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


      <td></td>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">

      <td>

        <img *ngIf="usuario.avatar != ''" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{usuario.avatar}}">
        <img *ngIf="usuario.avatar == ''" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{avatar_padrao}}"> <span style="margin-left: 10px;">{{usuario.name}}</span>

      </td>
      <td>
        <img *ngIf="usuario.cartao_bandeira != ''" class="img-profile " style="width: 30px; margin-left: 10px; " src="{{urlbandeira}}img/bandeiras/{{usuario.cartao_bandeira}}.png">
        <img *ngIf="usuario.cartao_bandeira == ''" class="img-profile " style="width: 30px; margin-left: 10px; " src="{{urlbandeira}}{{cartao_bandeira_padrao}}">
        {{usuario.cartao_credito}}
      </td>
      <td><span >{{usuario.email }}</span></td>
      <td><span >{{usuario.telefone }}</span></td>


      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a (click)="editar(usuario)" class="dropdown-item" >Editar</a>

          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>
      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(1)" >1</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>


      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
      </li>



      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>


</div>
