import { Component, OnInit } from '@angular/core';
import {Users} from './users';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-homelayout',
  templateUrl: './homelayout.component.html',
  styleUrls: ['./homelayout.component.css']
})
export class HomelayoutComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router) { }

menuaberto = true;
  modalsair = false;
valor: any;

  // tslint:disable-next-line:ban-types
  users: any = { name: '', email: '', role: '', telefone: '', avatar: 'img/avatar/avatar.jpg', nascimento: '', created_at: '', status: '',carteira:{isAssinaturas:false} };
  url: any;
  ngOnInit(): void {


    this.url = environment.url + 'api/app/img/';

    this.getAll().subscribe(users => {
      this.users = users;

      console.log( this.users);
      // tslint:disable-next-line:triple-equals
      if (  this.users.status == 1 || this.users.status == 2 || this.users.status == 3) {

        this.router.navigate(['login']);
      }

      // tslint:disable-next-line:triple-equals
      if (this.users.avatar == ''){

        this.users.avatar = 'img/avatar/avatar.jpg';
        console.log(this.users.avatar);
      }
    });
    this.gettotalvalor().subscribe(valor => {
      this.valor = valor;
    });


 }

  // tslint:disable-next-line:typedef
  getAll(){
   return this.http.get(`${environment.api}/user`);
 }
  // tslint:disable-next-line:typedef
  gettotalvalor(){
   return this.http.get(`${environment.api}/geral/gettotalvalor`);
 }
  // tslint:disable-next-line:typedef
  fecharabrirmenu(){

    // tslint:disable-next-line:triple-equals
      if ( this.menuaberto == true ){ this.menuaberto = false; }else{ this.menuaberto = true; }

  }
  // tslint:disable-next-line:typedef
  getano(){

    // tslint:disable-next-line:new-parens
    const now = new Date;
    return now.getFullYear();
  }
// tslint:disable-next-line:typedef
criarnovo( tipo: any ){

  this.router.navigate([tipo + '/criar']);
}

  // tslint:disable-next-line:typedef
  sair( ){
    this.modalsair = false;
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
// tslint:disable-next-line:typedef
  abrirmodalsair( ){
    this.modalsair = true;
  }
// tslint:disable-next-line:typedef
  cancelarsair( ){
    this.modalsair = false;
  }

}
