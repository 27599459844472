<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 " id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/tarifas/criar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Criar Tarifas</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>




    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Criar Tarifas</h2>

    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 mb-3 mb-sm-0">
        <label for="carteira_id">Carteira</label>
        <select name="perfil_usuario" id="carteira_id" class="form-control" [(ngModel)]="editarConta.carteira_id">
          <option value="" selected >Selecione</option>
          <option *ngFor="let carteira of carteiras ; let i = index" value="{{carteira.id}}">{{carteira.nome}}</option>

        </select>
      </div>

    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label for="descricao">Descrição</label>
        <textarea  class="form-control form-control-user" id="descricao"  name="descricao" [(ngModel)]="editarConta.descricao"
                   placeholder="Email "></textarea>
      </div>
      <div class="col-md-4">
        <label for="dias_cobranca">Data da cobrança</label>
        <select name="perfil_usuario" id="dias_cobranca" class="form-control" [(ngModel)]="editarConta.dias_cobranca">
          <option value="" selected >Selecione</option>
          <option value="30"  >30 dias</option>
          <option value="7" >1 semana</option>
          <option value="90" >90 dias</option>
          <option value="60" >60 dias</option>

        </select>
      </div>
      <div class="col-md-4">
        <label for="atividade">Atividade</label>
        <select name="atividade" id="atividade" class="form-control" [(ngModel)]="editarConta.atividade">
          <option value="" selected >Selecione</option>
          <option value="Abertura de Conta" selected >Abertura de Conta</option>


        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label for="periodicidade">Periodicidade</label>

        <select name="periodicidade" id="periodicidade" class="form-control" [(ngModel)]="editarConta.periodicidade">
          <option value="" selected >Selecione</option>
          <option value="Mensal"  >Mensal</option>
          <option value="Anual"  >Anual</option>
          <option value="Semestral"  >Semestral</option>
          <option value="Trimestral"  >Trimestral</option>


        </select>
      </div>
      <div class="col-md-4 ">
        <label  for="valor">Valor</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">R$</div>
          </div>
          <input type="text" class="form-control" id="valor" currencyMask  [(ngModel)]="editarConta.valor" [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="Valor">
        </div>

      </div>
      <div class="col-md-4">
        <label for="ativo">Status</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="ativo" name="status" value="ativo" [(ngModel)]="editarConta.status" >
          <label class="form-check-label" for="ativo">Ativo</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="status" class="form-check-input" value="bloqueadas" [(ngModel)]="editarConta.status" id="bloqueadas">

          <label class="form-check-label" for="bloqueadas">Cancelado</label>
        </div>


      </div>
    </div>




    <a class="btn btn-primary"  (click)="atualizarConta()" style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Criar</a>
  </div>


</div>
