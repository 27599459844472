import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-listar-cadastro',
  templateUrl: './listar-cadastro.component.html',
  styleUrls: ['./listar-cadastro.component.css']
})
export class ListarCadastroComponent implements OnInit {


  constructor(private http: HttpClient, private router: Router) {
  }

  public paginaAtual = 1;


  inicio: number | undefined;
  limite: number | undefined;

  editarConta = {email: '', id: '', password: ''};

  public filtro = 'analise';
  pesquisa = {texto: ''};
  usuarios: any = [];
  usu: any = {novasenha: '', password: ''};
  tipo: any = 'email';
  url: any;
  tiposenha: any = '';
  confimarsenha: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  modal = {erro: false, msg: ''};
  modalmudarsenha = false;
  modalsenhamudar = false;
  modalacao = false;
  modalacaotexto = '';
  modalerro = {erro: false, msg: ''};
  passwordativo = false;
  errocamposenha = {erro: false, tipo: ''};
  erroform: any = '0';

  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    if (sessionStorage.getItem('cadatro_filtro')) {
      // @ts-ignore
      this.filtro = sessionStorage.getItem('cadatro_filtro');
    }else{
      this.filtro = 'analise';
    }
    if (sessionStorage.getItem('cadatro_pesquisar')) {
      // @ts-ignore
      this.pesquisa.texto = sessionStorage.getItem('cadatro_pesquisar');
    }
    if (sessionStorage.getItem('cadatro_ordenar')) {
      // @ts-ignore
      this.order = sessionStorage.getItem('cadatro_ordenar');
    }
    if (sessionStorage.getItem('cadatro_order_tipo')) {
      // @ts-ignore
      this.order_tipo = sessionStorage.getItem('cadatro_order_tipo');
    }
    if (sessionStorage.getItem('cadatro_paginaatual')) {
      // @ts-ignore
      this.paginaAtual = sessionStorage.getItem('cadatro_paginaatual');
    }


    this.getAll().subscribe(async users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
      if (this.usuarios.status) {
        this.router.navigate(['login']);
      }

    });
  }

  // tslint:disable-next-line:typedef
  getAll() {
    // return this.http.get(`${environment.apiContas}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);

  }

  // tslint:disable-next-line:typedef
  criarnovo() {
    this.router.navigate(['cadastros/criar']);
  }

  // tslint:disable-next-line:typedef
  getfiltro() {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }

  // tslint:disable-next-line:typedef
  proximo(pagina: number) {
    this.paginaAtual = pagina;
// @ts-ignore
    sessionStorage.setItem('cadatro_paginaatual', this.paginaAtual);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar(filtro: string) {
    this.filtro = filtro;
    sessionStorage.setItem('cadatro_filtro', this.filtro);
    sessionStorage.setItem('cadatro_paginaatual', '1');
    this.paginaAtual = 1;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }

  // tslint:disable-next-line:typedef
  ordenar(event: any) {
    this.order = event;
    sessionStorage.setItem('cadatro_ordenar', this.order);
    this.paginaAtual = 1;
    sessionStorage.setItem('cadatro_paginaatual', '1');
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc') {
      this.order_tipo = 'desc';
    } else {
      this.order_tipo = 'asc';
    }
    sessionStorage.setItem('cadatro_order_tipo', this.order_tipo);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }

  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    sessionStorage.setItem('cadatro_pesquisar', this.pesquisa.texto);
    sessionStorage.setItem('cadatro_paginaatual', '1');
    this.paginaAtual = 1;
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }

  // tslint:disable-next-line:typedef
  editar(id: any) {
    console.log('çasçsaçsalçs');

    this.router.navigate(['cadastros/editar']);
    // @ts-ignore
    localStorage.setItem('editar_conta_dados', JSON.stringify(id));
  }

  // tslint:disable-next-line:typedef
  transacao(id: any) {
    // @ts-ignore
    localStorage.setItem('editar_conta_dados', JSON.stringify(id));
    this.router.navigate(['clientes/transacao']);

  }

  // tslint:disable-next-line:typedef
  deletar(id: any) {
    console.log(id);
    this.editarConta = id;
    this.abrirsenha();
  }

  // tslint:disable-next-line:typedef
  mudar() {
    // tslint:disable-next-line:triple-equals
    if (this.tipo == 'editar') {
      this.modalmudarsenha = false;
      this.modalsenhamudar = true;
    }

    // tslint:disable-next-line:triple-equals
    if (this.tipo == 'email') {
      this.enviarproemail().subscribe(users => {
        // tslint:disable-next-line:triple-equals
        // tslint:disable-next-line:triple-equals
        if (users == '1') {
          this.modal.erro = true;
          this.modal.msg = 'Email enviado com sucesso!';
        } else {
          this.modalerro.erro = true;
          this.modalerro.msg = 'Erro ao enviar email, tente novamente mais tarde!';
        }
      });
      this.modalmudarsenha = false;
    }
  }

  // tslint:disable-next-line:typedef
  // @ts-ignore
  cadatrarsenha(): any {
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    // tslint:disable-next-line:triple-equals
    if (this.usu.novasenha.length != 6) {
      this.errocamposenha.tipo = 'obrigatorio';
      this.errocamposenha.erro = true;
      return false;
    }
    // tslint:disable-next-line:triple-equals
    if (this.usu.novasenha != this.confimarsenha) {
      this.errocamposenha.tipo = 'senhasdiferente';
      this.errocamposenha.erro = true;
      return false;
    }

    this.modalmudarsenha = false;
    this.modalsenhamudar = false;
    this.tiposenha = 'trocarsenha';
    this.passwordativo = true;
  }

  // tslint:disable-next-line:typedef
  abrirususenha(dados: any) {
    this.usu = dados;
    this.modalmudarsenha = true;
  }

  // tslint:disable-next-line:typedef
  cancelar() {
    this.modal.erro = false;
    this.modalerro.erro = false;
    this.modalacao = false;
    this.modalmudarsenha = false;
    this.modalsenhamudar = false;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }

  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0') {
      this.tiposenha = 'deletar';
      this.passwordativo = true;
    }

  }

  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;
    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar') {
      this.passwordativo = false;
    } else {
      // tslint:disable-next-line:triple-equals
      if (this.tiposenha == 'deletar') {
///////////////////////////////////////////////////////
        this.deletardados().subscribe(users => {
          // tslint:disable-next-line:triple-equals
          console.log('dddddd', users);
          // tslint:disable-next-line:triple-equals
          if (users == '1') {
            this.modal.erro = true;
            this.modal.msg = 'Deletado com sucesso!';
          } else {
            this.modalerro.erro = true;
            this.modalerro.msg = 'Erro ao deletar, tente novamente mais tarde!';
          }
        });
        this.passwordativo = false;
      }
      // tslint:disable-next-line:triple-equals
      else if (this.tiposenha == 'trocarsenha') {
        this.usu.password = senha.password;
        this.mudarsenha().subscribe(users => {
          // tslint:disable-next-line:triple-equals
          // tslint:disable-next-line:triple-equals
          if (users == '1') {
            this.modal.erro = true;
            this.modal.msg = 'Senha criada com sucesso!';
          } else {
            this.modalerro.erro = true;
            this.modalerro.msg = 'Erro ao criar senha, tente novamente mais tarde!';
          }
        });
        this.passwordativo = false;
      } else {
        this.passwordativo = false;
      }
    }
    console.log(senha);
  }

  // tslint:disable-next-line:typedef
  deletardados() {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/deletar`, this.editarConta);
  }

  // tslint:disable-next-line:typedef
  mudarsenha() {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/editarsenha`, this.usu);
  }

  // tslint:disable-next-line:typedef
  enviarproemail() {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/enviarproemail`, this.usu);
  }

  numberOnly(event: any): boolean {
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
