import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { map, filter, scan } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { DatePipe } from '@angular/common'; // import do datePipe
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-editarcontas',
  templateUrl: './editarcontas.component.html',
  styleUrls: ['./editarcontas.component.css'],
  providers: [DatePipe]
})
export class EditarcontasComponent implements OnInit {


  model: NgbDateStruct | any;
  avatar: any =  '../../assets/img/avatar.jpg';
usuarios: any = '';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', nascimento: 'hhhh', email: '', role: 'User', avatar: '',
    namecorreto: '', telefone: '', nacionalidade: '', sexo: 'm', cep: '', cpf: '', estado: '1'
    , cidade: '', cpf_pj: '', nomeFatasia: '', segmento: 1, nomeMae: '', iscontapix:'',tipochave:'',chave:''
  };
  cidades: any;
  segmentos: any;
  erro: any = 0;
  user: any = [];
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private router: Router) { }

  // tslint:disable-next-line:typedef
  async ngOnInit() {

    this.getsegmentos().subscribe(seg => {
      this.segmentos = seg;
      // tslint:disable-next-line:triple-equals


    });

    this.getusuariodados().subscribe( async user => {
      this.user = user;
      // @ts-ignore
      this.editarConta = JSON.parse(  localStorage.getItem('editar_conta_dados')) ;
      this.editarConta.nascimento = this.datePipe.transform(this.editarConta.nascimento, 'dd/MM/yyyy');
      this.url = environment.url;
      // this.editarConta.avatar =  this.url +  this.editarConta.avatar;
      console.log(this.editarConta);
      if (this.editarConta.avatar === '' || this.editarConta.avatar === 'avatar.jpg') {
        this.avatar = this.url + 'api/app/img/avatar.jpg';
      } else {
        this.avatar = this.url + 'api/app/img/' + this.editarConta.avatar;
      }
      this.getcidade().subscribe(cidade => {
        this.cidades = cidade;
        // tslint:disable-next-line:triple-equals


      });




      this.getestados().subscribe(estados => {
        this.dado_estados = estados;
        // tslint:disable-next-line:triple-equals


      });
      this.editarConta.cidade =  this.editarConta.cidade;
    });


    console.log(this.editarConta);
    // tslint:disable-next-line:triple-equals
}
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }


  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.avatar = myReader.result;
      this.editarConta.avatar = myReader.result;
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }
  // // tslint:disable-next-line:typedef
  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //
  //     reader.onload = (ev: any) => {
  //       this.editarConta.avatar = ev.target.result;
  //     };
  //    // reader.readAsDataURL(event.target.files[0]);

  //   }
  // }

  // tslint:disable-next-line:typedef
  getcidade(){
    return this.http.get(`${environment.api}/app/cidades/` + this.editarConta.estado );
  }
  // tslint:disable-next-line:typedef
  getsegmentos(){
    return this.http.get(`${environment.api}/segmentos` );
  }

  // tslint:disable-next-line:typedef
  postavatar(){
    return this.http.post(`${environment.apiContas}/avatar` , this.editarConta );
  }
  // tslint:disable-next-line:typedef
  getuser(){
    return this.http.get(`${environment.apiContas}/editar/` + this.editarConta.estado );
  }
  // tslint:disable-next-line:typedef
  getestados(){
    return this.http.get(`${environment.api}/estados` );
  }
  // tslint:disable-next-line:typedef
  onAddCidade(){
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }

  // tslint:disable-next-line:typedef
  atualizarConta(){
    this.enviarDadosConta().subscribe(dadosconta => {
      console.log(dadosconta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
this.erro = 1;
      }else{
        this.erro = 0;
        this.router.navigate(['clientes']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/contas/update/` + this.editarConta.id, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  getusuariodados(){
    return this.http.get(`${environment.api}/user`);
  }
}
