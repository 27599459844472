<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/cadastros/criar']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Cadastrar Cliente</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/criarcontapj']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Cadastrar PJ</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>



    </ul>
  </div>






  <div   class="col-md-9" style="padding: 30px;">



    <h2 style="color: #33343E; font-weight: normal;"> Cadastrar PJ</h2>
    <div *ngFor="let c of errosgeral.cnpj let i = index" class="errogeral">
      Erro CNPJ: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cpf let i = index" class="errogeral">
      Erro CPF:  {{c}}
    </div>
    <div *ngFor="let c of errosgeral.email let i = index" class="errogeral">
      Erro EMAIL: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.password let i = index" class="errogeral">
      Erro SENHA: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.razaoSocial let i = index" class="errogeral">
      Erro RAZÃO SOCIAL: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.nomeFatasia let i = index" class="errogeral">
      Erro NOME FANTASIA: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.endereco let i = index" class="errogeral">
      Erro ENDEREÇO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.numero let i = index" class="errogeral">
      Erro NUMERO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.bairro let i = index" class="errogeral">
      Erro BAIRRO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cidade let i = index" class="errogeral">
      Erro CIDADE: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.estado let i = index" class="errogeral">
      Erro ESTADO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.sexo let i = index" class="errogeral">
      Erro SEXO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cep let i = index" class="errogeral">
      Erro CEP: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.nascimento let i = index" class="errogeral">
      Erro NASCIMENTO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.telefone let i = index" class="errogeral">
      Erro TELEFONE: {{c}}
    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="razaoSocial">Razão social</label>
        <input type="text" class="form-control form-control-user" name="name" id="razaoSocial"
               [(ngModel)]="editarConta.razaoSocial"
               placeholder="Razão social">
      </div>
      <div class="col-md-6" >
        <label for="cnpj">CNPJ</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cnpj" name="cnpj" id="cnpj"
               mask="00.000.000/0000-00" placeholder="CNPJ">
      </div>

    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="nomeMae">Nome da mãe</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.nomeMae" name="nomeMae" id="nomeMae"
               placeholder="Nome da mãe">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input type="email" class="form-control form-control-user" id="email" name="email"
               [(ngModel)]="editarConta.email"
               placeholder="Email ">
      </div>
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="nomeFatasia">Nome Fantasia</label>
        <input type="text" class="form-control form-control-user" name="name" id="nomeFatasia"
               [(ngModel)]="editarConta.nomeFatasia"
               placeholder="Nome Fantasia">
      </div>

    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="namelegal">Representante Legal</label>
        <input type="text" class="form-control form-control-user" name="name" id="namelegal"
               [(ngModel)]="editarConta.name"
               placeholder="Representante Legal">
      </div>
      <div class="col-md-6" >
        <label for="cnpj">CPF do representante</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cpf_pj" name="cpf_pj" id="cpf_pj"
               mask="000.000.000-00" placeholder="CPF do representante">
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Sexo</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.sexo" id="sexo">
          <option value="m">Masculino</option>
          <option value="f">Feminino</option>
          <option value="p">Prefiro não dizer</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="BR">Nacionalidade</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="BR" name="nacionalidade" value="BR"
                 [(ngModel)]="editarConta.nacionalidade">
          <label class="form-check-label" for="BR">Brasileiro</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="nacionalidade" class="form-check-input" value="ES"
                 [(ngModel)]="editarConta.nacionalidade" id="estrageiro">

          <label class="form-check-label" for="estrageiro">Estrageiro</label>
        </div>


      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="aniversario">Aniversário</label>
        <input type="text" class="form-control form-control-user" name="dp1" id="aniversario"
               [(ngModel)]="editarConta.nascimento" mask="00/00/0000"
               placeholder="Aniversário">
      </div>

      <div class="col-md-6">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.telefone" id="telefone"
               mask="(00) 0 0000-0000" name="telefone"
               placeholder="Telefone ">
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0">
        <label for="cep">CEP </label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cep" (keypress)="numberOnly($event)"  #inputitem    (blur)="buscarCep()" name="cep" id="cep"
               placeholder="CEP">
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="estado">ESTADO</label>
        <select name="estados" [(ngModel)]="editarConta.estado" id="estado" class="form-control"
                (ngModelChange)="onAddCidade()">
          <option *ngFor="let estado of dado_estados ; let i = index"
                  value="{{estado.abbreviation}}">{{estado.name}}</option>
        </select>
      </div>

      <div class="col-md-6">
        <label for="cidade">CIDADE</label>
        <select name="cidades" [(ngModel)]="editarConta.cidade" id="cidade" class="form-control">
          <option *ngFor="let cidade of cidades ; let i = index" value="{{cidade.ibge_code}}">{{cidade.name}}</option>
        </select>
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0" >
        <label for="endereco"> Endereço <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="endereco" id="endereco"  [(ngModel)]="editarConta.endereco"
               placeholder="Rua A, Centro, 782" required>
      </div>

    </div>

    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="bairro"> Bairro <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="bairro" id="bairro" [(ngModel)]="editarConta.bairro"
               placeholder="Ex: Centro" required>

      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="numero"> Numero <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="numero" id="numero"  [(ngModel)]="editarConta.numero"
               placeholder="Ex: 485"     required>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Tipo de Usuario</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.role" id="role">
          <option value="OPublico">Orgão Publico</option>
          <option value="Parceiro">Lojista</option>
          <option *ngIf="user.perfil_usuario == 'Senior'" value="Admin">Admin</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="segmento">Segmento</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.segmento" id="segmento">
          <option *ngFor=" let segmento of segmentos" value="{{segmento.id}}">{{segmento.name}}</option>

        </select>
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Tipo de Chave</label>
        <select name="tipochave" class="form-control" [(ngModel)]="editarConta.tipochave" id="tipochave">
          <option value="CPF">CPF</option>
          <option value="CNPJ">CNPJ</option>
          <option value="EVP">Chave Aleatoria</option>
          <option value="EMAIL">EMAIL</option>
          <option value="TELEFONE">TELEFONE</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="segmento">Chave</label>
        <input type="text" class="form-control form-control-user"  [(ngModel)]="editarConta.chave" name="chave" id="chave"
               placeholder="Chave">
      </div>
    </div>


    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="password"> Senha <span style="color: red;">*</span></label>
        <input type="password" class="form-control  form-control-user"   (keypress)="numberOnly($event)"  maxlength="6" name="password" id="password" [(ngModel)]="editarConta.password"
               required>
        <div *ngIf=" erroformsenhavazia === 1" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="passwordc"> Confirme a Senha <span style="color: red;">*</span></label>
        <input type="password" class="form-control form-control-user"  (keypress)="numberOnly($event)"  maxlength="6" name="passwordc" id="passwordc"  [(ngModel)]="editarConta.passwordc"
               required>

        <div *ngIf="  erroformsenha === 1" style="color: red;">
          As senhas não são iguais.
        </div>
      </div>


    </div>

    <a class="btn btn-primary" (click)="atualizarConta()"
       style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Criar</a>
  </div>


</div>
