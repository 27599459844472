import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css']
})
export class RelatoriosComponent implements OnInit {

  constructor(private http: HttpClient) { }
modal = false;
tipo = '';
bene: any = [];
cateiras: any = [];
dadosdownlod: any = {};
dadosrelatorio = {beneficio_id: 'Todos', carteira_id: 'Todos', status: 'Todos'};
  // tslint:disable-next-line:typedef
  async ngOnInit() {

    this.getbeneficios().subscribe(users => {
      this.bene = users;
    });
    this.getcarteiras().subscribe(users => {
      this.cateiras = users;
      console.log(this.cateiras);
    });

  }


  // tslint:disable-next-line:typedef
  cancelar(){
    this.modal = false;
    this.tipo = '';
  }
  // tslint:disable-next-line:typedef
  abrirmodal(tipo: any){
    this.modal = true;
    this.tipo = tipo;
  }
  // tslint:disable-next-line:typedef
  gerarpdf(){
    this.gerar().subscribe(users => {
      this.dadosdownlod = users;
      this.downloadPdf(users, 'Relatorio');
      console.log(this.dadosdownlod);
    });
  }



  // tslint:disable-next-line:typedef
  getbeneficios(){
    return this.http.get(`${environment.api}/beneficios/getall?order=id&order_tipo=desc`);
  }
  // tslint:disable-next-line:typedef
  getcarteiras(){
    return this.http.get(`${environment.apiCarteiras}/getall?` + 'order=id&order_tipo=desc');
  }
  // tslint:disable-next-line:typedef
  gerar(){
    return this.http.post(`${environment.api}/appdados/beneficios/relatoriobeneficios`, this.dadosrelatorio);
  }


  // tslint:disable-next-line:typedef
  downloadPdf( base64String: any , fileName: any ) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }
}
