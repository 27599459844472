<div fxLayout="row" fxLayoutAlign="start start" >

  <div  fxFlex.gt-md="40%"  fxFlex.gt-sm="100%" style="background-color: #F0F2F7; height: 100vh; padding: 20px; ">
    <div fxLayout="row" fxLayoutAlign="start start" >
      <img fxFlex.gt-md="40%" fxFlex.gt-sm="100%" src="assets/img/logo.svg" alt="Photo of a Shiba Inu">

    </div>
    <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 50px;" >

      <h1 fxFlexOffset="15" style="color: #233B58; font-weight: bold; font-size: 40px;" fxFlex>Atualizar nova
        senha</h1>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" >
      <div *ngIf="errologin.erro == true" style=" margin-left: -20px; margin-top: 50px; width: 65%; padding: 10px; color: #FFF; text-align: center;  background-color: indianred;">
        {{errologin.msg}}
      </div>
    </div>


    <div  class="example-form" style="margin-top: 30px; " >
      <mat-form-field fxFlexOffset="20" class="example-full-width" style="display: block;">

        <mat-label>Nova senha</mat-label>
        <input  placeholder="Placeholder" #input  style="background-color: #FFF;" type="password" matInput [formControl]="senhaControlmail" [(ngModel)]="usu.password" [errorStateMatcher]="matcher"
        >
        <mat-icon (click)="mostrarSenha(input)" style=" color: #9BA1B3; cursor:pointer;" matSuffix>remove_red_eye</mat-icon>
        <mat-hint style="color: green;">Por Favor digite sua senha.</mat-hint>
        <mat-error style="color: green;" *ngIf=" !senhaControlmail.hasError('required')">
          Por Favor digite sua Senha.
        </mat-error>
        <mat-error *ngIf="senhaControlmail.hasError('required')">
          a senha é <strong>Obrigatoria!</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div  class="example-form" style="margin-top: 30px; " >
      <mat-form-field fxFlexOffset="20" class="example-full-width" style="display: block;">

        <mat-label>Repetir senha</mat-label>
        <input  placeholder="Placeholder" #input2  style="background-color: #FFF;" type="password" matInput [formControl]="rsenhaControlmail" [(ngModel)]="usu.rpassword" [errorStateMatcher]="matcher"
        >
        <mat-icon (click)="mostrarSenha(input2)" style=" color: #9BA1B3; cursor:pointer;" matSuffix>remove_red_eye</mat-icon>
        <mat-hint style="color: green;">Por Favor digite sua senha.</mat-hint>
        <mat-error style="color: green;" *ngIf=" !rsenhaControlmail.hasError('required')">
          Por Favor digite sua Senha.
        </mat-error>
        <mat-error *ngIf="rsenhaControlmail.hasError('required')">
          a senha é <strong>Obrigatoria!</strong>
        </mat-error>
      </mat-form-field>
    </div>



    <div>
      <button fxFlexOffset="15" (click)="logar()"   mat-raised-button color="primary" style="width: 65%; background-color: #4AD9A4;  margin-top: 30px;">Atualizar Senha</button>
    </div>



  </div>
  <div fxFlex="80%" fxFlex.gt-md="60%" fxFlex.gt-sm="90%" style="background-color: #F0F2F7; height: 100vh; padding: 20px;">
    <div style="width: 100%; height: 94vh;background:
    linear-gradient(204deg, rgba(145,217,191,1) 0%, rgba(145,217,191,1) 19%, rgba(17,130,148,1) 64%);
     border-radius: 10px;"></div>

  </div>






</div>
