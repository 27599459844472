import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-lojistas',
  templateUrl: './lojistas.component.html',
  styleUrls: ['./lojistas.component.css']
})
export class LojistasComponent implements OnInit {



  constructor(private http: HttpClient, private router: Router) { }
  cpf = new FormControl('', [
    Validators.required,
  ]);
  editarConta = { cpf: '' , beneficio_id: '', password: '' };
  public paginaAtual = 1;
  public filtro = 'tudo';
  pesquisa = {texto: ''};
  usuarios: any = {data: []};
  dadosenviaracao: any = {array: []};
  url: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  beneficio_id: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'beneficios.name';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  tipoacao = '';
  modalimportar = false;
  dadosimportar = {csv: '', beneficio_id: ''};
  select: any = [];
  modal = false;
  modalacao = false;
  modalacaotexto = '';
  modalerro = false;
  passwordativo = false;
  erroform: any =  '0';
  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';
    // @ts-ignore
    this.beneficio_id = JSON.parse(  localStorage.getItem('beneficio' )).id ;
    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    // @ts-ignore
    this.editarConta.beneficio_id = JSON.parse(  localStorage.getItem('beneficio')).id ;
    this.getAll().subscribe(users => {
      this.usuarios = users;
      console.log(this.usuarios);
    });

  }

  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    if (this.cpf.hasError('required')){ this.erroform = '1'; }
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0'){
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;
    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      // tslint:disable-next-line:triple-equals
      if (this.erroform == '0') {
        this.beneficiosvincular().subscribe(dados => {
          // console.log(dadosconta);
          // tslint:disable-next-line:triple-equals
          if ( dados == 0){
            this.modal = false;
            this.modalerro = true;
          }else{
            this.modal = true;
            this.modalerro = false;
            // this.router.navigate(['beneficios']);
          }
          // tslint:disable-next-line:triple-equals
        });
      }
      this.passwordativo = false;
    }
    console.log( senha);
  }


  // tslint:disable-next-line:typedef
  beneficiosvincular(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/beneficiosvincularlojista`, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  onChange(id: string, isChecked: Event) {
    // @ts-ignore
    if (isChecked.target.checked) {
      this.select.push(id);
    } else {
      const index = this.select.indexOf(id);
      this.select.splice(index, 1);
    }
    console.log(this.select);
  }  // tslint:disable-next-line:typedef
  selecionartudo(isChecked: Event) {
    this.select = [];
    // @ts-ignore
    if (isChecked.target.checked){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usuarios.data.length; i++) {
        this.usuarios.data[i].selected = true;
        this.select.push(this.usuarios.data[i].id);
      }
    }else{

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usuarios.data.length; i++) {
        this.usuarios.data[i].selected = false;
        const index = this.select.indexOf(this.usuarios.data[i].id);
        this.select.splice(index, 1);
      }
    }
    console.log(this.select);
  }
  // tslint:disable-next-line:typedef
  getAll(){
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/beneficios/getbeneficiovinculoslojistas?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo + '&beneficio_id=' + this.beneficio_id);
  }

  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/getbeneficiovinculoslojistas?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo + '&beneficio_id=' + this.beneficio_id, this.pesquisa );
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;

    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  deletar(){
    this.tipoacao = 'deletar';
    this.modalacao = true;
    this.modalacaotexto = 'Você tem certeza que deseja deletar?';
  }
  // tslint:disable-next-line:typedef
  fazeracao(){
    this.modalacao = false;
    this.beneficiosvincularacao().subscribe(users => {
      // tslint:disable-next-line:triple-equals
      if ( users == '1' ){
        // tslint:disable-next-line:no-shadowed-variable
        this.getAll().subscribe(users => {
          this.usuarios = users;
        });
      }
      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      if (users.status == 2){
        // @ts-ignore
        window.open(users.url , '_blank');
      }
    });

  }
  // tslint:disable-next-line:typedef
  broquear(){
    this.tipoacao = 'broquear';
    this.modalacao = true;
    this.modalacaotexto = 'Você tem certeza que deseja Bloquear?';
  }  // tslint:disable-next-line:typedef
  ativar(){
    this.tipoacao = 'ativar';
    this.modalacao = true;
    this.modalacaotexto = 'Você tem certeza que deseja Ativar?';
  } // tslint:disable-next-line:typedef
  gerarpdf(){
    this.tipoacao = 'gerarpdf';
    this.modalacao = true;
    this.modalacaotexto = 'Você tem certeza que deseja Gerar lista de Lojistas?';
  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  editar(id: any){
    console.log('çasçsaçsalçs');

    this.router.navigate(['beneficios/editar']);
    // @ts-ignore
    localStorage.setItem('beneficio', JSON.stringify(id));
  }
  // tslint:disable-next-line:typedef
  beneciarios(id: any){
    localStorage.setItem('beneficio', JSON.stringify(id));
    this.router.navigate(['beneficios/beneficiarios']);
  }
  // tslint:disable-next-line:typedef
  criarnovo(){


    this.router.navigate(['beneficios/criar']);
  }
  // tslint:disable-next-line:typedef
  importarmodal(){
    this.modalimportar = true;
  }
  // tslint:disable-next-line:typedef
  cancelar(){
    this.modalimportar = false;
    this.modal = false;
    this.modalerro = false;
    this.modalacao = false;
    this.getAll().subscribe(users => {
      this.usuarios = users;
    });
  }
  // tslint:disable-next-line:typedef
  importar(){
    this.modalimportar = false;
    this.vincularcnpj().subscribe(dadosconta => {
      console.log(dadosconta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
        this.modal = false;
        this.modalerro = true;
      }else{
        this.modal = true;
        this.modalerro = false;
        // this.router.navigate(['beneficios']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  vincularcnpj(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/csv`, this.dadosimportar);
  }



  // tslint:disable-next-line:typedef
  beneficiosvincularacao(){
    this.dadosenviaracao.array = this.select;
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/beneficiosvincularlojistaacao/` + this.tipoacao , this.dadosenviaracao);
  }
  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      console.log(myReader.result);
      // @ts-ignore
      this.dadosimportar.csv = myReader.result;
      // @ts-ignore
      this.dadosimportar.beneficio_id = this.beneficio_id ;
    };
    myReader.readAsDataURL(file);


  }


}
