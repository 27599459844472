import { Component, OnInit } from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css'],
  providers: [DatePipe]
})
export class BeneficiosvalorComponent implements OnInit {


  model: NgbDateStruct | any;

  usuarios: any = '';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', aniversario: '', email: '', avatar: '', telefone: '',
    nacionalidade: '', sexo: '', cep: '', cpf: '', estado: '1', cidade: '', limiteSaque: '', limiteTrasacao: '', limitePagamento: ''
    , saldosocial: '', saldosaude: '', saldorefeicao: '', saldovalealimetacao: '', saldogeral: ''};
  cidades: any;
  erro: any = 0;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private router: Router) { }

  ngOnInit(): void {
    this.url = environment.url;
    // @ts-ignore
    this.editarConta = JSON.parse( localStorage.getItem('editar_conta_dados')) ;
    console.log( this.editarConta);
    this.editarConta.saldogeral = this.editarConta.saldogeral.replace( '.' , ',' );
    this.editarConta.saldovalealimetacao = this.editarConta.saldovalealimetacao.replace( '.' , ',' );
    this.editarConta.saldorefeicao = this.editarConta.saldorefeicao.replace( '.' , ',' );
    this.editarConta.saldosaude = this.editarConta.saldosaude.replace( '.' , ',' );
    this.editarConta.saldosocial = this.editarConta.saldosocial.replace( '.' , ',' );
  }


  // tslint:disable-next-line:typedef
  atualizarConta(){
    this.enviarDadosConta().subscribe(dadosconta => {
      console.log(dadosconta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
        this.erro = 1;
      }else{
        this.erro = 0;

        this.router.navigate(['clientes']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiContas}/updateLimite/` + this.editarConta.id, this.editarConta);

  }


}
