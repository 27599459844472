<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por id por conta, Valor...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

<!--    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>-->
<!--    <button (click)="fitrar('ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>-->
<!--    <button (click)="fitrar('bloqueadas')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Bloqueados</button>-->
<!--    <button (click)="fitrar('reprovados')" type="button" [ngClass]="{'btn-selecinado': filtro === 'reprovados'}" class="btn btn-light2" style="margin-left: 10px;">Reprovados</button>-->
<!--    <button (click)="fitrar('analise')" type="button" [ngClass]="{'btn-selecinado': filtro === 'analise'}" class="btn btn-light2" style="margin-left: 10px;">Em Análise</button>-->


    <select   class="form-control float-right" style="width: 20%; border: none; background-color: transparent;" (change)="mudarcarteira($event.target)">
      <option *ngFor="let carteira of carteiras ;" value="{{carteira.id}}" >{{carteira.nome}}</option>
    </select>
  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >
      <td  *ngIf="order == 'id' && order_tipo == 'asc' || order != 'id'"> ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'id' && order_tipo == 'desc'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


      <td  *ngIf="order == 'name_inicial' && order_tipo == 'asc' || order != 'name_inicial'"> DE <span (click)="ordenar('name_inicial')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'name_inicial' && order_tipo == 'desc'">DE <span (click)="ordenar('name_inicial')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'name_destino' && order_tipo == 'asc' || order != 'name_destino'"> PARA <span (click)="ordenar('name_destino')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'name_destino' && order_tipo == 'desc'"> PARA <span (click)="ordenar('name_destino')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'created_at' && order_tipo == 'asc' || order != 'created_at'"> DATA <span (click)="ordenar('created_at')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'created_at' && order_tipo == 'desc'"> DATA <span (click)="ordenar('created_at')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>





      <td  *ngIf="order == 'valor' && order_tipo == 'asc' || order != 'valor'" >VALOR <span (click)="ordenar('valor')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'valor' && order_tipo == 'desc'" >VALOR <span (click)="ordenar('valor')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'role' && order_tipo == 'asc' || order != 'role'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'role' && order_tipo == 'desc'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



      <td>Saldo Anterior</td>
      <td>Saldo Depois</td>
      <td></td>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td >{{usuario.id}}</td>
      <td>
        <span  style="margin-left: 10px;">{{usuario.name_inicial}}</span>
      </td>
      <td>
        <span  style="margin-left: 10px;">{{usuario.name_destino }}</span>
      </td>

      <td>{{usuario.created_at_ok | date: 'dd/MM/yy HH:MM:SS'}}</td>

      <td>
        <span *ngIf="usuario.tipo_entrada == '1'" style="color: red;"> -{{usuario.valor | currency:'BRL':true}}</span>
        <span *ngIf="usuario.tipo_entrada == '2'" style="color: #4AD9A4;">+{{usuario.valor | currency:'BRL':true}}</span>
      </td>
      <td>
        <span  >{{tipotrans [usuario.tipo].tipo}} </span>


      </td>

      <td>
        {{usuario.saldo_antes | currency:'BRL':true}}

      </td>
      <td>
        {{usuario.saldo_depois | currency:'BRL':true}}

      </td>
      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">

            <a  (click)="abrirmodaldetalhes(usuario)" class="dropdown-item" >Detalhes</a>
<!--            <a  *ngIf="usuario.status == '0'" (click)="ativar(usuario)" class="dropdown-item" >Ativar</a>-->

          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>
      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(1)" >1</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>


      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
      </li>



      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>




  <div *ngIf="modaldetalhes == true" id="exampleModalLive78" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div  style="color: #FFF;  text-align: center; padding: 30px 30px 0 30px; ">
          <h5 style="color: #6e707e;  text-align: left; font-size: 25px;"   >Detalhes Transação</h5>
          <button type="button" class="close"  (click)="cancelar()"  style="margin-top: -35px;" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>


        </div>

        <div class="modal-body" style="padding: 0px 30px;overflow-y: scroll; height: 70vh;">
          <table class="table  table-striped "    style="border-spacing: 0 8px;">

            <tbody >
            <tr  >
              <td >ID</td>
              <td >{{detalhes.id}}</td>
            </tr>
            <tr  >
              <td >Descrição</td>
              <td >{{detalhes.sub_tipo}}</td>
            </tr>
            <tr  >
              <td >De</td>
              <td >{{detalhes.name_inicial}}</td>
            </tr>
            <tr  >
              <td >Para</td>
              <td >{{detalhes.name_destino}}</td>
            </tr>
            <tr  >
              <td >Data</td>
              <td >{{detalhes.created_at_ok | date: 'dd/MM/yy HH:MM:SS'}}</td>
            </tr>
            <tr  >
              <td >Valor</td>
              <td >
                <span *ngIf="detalhes.tipo_entrada == '1'" style="color: red;"> -{{detalhes.valor | currency:'BRL':true}}</span>
                <span *ngIf="detalhes.tipo_entrada == '2'" style="color: #4AD9A4;">+{{detalhes.valor | currency:'BRL':true}}</span>
              </td>
            </tr>
            <tr  >
              <td >Tipo</td>
              <td >{{tipotrans [detalhes.tipo].tipo}}</td>
            </tr>

            <tr  >
              <td >Saldo Anterior</td>
              <td >
                {{detalhes.saldo_antes | currency:'BRL':true}}
              </td>
            </tr>
            <tr  >
              <td >Saldo Depois</td>
              <td >
                {{detalhes.saldo_depois | currency:'BRL':true}}
              </td>
            </tr>
            <tr  >
              <td >Agência Destino</td>
              <td >{{detalhes.agencia_destino}}</td>
            </tr>
            <tr  >
              <td >Conta Destino</td>
              <td >{{detalhes.conta_destino}}</td>
            </tr>
            <tr  >
              <td >Agência Inicial</td>
              <td >{{detalhes.agencia_inicial}}</td>
            </tr>
            <tr  >
              <td >Conta Inicial</td>
              <td >{{detalhes.conta_inicial}}</td>
            </tr>
            <tr  >
              <td >CPF Destino</td>
              <td >{{detalhes.cpf_destino}}</td>
            </tr>
            <tr  >
              <td >CPF Inicial</td>
              <td >{{detalhes.cpf_inicial}}</td>
            </tr>
            <tr  >
              <td >Taxa</td>
              <td >{{detalhes.taxa}}</td>
            </tr>
            <tr  >
              <td >N Documento</td>
              <td >{{detalhes.documento}}</td>
            </tr>
            <tr  >
              <td > Codigo de Autenticação</td>
              <td >{{detalhes.codigo_autenticacao}}</td>
            </tr>
            <tr  >
              <td > Banco</td>
              <td >{{detalhes.banco}}</td>
            </tr>
            <tr  >
              <td > ID Transação API</td>
              <td >{{detalhes.id_trasacao_stone}}</td>
            </tr>
            <tr  >
              <td > Status</td>
              <td >{{detalhes.status}}</td>
            </tr>
            <tr  >
              <td > Codigo</td>
              <td >{{detalhes.codigo}}</td>
            </tr>

            </tbody>
          </table>

        </div>
        <div style="padding:  20px; ">
          <button type="button" (click)="cancelar()"  style="color: red;" class="btn  float-left">Cancelar</button>
        </div>
      </div>
    </div>
  </div>


</div>
