import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-beneficiario-vincular',
  templateUrl: './beneficiario-vincular.component.html',
  styleUrls: ['./beneficiario-vincular.component.css'],
  providers: [DatePipe]
})
export class BeneficiarioVincularComponent implements OnInit {

  editarConta = { cpf: '' , beneficio_id: '', password: '' };
  // tslint:disable-next-line:variable-name
  cpf = new FormControl('', [
    Validators.required,
  ]);
  erroform: any =  '0';
  passwordativo = false;
  modal = false;
  modalerro = false;
  constructor(private route: ActivatedRoute, private http: HttpClient,
              private datePipe: DatePipe, private router: Router) {
  }

  ngOnInit(): void {
    // @ts-ignore
    console.log(JSON.parse(  localStorage.getItem('beneficio')));
    // @ts-ignore
    this.editarConta.beneficio_id = JSON.parse(  localStorage.getItem('beneficio')).id ;
  }
  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    if (this.cpf.hasError('required')){ this.erroform = '1'; }
    // tslint:disable-next-line:triple-equals
    if(this.erroform == '0'){
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;
    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      // tslint:disable-next-line:triple-equals
      if (this.erroform == '0') {
        this.beneficiosvincular().subscribe(dados => {
          // console.log(dadosconta);
          // tslint:disable-next-line:triple-equals
          if ( dados == 0){
            this.modal = false;
            this.modalerro = true;
          }else{
            this.modal = true;
            this.modalerro = false;
            // this.router.navigate(['beneficios']);
          }
          // tslint:disable-next-line:triple-equals
        });
      }
      this.passwordativo = false;
    }
    console.log( senha);
  }


  // tslint:disable-next-line:typedef
  beneficiosvincular(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/beneficiosvincular`, this.editarConta);
  }

  // tslint:disable-next-line:typedef
  cancelar(){

    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/beneficios/beneficiarios']);
  }
  // tslint:disable-next-line:typedef
  fechar(){
    this.modalerro = false;
  }

}
