import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './views/home/home.component';
import {HomeadmComponent} from './adm/homeadm/homeadm.component';
import {AuthGuard} from './account/shared/auth.guard';
import {HomelayoutComponent} from './layout/homelayout/homelayout.component';
import {AuthenticationComponent} from './layout/authentication/authentication.component';
import {ContasComponent} from './adm/contas/contas.component';
import {RelatoriosComponent} from './adm/relatorios/relatorios.component';
import {TrasacoesComponent} from './adm/trasacoes/trasacoes.component';
import {ParceirosComponent} from './adm/parceiros/parceiros.component';
import {EditarcontasComponent} from './adm/contas/editarcontas/editarcontas.component';
import {UsuariosComponent} from './adm/usuarios/usuarios.component';
import {EditarusuariosComponent} from './adm/usuarios/editarusuarios/editarusuarios.component';
import {CarteirasComponent} from './adm/carteiras/carteiras.component';
import {EditarcarteirasComponent} from './adm/carteiras/editarcarteiras/editarcarteiras.component';
import {EditarcarteiraspComponent} from './adm/carteiras/editarcarteirasp/editarcarteirasp.component';
import {NovacarteirasComponent} from './adm/carteiras/novacarteiras/novacarteiras.component';
import {PermissoesComponent} from './adm/usuarios/permissoes/permissoes.component';
import {CriarusuariosComponent} from './adm/usuarios/criarusuarios/criarusuarios.component';
import {CriarpermissaoComponent} from './adm/usuarios/criarpermissao/criarpermissao.component';
import {TarifasComponent} from './adm/tarifas/tarifas.component';
import {EditarTarifasComponent} from './adm/tarifas/editar-tarifas/editar-tarifas.component';
import {CriarTarifasComponent} from './adm/tarifas/criar-tarifas/criar-tarifas.component';
import {LimitesContasComponent} from './adm/contas/limites-contas/limites-contas.component';
import {SenhaComponent} from './views/senha/senha.component';
import {DocumentosContaComponent} from './adm/contas/documentos-conta/documentos-conta.component';
import {BeneficiosComponent} from './adm/beneficios/beneficios.component';
import {BeneficiariosComponent} from './adm/beneficios/beneficiarios/beneficiarios.component';
import {CriarBeneficiosComponent} from './adm/beneficios/criar-beneficios/criar-beneficios.component';
import {EditarBeneficiosComponent} from './adm/beneficios/editar-beneficios/editar-beneficios.component';
import {BeneficiarioVincularComponent} from './adm/beneficios/beneficiarios/beneficiario-vincular/beneficiario-vincular.component';
import {LojistasComponent} from './adm/beneficios/lojistas/lojistas.component';
import {CriarContaComponent} from './adm/contas/criar-conta/criar-conta.component';
import {ContaTransacaoComponent} from './adm/contas/conta-transacao/conta-transacao.component';
import {ListarCadastroComponent} from './adm/cadastros/listar-cadastro/listar-cadastro.component';
import {EditarCadastroComponent} from './adm/cadastros/editar-cadastro/editar-cadastro.component';
import {DocumentosCadastroComponent} from './adm/cadastros/documentos-cadastro/documentos-cadastro.component';
import {BeneficiosvalorComponent} from './adm/contas/beneficios/beneficios.component';
import {ConvitesComponent} from './adm/convites/convites.component';
import {CriarContapjComponent} from "./adm/contas/criar-contapj/criar-contapj.component";
import {ConfiguracaoComponent} from "./adm/configuracao/configuracao/configuracao.component";

const routes: Routes = [

  {
    path: '', component: HomelayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeadmComponent },

      { path: 'clientes', component: ContasComponent },
      { path: 'clientes/editar', component: EditarcontasComponent },
      { path: 'clientes/limite', component: LimitesContasComponent },
      { path: 'clientes/documentos', component: DocumentosContaComponent },
      { path: 'clientes/transacao', component: ContaTransacaoComponent },
      { path: 'clientes/saldobeneficios', component: BeneficiosvalorComponent },
      { path: 'clientes/criarcontapj', component: CriarContapjComponent },

      { path: 'cadastros', component: ListarCadastroComponent },
      { path: 'cadastros/editar', component: EditarCadastroComponent },
      { path: 'cadastros/documentos', component: DocumentosCadastroComponent },
      { path: 'cadastros/criar', component: CriarContaComponent },


      { path: 'convites', component: ConvitesComponent },
      { path: 'config', component: ConfiguracaoComponent },


      { path: 'usuarios', component: UsuariosComponent },
      { path: 'usuarios/editar', component: EditarusuariosComponent },
      { path: 'usuarios/permissoes', component: PermissoesComponent },
      { path: 'usuarios/criar', component: CriarusuariosComponent },
      { path: 'usuarios/criarpermissoes', component: CriarpermissaoComponent },

      { path: 'carteiras', component: CarteirasComponent },
      { path: 'carteiras/editar', component: EditarcarteirasComponent },
      { path: 'carteiras/editarp', component: EditarcarteiraspComponent },
      { path: 'carteiras/criar', component: NovacarteirasComponent },

      { path: 'tarifas', component: TarifasComponent },
      { path: 'tarifas/editar', component: EditarTarifasComponent },
      { path: 'tarifas/criar', component: CriarTarifasComponent },

      { path: 'beneficios', component: BeneficiosComponent },
      { path: 'beneficios/editar', component: EditarBeneficiosComponent },
      { path: 'beneficios/criar', component: CriarBeneficiosComponent },
      { path: 'beneficios/beneficiarios', component: BeneficiariosComponent },
      { path: 'beneficios/vincular', component: BeneficiarioVincularComponent },
      { path: 'beneficios/lojistas', component: LojistasComponent },

      { path: 'relatorios', component: RelatoriosComponent },
      { path: 'transacoes', component: TrasacoesComponent },
      { path: 'parceiros', component: ParceirosComponent },

    ],
    canActivate: [AuthGuard]
  },

  {
    path: '', component: AuthenticationComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: HomeComponent },
      { path: 'senha', component: SenhaComponent },

    ],

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
