<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por cliente, número da conta, cartão de crédito...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>
    <button (click)="fitrar('bloqueadas')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Bloqueados</button>
    <button (click)="fitrar('reprovados')" type="button" [ngClass]="{'btn-selecinado': filtro === 'reprovados'}" class="btn btn-light2" style="margin-left: 10px;">Reprovados</button>
    <button (click)="fitrar('analise')" type="button" [ngClass]="{'btn-selecinado': filtro === 'analise'}" class="btn btn-light2" style="margin-left: 10px;">Em Análise</button>

    <button (click)="criarnovo()" type="button"  class="btn btn-light2 float-right btn_bluedark" style="">Cadastrar novo</button>

  </div>



<table class="table "    style="border-spacing: 0 8px;">
  <thead>
  <tr style="font-size: 13px!important;" >
    <td  *ngIf="order == 'id' && order_tipo == 'asc' || order != 'id'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
    <td  *ngIf="order == 'id' && order_tipo == 'desc'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

    <td  *ngIf="order == 'name' && order_tipo == 'asc' || order != 'name'">CLIENTE <span (click)="ordenar('name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
    <td  *ngIf="order == 'name' && order_tipo == 'desc'">CLIENTE <span (click)="ordenar('name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

    <td >CADASTRO</td>
    <td >CARTEIRA</td>

    <td  *ngIf="order == 'saldo' && order_tipo == 'asc' || order != 'saldo'" >SALDO <span (click)="ordenar('saldo')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
    <td  *ngIf="order == 'saldo' && order_tipo == 'desc'" >SALDO <span (click)="ordenar('saldo')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>
    <td >SALDO BMP</td>



    <td  *ngIf="order == 'role' && order_tipo == 'asc' || order != 'role'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
    <td  *ngIf="order == 'role' && order_tipo == 'desc'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



    <td></td>
    <td></td>
  </tr>
  </thead>
<tbody >
<tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
  <td >{{usuario.id}}</td>
  <td>

    <img *ngIf="usuario.avatar != ''" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{usuario.avatar}}">
    <img *ngIf="usuario.avatar == ''" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{avatar_padrao}}">
    <span *ngIf="usuario.contaPfPj == 'PF'"  style="margin-left: 10px;">
      <span *ngIf="usuario.namecorreto != null || usuario.namecorreto != ''">{{usuario.namecorreto}}</span>
      <span *ngIf="usuario.namecorreto == null || usuario.namecorreto == ''">{{usuario.name}}</span>
    </span>
    <span *ngIf="usuario.contaPfPj == 'PJ'"  style="margin-left: 10px;">{{usuario.razaoSocial}}</span>

  </td>
  <td>{{usuario.created_at | date: 'dd/MM/yy HH:MM:SS'}}</td>
  <td>{{usuario.nome_carteira }}</td>
  <td><span style="color: #4AD9A4;">{{usuario.saldo | currency:'BRL':true}}</span></td>
  <td><span style="color: #4AD9A4;">{{usuario.saldocelcoin | currency:'BRL':true}}</span></td>
  <td>
    <span *ngIf="usuario.role == 'Admin'" >Admin</span>
    <span *ngIf="usuario.role == 'User'" >Cliente</span>
    <span *ngIf="usuario.role == 'OPublico'" >Orgão Publico</span>
    <span *ngIf="usuario.role == 'Parceiro'" >Lojista</span>

  </td>

  <td>
    <button *ngIf="usuario.status == 'ativo'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Ativo</span></button>
    <button *ngIf="usuario.status == 'bloqueadas'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red;"></div> <span style="margin-left: 10px; font-size: 12px;">Bloqueado</span></button>
    <button *ngIf="usuario.status == 'reprovados'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #000;"></div> <span style="margin-left: 10px; font-size: 12px;">Reprovado</span></button>
    <button *ngIf="usuario.status == 'analise'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #000;"></div> <span style="margin-left: 10px; font-size: 12px;">Em Análise</span></button>
  </td>
  <td>
    <!-- Example split danger button -->
    <div class="btn-group">
      <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
      <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu">
        <a (click)="editar(usuario)" class="dropdown-item" >Editar</a>
        <a *ngIf="usuario.status == 'analise' || usuario.status == 'reprovados'" (click)="deletar(usuario)" class="dropdown-item" >Deletar</a>
        <a (click)="abrirususenha(usuario)" class="dropdown-item" >Redefinir senha</a>
        <a (click)="transacao(usuario)" class="dropdown-item" >Transações</a>
        <a  (click)="criarconta( usuario.id )" class="dropdown-item" >Criar conta BMP</a>
      </div>
    </div>
  </td>
</tr>
</tbody>
</table>

<nav aria-label="Page navigation example" class="">
  <ul class="pagination justify-content-end">
    <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
      <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
    </li>
    <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
      <a class="page-link" (click)="proximo(1)" >1</a>
    </li>

    <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
      <a class="page-link"  >...</a>
    </li>


    <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

    <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
      <a class="page-link"  >...</a>
    </li>

    <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
      <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
    </li>



    <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
      <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
    </li>
  </ul>
</nav>

  <div *ngIf="modal.erro == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> {{modal.msg}}</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalerro.erro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> {{modalerro.msg}} </p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-danger">Ok</button>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="modalmudarsenha == true" id="exampleModalLive2" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content ">
        <div  style="color: #FFF;  text-align: center; padding: 30px 30px 0 30px; ">
          <h5 style="color: #6e707e;  text-align: left; font-size: 18px;"  *ngIf="usu.contaPfPj == 'PF'"  >Redefinir senha para: <strong>{{usu.name}}</strong></h5>
          <h5 style="color: #6e707e;  text-align: left; font-size: 18px;"  *ngIf="usu.contaPfPj == 'PJ'"  >Redefinir senha para: <strong>{{usu.razaoSocial}}</strong></h5>
          <button type="button" class="close"  (click)="cancelar()"  style="margin-top: -35px;" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>


        </div>

        <div class="modal-body">
          <div class="form-check" style="padding: 20px;">
            <input class="form-check-input radioteste" style="color: #00C689;" [(ngModel)]="tipo" type="radio" id="exampleRadios1" value="email" checked>
            <label class="form-check-label " for="exampleRadios1">
              Enviar link para email
            </label>
          </div>
          <div class="form-check" style=" padding: 10px 20px;">
            <input class="form-check-input radioteste" style="color: #00C689;" [(ngModel)]="tipo" type="radio" id="exampleRadios2" value="editar">


            <label class="form-check-label " for="exampleRadios2">
              Criar nova senha
            </label>
          </div>

        </div>
        <div style="padding:  20px; ">
          <button type="button" (click)="mudar()" style="background-color: #2A5D67; color: #FFF; margin-right: 10px;"  class="btn  float-left" data-dismiss="modal">Redefinir</button>
          <button type="button" (click)="cancelar()"  style="color: red;" class="btn  float-left">Cancelar</button>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="modalsenhamudar == true" id="exampleModalLive78" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div  style="color: #FFF;  text-align: center; padding: 30px 30px 0 30px; ">
          <h5 style="color: #6e707e;  text-align: left; font-size: 25px;"   >Digite sua senha </h5>
          <button type="button" class="close"  (click)="cancelar()"  style="margin-top: -35px;" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>


        </div>

        <div class="modal-body" style="padding: 0px 30px;">
          <div class="form-group row">
            <div class="col-md-12">
              <label for="novasenha">Digite a sua senha de 6 dígitos</label>
              <input type="password" class="form-control form-control-user" (keypress)="numberOnly($event)"  maxlength="6" [(ngModel)]="usu.novasenha" id="novasenha"  name="novasenha"
                    >
              <div *ngIf="errocamposenha.tipo == 'obrigatorio' && errocamposenha.erro == true" style="color: red;">
                Campo obrigatório
              </div>
            </div>


            <div class="col-md-12" style="margin-top: 30px;">
              <h5 style="color: #6e707e;  text-align: left; font-size: 25px;"   >Confirme a sua senha</h5>

              <label for="confimarsenha">Digite a sua senha de 6 dígitos</label>
              <input type="password" (keypress)="numberOnly($event)"  class="form-control form-control-user" maxlength="6"  [(ngModel)]="confimarsenha" id="confimarsenha"   name="confimarsenha"
                    >
              <div *ngIf="errocamposenha.tipo == 'senhasdiferente' && errocamposenha.erro == true" style="color: red;">
                As senhas não são iguais.
              </div>
            </div>
            </div>

        </div>
        <div style="padding:  20px; ">
          <button type="button" (click)="cadatrarsenha()" style="background-color: #2A5D67; color: #FFF; margin-right: 10px;"  class="btn  float-left" data-dismiss="modal">Confirmar</button>
          <button type="button" (click)="cancelar()"  style="color: red;" class="btn  float-left">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
  <app-password *ngIf="passwordativo == true" (senha)="Password($event)"></app-password>

</div>
