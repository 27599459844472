<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%; border-bottom: 1px solid #E7E8F2;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/editar']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Benefícios</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/lojistas']">
          <span class=" d-sm-inline" style="margin-left: 20px;"> Vincular Lojistas</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

    </ul>
  </div>


  <div class="col-md-8" style="padding: 30px;" (submit)="atualizarConta()">


    <h2 style="color: #33343E; font-weight: normal;"> Editar Benefício</h2>

    <div class="form-group row" style="margin-top: 20px;">
      <div class="col-md-12 mb-3 mb-sm-0" >
        <label for="name">Nome do benefício <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="name" id="name" [formControl]="name" [(ngModel)]="editarConta.name"
               placeholder="Nome" required>
        <div *ngIf="name.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
    </div>

    <div class="form-group row">

      <div class="col-md-6">
        <label for="beneficio_tipo">Tipo de benefício <span style="color: red;">*</span></label>

        <select name="sexo"  class="form-control" required [(ngModel)]="editarConta.beneficio_tipo" [formControl]="beneficio_tipo" id="beneficio_tipo">
          <option *ngFor="let tipobeneficio of tipobeneficios ; let i = index"  value="{{tipobeneficio.id}}">{{tipobeneficio.name}}</option>
        </select>
        <div *ngIf="beneficio_tipo.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="periodicidade">Periodicidade do pagamento <span style="color: red;">*</span></label>

        <select name="sexo"  required  class="form-control" [(ngModel)]="editarConta.periodicidade" [formControl]="periodicidade" id="periodicidade">
          <option selected value="Mensal">Mensal</option>
          <option value="Anual">Anual</option>
        </select>
        <div *ngIf="periodicidade.hasError('required') && (erroform == '1' || erroform == '2') " style="color: red;">
          Campo obrigatório
        </div>
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="fatorGerador">Fator Gerador <span style="color: red;">*</span></label>
        <select name="sexo" required class="form-control" [(ngModel)]="editarConta.fatorGerador" [formControl]="fatorGerador" id="fatorGerador">
          <option selected value="Mensal">Mensal</option>
          <option value="Anual">Anual</option>
        </select>
        <div *ngIf="fatorGerador.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="valorFator">Valor por fator <span style="color: red;">*</span></label>

        <input type="text" class="form-control form-control-user" currencyMask  name="valorFator" [formControl]="valorFator" [options]="{ prefix: '', thousands: '.', decimal: ',',align:'left' }"  id="valorFator" [(ngModel)]="editarConta.valorFator"
               placeholder="Valor">
        <div *ngIf="valorFator.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>

      </div>
    </div>
    <div class="form-group row">

      <div class="col-md-6">
        <label >Status </label>
        <br>
        <div class="form-check ">

          <input type="radio" class="form-check-input" id="ativo" name="nacionalidade"  value="ativo" [(ngModel)]="editarConta.status" >
          <label class="form-check-label" for="ativo">Ativo</label>
        </div>

        <div class="form-check " style="margin-top: 10px;">
          <input type="radio" name="nacionalidade" class="form-check-input" value="bloqueadas" [(ngModel)]="editarConta.status" id="bloqueadas">

          <label class="form-check-label" for="bloqueadas">Desativado</label>
        </div>


      </div>

      <div class="col-md-6">
        <label >Apresenta prazo de término?</label>
        <br>
        <div class="form-check ">

          <input type="radio" class="form-check-input" id="Sim" name="prazoTermino" value="Sim" [(ngModel)]="editarConta.prazoTermino" >
          <label class="form-check-label" for="Sim">Sim</label>
        </div>

        <div class="form-check " style="margin-top: 10px;">
          <input type="radio" name="prazoTermino" class="form-check-input" value="Não" [(ngModel)]="editarConta.prazoTermino" id="Não">

          <label class="form-check-label" for="Não">Não</label>
        </div>


      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="diaPagamento">Dia do próximo pagamento <span style="color: red;">*</span> </label>
        <input type="number" required class="form-control form-control-user" [formControl]="diaPagamento"  [(ngModel)]="editarConta.diaPagamento" name="diaPagamento" id="diaPagamento"
        >
        <div *ngIf="diaPagamento.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
        <div *ngIf="diaPagamento.hasError('max') " style="color: red; margin-top: 5px; font-size: 10px;">
          Só é possível realizar o agendamento automático até o dia 28.
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0" *ngIf="editarConta.periodicidade == 'Anual'">
        <label for="diaPagamento">Mês do próximo pagamento <span style="color: red;">*</span> </label>
        <input type="number" required class="form-control form-control-user" [formControl]="mesPagamento"  [(ngModel)]="editarConta.mesPagamento" name="diaPagamento" id="diaPagamento"
        >
        <div *ngIf="mesPagamento.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="datafimpagamanto">Data de fim do pagamento <span style="color: red;">*</span></label>
        <input type="text" required class="form-control form-control-user" [formControl]="datafimpagamanto" [(ngModel)]="editarConta.datafimpagamanto" mask="00/00/0000" name="datafimpagamanto" id="datafimpagamanto"
               placeholder="DD/MM/AAAA">
        <div *ngIf="datafimpagamanto.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="diaPagamento">Cartão do benefício <span style="color: red;">*</span></label>
        <div style="width: 90%; height: 200px; background-color: #25384D; border-radius: 10px; " >
          <h2 class="img_bg" style=" height: 200px; text-align: center; border-radius: 10px; background-image: url('../../../../assets/bg_social_bankis.svg');" >
            <img src="{{imglogo}}" alt="" width="50%" style="margin-top: 70px;">
          </h2>

        </div>
      </div>
      <div class="col-md-6" style="text-align: center;">

        <div  (click)="fileInput.click()" style="width: 90%; margin-top: 50px; border-radius: 10px; cursor: pointer; background-color: #FFF; padding: 10px 5px; -webkit-box-shadow: -4px 1px 29px 3px rgba(0,0,0,0.10); box-shadow: -4px 1px 29px 3px rgba(0,0,0,0.10); ">
          <span><svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.2" cx="24.9627" cy="24" rx="24.5373" ry="24" fill="#4AD9A4"/>
<rect x="13.7164" y="16" width="22.4925" height="16" rx="2" stroke="#4AD9A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7164 22H36.209" stroke="#4AD9A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> &nbsp;&nbsp;&nbsp;
 Faça o upload de um novo modelo</span>
          <input #fileInput type="file" (change)="onSelectFile($event)" style="display:none;" />
        </div>
        <div *ngIf="erroform == 2" style="color: red; width: 100%; text-align: left; margin-top: 3px;">
          Campo obrigatório
        </div>
      </div>

    </div>


    <a  class="btn btn-primary" (click)="abrirsenha()"   style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;" >
      Save
    </a>
    <a class="btn "  (click)="cancelar()" style="width: 200px; color: #FFF; height: 40px; color: red;">Cancelar</a>


  </div>


</div>


<div *ngIf="modal == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Benefício alterado com sucesso!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="fechar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Erro ao alterar, tente novamente mais tarde!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="fechar()"  class="btn btn-danger">Ok</button>
      </div>
    </div>
  </div>
</div>

<app-password *ngIf="passwordativo == true" (senha)="Password($event)">

</app-password>
