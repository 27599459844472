import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
// @ts-ignore
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-convites',
  templateUrl: './convites.component.html',
  styleUrls: ['./convites.component.css']
})
export class ConvitesComponent implements OnInit {


  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;

  select: any = [];


  inicio: number | undefined;
  limite: number | undefined;

  editarConta = { email: '' , id: '', password: '' };

  public filtro = 'tudo';
  dadosenviaracao: any = {array: []};
  pesquisa = {texto: ''};
  usuarios: any = [];
  usu: any = {novasenha: '', password: ''};
  tipo: any = 'email';
  iddados: any = '';
  url: any;
  tiposenha: any = '';
  confimarsenha: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'pedir_convites.id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  modal = {erro: false, msg: '' };
  modalmudarsenha = false;
  modalsenhamudar = false;
  modalacao = false;
  modalacaotexto = '';
  modalerro = {erro: false, msg: '' };
  passwordativo = false;
  errocamposenha = {erro: false, tipo: ''};
  erroform: any =  '0';
  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    if ( sessionStorage.getItem('filtro_convite')){
      // @ts-ignore
      this.filtro = sessionStorage.getItem('filtro_convite');
    }
    if ( sessionStorage.getItem('pesquisar_convite')){
      // @ts-ignore
      this.pesquisa.texto = sessionStorage.getItem('pesquisar_convite');
    }
    if ( sessionStorage.getItem('ordenar_convite')){
      // @ts-ignore
      this.order = sessionStorage.getItem('ordenar_convite');
    }
    if ( sessionStorage.getItem('order_tipo_convite')){
      // @ts-ignore
      this.order_tipo = sessionStorage.getItem('order_tipo_convite');
    }
    if ( sessionStorage.getItem('paginaatual_convite')){
      // @ts-ignore
      this.paginaAtual = sessionStorage.getItem('paginaatual_convite');
    }


    this.getAll().subscribe(async users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
      if (this.usuarios.status){
        this.router.navigate(['login']);
      }

    });
  }
  // tslint:disable-next-line:typedef
  getAll(){
    // return this.http.get(`${environment.apiContas}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/app/convites/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);

  }
  // tslint:disable-next-line:typedef
  criarnovo(){
    this.router.navigate(['cadastros/criar']);
  }

  // tslint:disable-next-line:typedef
  getfiltro(){
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/app/convites/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
// @ts-ignore
    sessionStorage.setItem('paginaatual_convite', this.paginaAtual);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;
    sessionStorage.setItem('filtro_convite', this.filtro);
    sessionStorage.setItem('paginaatual_convite', '1' );
    this.paginaAtual = 1;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    sessionStorage.setItem('ordenar_convite', this.order);
    this.paginaAtual = 1;
    sessionStorage.setItem('paginaatual_convite', '1' );
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    sessionStorage.setItem('order_tipo_convite', this.order_tipo);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    sessionStorage.setItem('pesquisar_convite', this.pesquisa.texto);
    sessionStorage.setItem('paginaatual_convite', '1' );
    this.paginaAtual = 1;
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  enviar(convite: any){
    this.select = [];
    this.select.push(convite);
    this.enviardados().subscribe(users => {
      // tslint:disable-next-line:triple-equals
      if ( users == 1){
        this.getfiltro().subscribe(dados => {
          this.usuarios = dados;
          // tslint:disable-next-line:triple-equals
          Swal.fire({
            icon: 'success',
            title: 'Email Enviado.',
          });
        });
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erro ao Enviar!',
        });
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviartodos(){
    this.enviardados().subscribe(users => {
      // tslint:disable-next-line:triple-equals
      if ( users == 1){
        this.getfiltro().subscribe(dados => {
          this.usuarios = dados;
          // tslint:disable-next-line:triple-equals
          Swal.fire({
            icon: 'success',
            title: 'Emails Enviados.',
          });
        });
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erro ao Enviar!',
        });
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  deletarmodal(id: any){
    this.iddados = id;
    this.modal.erro = true;
  }
  // tslint:disable-next-line:typedef
  deletar(){
    this.deletardados(this.iddados).subscribe(users => {
    console.log(users);
      // tslint:disable-next-line:triple-equals
    if ( users  == '1'){
      this.getfiltro().subscribe(dados => {
        this.usuarios = dados;
        // tslint:disable-next-line:triple-equals
      });
      this.modal.erro = false;
    }else {
      this.modal.erro = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Erro ao Deletar!',
      });
    }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  cancelar(){
   this.modal.erro = false;
  }


  // tslint:disable-next-line:typedef
  enviardados(){
    this.dadosenviaracao.array = this.select;
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/app/convites/enviar`, this.dadosenviaracao);
  }

  // tslint:disable-next-line:typedef
  deletardados(id: any){
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/app/convites/deletar/` + id);
  }
  numberOnly( event: any ): boolean {
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // tslint:disable-next-line:typedef
  selecionartudo(isChecked: Event) {
    this.select = [];
    // @ts-ignore
    if (isChecked.target.checked){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usuarios.data.length; i++) {
        this.usuarios.data[i].selected = true;
        this.select.push(this.usuarios.data[i].id);
      }
    }else{

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.usuarios.data.length; i++) {
        this.usuarios.data[i].selected = false;
        const index = this.select.indexOf(this.usuarios.data[i].id);
        this.select.splice(index, 1);
      }
    }
    console.log(this.select);
  }
  // tslint:disable-next-line:typedef
  onChange(id: string, isChecked: Event) {
    // @ts-ignore
    if (isChecked.target.checked) {
      this.select.push(id);
    } else {
      const index = this.select.indexOf(id);
      this.select.splice(index, 1);
    }
    console.log(this.select);
  }
}
