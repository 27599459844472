import { Component, OnInit } from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-criar-tarifas',
  templateUrl: './criar-tarifas.component.html',
  styleUrls: ['./criar-tarifas.component.css'],
  providers: [DatePipe]
})
export class CriarTarifasComponent implements OnInit {


  model: NgbDateStruct | any;
  carteiras: any = [];
  usuarios: any = '';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', atividade: '', status: '', descricao: '', periodicidade: '', carteira_id: '', valor: '', data_cobranca: '', dias_cobranca: ''};
  cidades: any;
  erro: any = 0;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private router: Router) { }

  ngOnInit(): void {
    this.url = environment.url;


    this.getCarteiras().subscribe(users => {
      this.carteiras = users;
      console.log(this.carteiras);
      // tslint:disable-next-line:triple-equals
      if (this.carteiras.status){
        this.router.navigate(['login']);
      }

    });

  }
  // tslint:disable-next-line:typedef
  getCarteiras() {
    return this.http.get(`${environment.apiCarteiras}/getall2?` + 'order=id&order_tipo=asc');
  }










  // tslint:disable-next-line:typedef
  atualizarConta(){
    this.enviarDadosConta().subscribe(dadosconta => {
      console.log(dadosconta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
        this.erro = 1;
      }else{
        this.erro = 0;
        this.router.navigate(['tarifas']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiTarifa}/store`, this.editarConta);
  }
}
