<div class="row" style="background-color: #FFF; margin: 10px!important;">
  <div class="col-md-12 " >
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
  </div>

  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 " id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/editar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Conta</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/documentos']">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 17.4168H4.74999C3.87554 17.4168 3.16666 16.7079 3.16666 15.8335V3.16683C3.16666 2.29238 3.87554 1.5835 4.74999 1.5835H10.2917C10.3003 1.58387 10.3088 1.58547 10.317 1.58825C10.3247 1.59063 10.3327 1.59223 10.3407 1.593C10.4106 1.59747 10.4795 1.61104 10.5458 1.63337L10.5679 1.6405C10.5859 1.64658 10.6034 1.65399 10.6202 1.66266C10.7064 1.701 10.785 1.75464 10.8522 1.821L15.6022 6.571C15.6685 6.63812 15.7222 6.71671 15.7605 6.80295C15.7684 6.82037 15.7739 6.83858 15.7803 6.85679L15.7874 6.87737C15.8095 6.94339 15.8226 7.0121 15.8262 7.08162C15.8274 7.08889 15.8293 7.09604 15.8317 7.103C15.8331 7.1103 15.8337 7.11774 15.8334 7.12516V15.8335C15.8334 16.7079 15.1244 17.4168 14.25 17.4168ZM4.74999 3.16683V15.8335H14.25V7.91683H10.2917C9.85443 7.91683 9.49999 7.56239 9.49999 7.12516V3.16683H4.74999ZM11.0833 4.28625V6.3335H13.1306L11.0833 4.28625Z" fill="#A7A9C0"/>
          </svg>

          <span class=" d-sm-inline" style="margin-left: 20px;">Documentos </span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/limite']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Limites</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/saldobeneficios']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Saldo beneficios</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>

    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal; margin-bottom: 60px;"> Documentos enviados</h2>

    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;">Envios</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-12 " style="border: 1.5px solid #E7E8F2; box-sizing: border-box; border-radius: 12px;">
        <div class="row">

          <div class="col-md-12 " style="padding: 20px;">
         <div class="float-right" style="text-align: right;">
           <p *ngIf="editarConta.tipoDoc == 'CH'">Documento escolhido: CNH</p>
           <p *ngIf="editarConta.tipoDoc == 'RG'">Documento escolhido: RG</p>
           <p *ngIf="editarConta.tipoDoc == 'PS'">Documento escolhido: PASSAPORTE</p>
           <p>Data de envio:  {{editarConta.updated_at | date :'d/MM/y' }} ás {{editarConta.updated_at | date :' h:mm'}}</p>
         </div>
            <div class="float-left" style="text-align: left;">
           <p>nome: {{editarConta.name}}</p>
           <p *ngIf="editarConta.contaPfPj == 'PF'">CPF: {{editarConta.cpf}}</p>
           <p *ngIf="editarConta.contaPfPj == 'PJ'">CNPJ: {{editarConta.cnpj}}</p>
         </div>
          </div>

        <div class="col-md-4 " style="padding: 20px;">
          <img src="data:image/jpeg;base64,{{documentos.docFrente}}" alt="" width="100%" >
        </div>
<!--          <div class="col-md-4 " >-->
<!--          </div>-->
<!--        <div class="col-md-4 " style="padding: 20px;">-->
<!--          <a class="btn btn-primary float-right" target="_blank" href="{{url}}imagem/verdocumento/{{editarConta.id}}/conta">Documentos</a>-->
<!--        </div>-->
        <div class="col-md-4 " style="padding: 20px;">
          <img src="data:image/jpeg;base64,{{documentos.docVerso}}" alt="" width="100%" >
        </div>
        <div class="col-md-4 " style="padding: 20px;">
          <img src="data:image/jpeg;base64,{{documentos.selfie}}" alt="" width="100%" >
        </div>
 </div>

      </div>

    </div>







    <div class="row">

      <div class="col-md-12" style="text-align: right;">
        <a *ngIf="editarConta.status != 'ativo'" class="btn btn-primary float-right"  (click)="atualizarConta('aprovar')" style="width: 200px; float:right; font-weight:normal;    color: #FFF; height: 40px; background-color: #306C73;">Aprovar</a>
        <a class="btn btn-outline-danger float-right"  (click)="atualizarConta('bloquear')" style="width: 200px; height: 40px; margin: 0px 20px; background-color: red; font-weight:normal; color: #FFF; margin-left: 10px; border: none; ">Bloquear</a>

        <a class="btn btn-outline-danger float-right"  (click)="atualizarConta('reprovar')" style="width: 200px; height: 40px; font-weight:normal; color: red; margin-left: 10px; border: none; ">Reprovar</a>

      </div>
    </div>
  </div>


</div>
<div *ngIf="tipo == 'aprovar'" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success" style="color: #FFF;" >
        <h5 class="modal-title" id="exampleModalLiveLabel">Aprovar Documentos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Tem certeza que deseja Aprovar esses Documentos?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="atualizarConta('inativo')"  class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="mudar('aprovar')"  class="btn btn-success">Aprovar</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="tipo == 'bloquear'" id="exampleModalLivebloquear" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-danger" style="color: #FFF;" >
        <h5 class="modal-title" id="exampleModalLiveLabelbloquear">Bloquear Usuario</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Tem certeza que deseja Bloquear esse Usuario?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="atualizarConta('inativo')"  class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="mudar('bloquear')"  class="btn btn-danger">Bloquear</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="tipo == 'reprovar'" id="exampleModalLive2" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div  style="color: #FFF;  text-align: center; padding: 20px 20px 0 20px; border-bottom: 1px solid #dee2e6;">
        <h5 style="color: #6e707e;  text-align: center; font-size: 18px;" id="exampleModalLiveLabel1">Reprovar Documentos</h5>
        <button type="button" class="close"  (click)="atualizarConta('inativo')"  style="margin-top: -35px;" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <div style="color: #00C689; text-align: left;"> Qual comentario?</div>

      </div>

      <div class="modal-body">
        <div class="form-check">
          <input class="form-check-input radioteste" style="color: #00C689;" [(ngModel)]="pergunta" type="radio" id="exampleRadios1" value="1" checked>
          <label class="form-check-label " for="exampleRadios1">
            Por gentileza, acesse o app e faça o reenvio das fotos dos documentos, pois ficaram ilegíveis.
          </label>
        </div>
        <div class="form-check" style="margin-top: 20px;">
          <input class="form-check-input radioteste" style="color: #00C689;" [(ngModel)]="pergunta" type="radio" id="exampleRadios2" value="2">


          <label class="form-check-label " for="exampleRadios2">
            Por gentileza, acesse o app e faça o reenvio da selfie segurando o seu respectivo {{editarConta.tipoDoc}}
          </label>
        </div>

      </div>
      <div style="padding:  20px; border-top: 1px solid #dee2e6;">
        <button type="button" (click)="atualizarConta('inativo')" style="background-color: #2A5D67; color: #FFF; margin-right: 10px;"  class="btn  float-left" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="mudar('reprovar')"  style="color: red;" class="btn  float-left">Reprovar</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="modal.erro == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> {{modal.msg}}</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalerro.erro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> {{modalerro.msg}} </p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="cancelar()"  class="btn btn-danger">Ok</button>
      </div>
    </div>
  </div>
</div>
