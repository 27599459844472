import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.css']
})
export class TarifasComponent implements OnInit {


  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;
  public filtro = 'tudo';
  pesquisa = {texto: ''};
  usuarios: any = [];
  url: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url;
    this.getAll().subscribe(users => {
      this.usuarios = users;
      console.log(this.usuarios);
      // tslint:disable-next-line:triple-equals
      if (this.usuarios.status){
        this.router.navigate(['login']);
      }

    });
  }
  // tslint:disable-next-line:typedef
  getAll(){
    return this.http.get(`${environment.apiTarifa}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
  }


  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiTarifa}/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;

    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  editar(id: any){
    console.log('çasçsaçsalçs');

    this.router.navigate(['tarifas/editar']);
    // @ts-ignore
    localStorage.setItem('editar_tarifa_dados', JSON.stringify(id));
  }

}
