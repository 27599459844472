



<div class="form-group row" style="margin-top: 30px;">

  <div class="col-md-10 offset-md-1 " style="border: 1.5px solid #E7E8F2; box-sizing: border-box; border-radius: 12px;">
    <div class="row">

      <div class="col-md-12 " style="padding: 20px;">
        <div class="float-right" style="text-align: right;">
          <p *ngIf="editarConta.tipoDoc == 'CH'"><strong>Documento escolhido: </strong> CNH</p>
          <p *ngIf="editarConta.tipoDoc == 'RG'"><strong>Documento escolhido: </strong> RG</p>
          <p *ngIf="editarConta.tipoDoc == 'PS'"><strong>Documento escolhido: </strong> PASSAPORTE</p>
          <p><strong> Data da Criação: </strong>  {{editarConta.created_at | date :'d/MM/y' }} ás {{editarConta.created_at | date :' h:mm'}}</p>

        </div>
        <div class="float-left" style="text-align: left;">
          <p *ngIf="editarConta.contaPfPj == 'PF'"><strong>NOME: </strong>{{editarConta.name}}</p>
          <p *ngIf="editarConta.contaPfPj == 'PJ'"><strong>NOME: </strong> {{editarConta.razaoSocial}}</p>

          <p *ngIf="editarConta.contaPfPj == 'PF'"><strong>CPF: </strong> {{editarConta.cpf}}</p>
          <p *ngIf="editarConta.contaPfPj == 'PJ'"><strong>CNPJ: </strong> {{editarConta.cnpj}}</p>

          <p><strong> SALDO: </strong>  {{editarConta.saldo | currency:'BRL':true }}</p>
        </div>
      </div>


    </div>
    <div class="row">

      <div class="col-md-12" style="text-align: right;">
        <a *ngIf="editarConta.status != 'ativo'" class="btn btn-primary float-right"  (click)="atualizarConta('aprovar')" style="width: 200px; float:right; font-weight:normal;    color: #FFF; height: 40px; background-color: #306C73;">Aprovar</a>
        <a class="btn btn-outline-danger float-right"  (click)="atualizarConta('bloquear')" style="width: 200px; height: 40px; margin: 0px 20px; background-color: red; font-weight:normal; color: #FFF; margin-left: 10px; border: none; ">Bloquear</a>


      </div>
    </div>

  </div>

</div>



<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"
             id="inlineFormInputGroupUsername" placeholder="Buscar por Transações, Código autenticação, Numero do Documento...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('1')" type="button" [ngClass]="{'btn-selecinado': filtro === '1'}" class="btn btn-light2 " style="margin-left: 10px;">Ativas</button>
    <button (click)="fitrar('0')" type="button" [ngClass]="{'btn-selecinado': filtro === '0'}" class="btn btn-light2" style="margin-left: 10px;">Desativadas</button>

  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >
      <td  *ngIf="order == 'id' && order_tipo == 'asc' || order != 'id'"> ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'id' && order_tipo == 'desc'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


      <td  *ngIf="order == 'name_inicial' && order_tipo == 'asc' || order != 'name_inicial'"> DE <span (click)="ordenar('name_inicial')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'name_inicial' && order_tipo == 'desc'">DE <span (click)="ordenar('name_inicial')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'name_destino' && order_tipo == 'asc' || order != 'name_destino'"> PARA <span (click)="ordenar('name_destino')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'name_destino' && order_tipo == 'desc'"> PARA <span (click)="ordenar('name_destino')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'created_at' && order_tipo == 'asc' || order != 'created_at'"> DATA <span (click)="ordenar('created_at')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'created_at' && order_tipo == 'desc'"> DATA <span (click)="ordenar('created_at')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>





      <td  *ngIf="order == 'valor' && order_tipo == 'asc' || order != 'valor'" >VALOR <span (click)="ordenar('valor')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'valor' && order_tipo == 'desc'" >VALOR <span (click)="ordenar('valor')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'role' && order_tipo == 'asc' || order != 'role'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'role' && order_tipo == 'desc'" >TIPO <span (click)="ordenar('role')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td >{{usuario.id}}</td>
      <td>
        <span  style="margin-left: 10px;">{{usuario.name_inicial}}</span>
      </td>
      <td>
        <span  style="margin-left: 10px;">{{usuario.name_destino }}</span>
      </td>

      <td>{{usuario.created_at_ok | date: 'dd/MM/yy HH:MM:SS'}}</td>

      <td>
        <span *ngIf="usuario.tipo_entrada == '1'" style="color: red;"> -{{usuario.valor | currency:'BRL':true}}</span>
        <span *ngIf="usuario.tipo_entrada == '2'" style="color: #4AD9A4;">+{{usuario.valor | currency:'BRL':true}}</span>
      </td>
      <td>
        <span  >{{tipotrans [usuario.tipo].tipo}}</span>


      </td>

      <td>
        <button *ngIf="usuario.status == '1'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Ativo</span></button>
        <button *ngIf="usuario.status == '0'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red;"></div> <span style="margin-left: 10px; font-size: 12px;">Desativada</span></button>

      </td>
      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">

            <a *ngIf="usuario.status == '1'"  (click)="desativar(usuario)" class="dropdown-item" >Desativar</a>
            <a  *ngIf="usuario.status == '0'" (click)="ativar(usuario)" class="dropdown-item" >Ativar</a>

          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>
      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(1)" >1</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>


      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
      </li>



      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>

  <div *ngIf="modal.erro == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> {{modal.msg}}</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalerro.erro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> {{modalerro.msg}} </p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-danger">Ok</button>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="tipostatus == 'aprovar'" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success" style="color: #FFF;" >
          <h5 class="modal-title" id="exampleModalLiveLabel">Aprovar Documentos</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja Aprovar esses Documentos?</p>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="atualizarConta('inativo')"  class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="mudar('aprovar')"  class="btn btn-success">Aprovar</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tipostatus == 'bloquear'" id="exampleModalLivebloquear" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-danger" style="color: #FFF;" >
          <h5 class="modal-title" id="exampleModalLiveLabelbloquear">Bloquear Usuario</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja Bloquear esse Usuario?</p>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="atualizarConta('inativo')"  class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="mudar('bloquear')"  class="btn btn-danger">Bloquear</button>
        </div>
      </div>
    </div>
  </div>



  <app-password *ngIf="passwordativo == true" (senha)="Password($event)"></app-password>

</div>
