import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css']
})
export class ConfiguracaoComponent implements OnInit {
  user: any = [];
  svg: any = '';
  constructor( private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.getusuariodados().subscribe( async user => {
      this.user = user;
      if(this.user.google2fa_secret){
        this.parseSvg(this.user.qrcode);
      }

      });





  }

  parseSvg(data: any) {

      this.svg = this.sanitizer.bypassSecurityTrustHtml(data);
  }


  getusuariodados(){
    return this.http.get(`${environment.api}/user`);
  }

  ativar(){
    this.ativardados().subscribe( async user => {
      this.user = user;

      if(this.user.google2fa_secret){
        this.parseSvg(this.user.qrcode);
      }
    });

  }


  ativardados(){
    return this.http.get(`${environment.api}/ativar2fa`);
  }

}
