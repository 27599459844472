<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/cadastros/criar']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Cadastrar Cliente</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/criarcontapj']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Cadastrar PJ</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>



    </ul>
  </div>


  <div class="col-md-8" style="padding: 30px;" (submit)="atualizarConta()">


    <h2 style="color: #33343E; font-weight: normal;"> Cadastrar Cliente</h2>
    <div *ngFor="let c of errosgeral.cnpj let i = index" class="errogeral">
      Erro CNPJ: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cpf let i = index" class="errogeral">
      Erro CPF:  {{c}}
    </div>
    <div *ngFor="let c of errosgeral.email let i = index" class="errogeral">
      Erro EMAIL: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.password let i = index" class="errogeral">
      Erro SENHA: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.razaoSocial let i = index" class="errogeral">
      Erro RAZÃO SOCIAL: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.nomeFatasia let i = index" class="errogeral">
      Erro NOME FANTASIA: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.endereco let i = index" class="errogeral">
      Erro ENDEREÇO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.numero let i = index" class="errogeral">
      Erro NUMERO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.bairro let i = index" class="errogeral">
      Erro BAIRRO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cidade let i = index" class="errogeral">
      Erro CIDADE: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.estado let i = index" class="errogeral">
      Erro ESTADO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.sexo let i = index" class="errogeral">
      Erro SEXO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.cep let i = index" class="errogeral">
      Erro CEP: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.nascimento let i = index" class="errogeral">
      Erro NASCIMENTO: {{c}}
    </div>
    <div *ngFor="let c of errosgeral.telefone let i = index" class="errogeral">
      Erro TELEFONE: {{c}}
    </div>
    <div class="form-group row">


      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="name"> Nome <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="name" id="name" [formControl]="name" [(ngModel)]="editarConta.name"
               placeholder="Nome" required>
        <div *ngIf="name.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="name">CPF <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" (keypress)="numberOnly($event)"  mask="000.000.000-00" name="cpf" id="cpf" [formControl]="cpf" [(ngModel)]="editarConta.cpf"
               placeholder="CPF" required>
        <div *ngIf="cpf.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      </div>

      <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0" >
        <label for="nomeMae">Nome da mãe <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user"   name="nomeMae" id="nomeMae" [formControl]="nomeMae" [(ngModel)]="editarConta.nomeMae"
               placeholder="Nome da mãe" required>
        <div *ngIf="nomeMae.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>


    </div>
    <div class="form-group row">


      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="name"> Email <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="email" id="email" [formControl]="email" [(ngModel)]="editarConta.email"
               placeholder="Email" required>
        <div *ngIf="email.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
        <div *ngIf="email.hasError('email') && (erroform == '1' || erroform == '2')" style="color: red;">
        O campo deve ser um email válido.
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="name">Telefone <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" (keypress)="numberOnly($event)"  mask="(00) 0 0000-0000" name="telefone" id="telefone" [formControl]="telefone" [(ngModel)]="editarConta.telefone"
               placeholder="Telefone" required>
        <div *ngIf="telefone.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>


    </div>

    <div class="form-group row">

      <div class="col-md-6">
        <label for="sexo">Sexo <span style="color: red;">*</span></label>

        <select name="sexo"  required  class="form-control" [(ngModel)]="editarConta.sexo" [formControl]="sexo" id="sexo">
          <option selected value="m">Masculino</option>
          <option value="f">Feminino</option>
          <option value="p">Prefiro não dizer</option>
        </select>
        <div *ngIf="sexo.hasError('required') && (erroform == '1' || erroform == '2') " style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="nascimento">Aniversário <span style="color: red;">*</span></label>
        <input type="text" required class="form-control form-control-user" (keypress)="numberOnly($event)"  [formControl]="nascimento" [(ngModel)]="editarConta.nascimento" mask="00/00/0000" name="nascimento" id="nascimento"
               placeholder="DD/MM/AAAA">
        <div *ngIf="nascimento.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

    </div>
    <div class="form-group row">


      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="profissao"> Profissão <span style="color: red;">*</span></label>
        <select name="profissao"  required  class="form-control" [(ngModel)]="editarConta.profissao" [formControl]="profissao" id="profissao">
          <option selected value="Servidor Público">Servidor Público</option>
        </select>
        <div *ngIf="email.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6">
        <label >Nacionalidade</label>
        <br>

        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" id="nacionalidades" name="nacionalidade" value="BR" [(ngModel)]="editarConta.nacionalidade" >
          <label class="form-check-label" for="nacionalidades">Brasileiro</label>
        </div>
        <div class="form-check form-check-inline">
          <input type="radio" name="prazoTermino" class="form-check-input" value="ES" [(ngModel)]="editarConta.nacionalidade" id="nacionalidaden">

          <label class="form-check-label" for="nacionalidaden">Estrangeiro</label>
        </div>



      </div>


    </div>


    <div class="form-group row">


      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="cep"> CEP <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" maxlength="9" (keypress)="numberOnly($event)"  (keyup)="getcep($event , inputitem)" #inputitem mask="00000-000" name="cep" id="cep" [formControl]="cep" [(ngModel)]="editarConta.cep"
               placeholder="CEP" required>
        <div *ngIf="cep.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="estado"> Estado <span style="color: red;">*</span></label>
        <select name="estado" [(ngModel)]="editarConta.estado" class="form-control" id="estado" (ngModelChange)="onAddCidade()" required="required">
          <option value="">Selecione um Estado</option>
          <option *ngFor="let estado of dado_estados ; let i = index" [ngValue]="estado.abbreviation" value="{{estado.abbreviation}}">{{estado.name}}</option>
        </select>
        <div *ngIf="(  erroform == '1' && erroestado == '1' )" style="color: red;">
          Campo obrigatório
        </div>
      </div>


    </div>

    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="cidade"> Cidade <span style="color: red;">*</span></label>
        <select name="cidade" [(ngModel)]="editarConta.cidade" class="form-control" id="cidade" >
          <option *ngFor="let cidade of cidades ; let i = index" [ngValue]="cidade.ibge_code"  value="{{cidade.ibge_code}}">{{cidade.name}}</option>
        </select>
        <div *ngIf="(  erroform == '1' && erroformcidade == '1' )" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="endereco"> Endereço <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="endereco" id="endereco" [formControl]="endereco" [(ngModel)]="editarConta.endereco"
               placeholder="Rua A, Centro, 782" required>
        <div *ngIf="endereco.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>


    </div>

    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="bairro"> Bairro <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="bairro" id="bairro" [formControl]="bairro" [(ngModel)]="editarConta.bairro"
               placeholder="Ex: Centro" required>
        <div *ngIf="bairro.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="numero"> Numero <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="numero" id="numero" [formControl]="numero" [(ngModel)]="editarConta.numero"
               placeholder="Ex: 485"     required>
        <div *ngIf="numero.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>

      </div>


    </div>
    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="password"> Senha <span style="color: red;">*</span></label>
        <input type="password" class="form-control  form-control-user"   (keypress)="numberOnly($event)"  maxlength="6" name="password" id="password" [formControl]="password" [(ngModel)]="editarConta.password"
                required>
        <div *ngIf="password.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="passwordc"> Confirme a Senha <span style="color: red;">*</span></label>
        <input type="password" class="form-control form-control-user"  (keypress)="numberOnly($event)"  maxlength="6" name="passwordc" id="passwordc" [formControl]="passwordc" [(ngModel)]="editarConta.passwordc"
               required>
        <div *ngIf="passwordc.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
        <div *ngIf="( erroform == '1' &&  erroformsenha == '1' )" style="color: red;">
         As senhas não são iguais.
        </div>
      </div>


    </div>








    <a  class="btn btn-primary" (click)="abrirsenha()"   style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;" >
      Salvar
    </a>
    <a class="btn "  (click)="cancelar()" style="width: 200px; color: #FFF; height: 40px; color: red;">Cancelar</a>


  </div>


</div>


<div *ngIf="modal == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Conta cadastrada com sucesso!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="fechar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Erro ao cadastrar, tente novamente mais tarde!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="fechar()"  class="btn btn-danger">Ok</button>
      </div>
    </div>
  </div>
</div>

<app-password *ngIf="passwordativo == true" (senha)="Password($event)">

</app-password>
