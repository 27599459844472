<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 min-vh-100" id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/usuarios/editar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class="d-none d-sm-inline" style="margin-left: 20px;">Editar Usuario</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/usuarios/permissoes']">

          <i class="fa fa-lock" aria-hidden="true" style="font-size: 18px;"></i>
          <span class="d-none d-sm-inline" style="margin-left: 20px;">Permissões</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>




    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Permissões: {{editarConta.name}}</h2>

    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-5 ">
        <label for="perfil_usuario" >Perfil do Usuário</label>
        <select name="perfil_usuario" id="perfil_usuario" class="form-control" [(ngModel)]="editarConta.perfil_usuario" style="width: 80%; float: left;" >
          <option value="Senior">Administrador Sênior</option>
          <option value="AdmCarteira">Administrador de Carteira</option>
          <option value="AnalistaCarteira">Analista de Carteira</option>
          <option value="AnalistaSenior">Analista de Sênior</option>
        </select>
        <button style="float: left; min-width: 30px;" mat-button (click)="openDialog()"><i class=" fa fa-question-circle" style="font-size: 20px; "></i></button>
      </div>
      <div class="col-md-5 offset-1">
        <div  *ngIf="editarConta.perfil_usuario != 'Senior'">
        <label for="carteira_id">Carteira Gerenciada</label>
        <select name="perfil_usuario" id="carteira_id" class="form-control" [(ngModel)]="editarConta.carteira_id">
          <option *ngFor="let carteira of carteiras ; let i = index" value="{{carteira.id}}">{{carteira.nome}}</option>

        </select>






        </div>






      </div>
    </div>








    <a class="btn btn-primary"  (click)="atualizarConta()" style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Sol. Atualização</a>
  </div>


</div>
