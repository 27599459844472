import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

// @ts-ignore
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-faturas',
  templateUrl: './faturas.component.html',
  styleUrls: ['./faturas.component.css']
})
export class FaturasComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;
  public filtro = 'tudo';
  pesquisa = {texto: ''};
  usuarios: any = [];
  url: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  modalpagar = false;
  modalerro = false;
  modal = false;
  texteerromodal = '';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  idbeneficio: any;

  urlpix: any = {url:'',emv:''};

  pagando: any = false;
  assinatura_id: any = 0;
  assinatura_tipo: any = 'CARTAO';
  async ngOnInit() {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;

    this.assinatura_id = localStorage.getItem('assinatura');
    this.assinatura_tipo = localStorage.getItem('assinaturatipo');


    this.getAll().subscribe(users => {
      this.usuarios = users;
    });
  }

  // tslint:disable-next-line:typedef
  getAll(){
    return this.http.get(`${environment.api}/appdados/assinaturas/faturas/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo + '&assinatura_id=' + this.assinatura_id);
  }

  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/appdados/assinaturas/faturas/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo + '&assinatura_id=' + this.assinatura_id, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;

    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef


  // tslint:disable-next-line:typedef
  cancelar(){
    this.modalpagar = false;
    this.modal = false;
    this.modalerro = false;
    this.pagando = false;
  }
  // tslint:disable-next-line:typedef
  gerarpix(token:any){


    Swal.fire({
      title: 'Você deseja realmente gerar a chave PIX? isso anulará o outro método de  pagamento.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result:any) => {
      if (result.isConfirmed) {




        this.getpix(token).subscribe(users => {
          this.urlpix = users;


          Swal.fire({
            title: 'Pix Qrcode!',
            html: ` <center>
    <p>Segue o Qrcode</p>

    <img src="${this.urlpix.url}" style="max-width: 80%;"/>
    <br>

  <p style="margin-top: 10px;">Segue o código</p>
    <input style="margin-top: 10px;" type="text" value="${this.urlpix.emv}" class="form-control">

    <button id="copyPix" class="swal2-confirm swal2-styled" style="background-color: #3085d6; margin-top: 10px;">
      Copiar código Pix
    </button>

    <p id="copyMessage" style="color: green; display: none; font-weight: bold; margin-top: 10px;">Código copiado!</p>
     </center>
  `,
            showConfirmButton: false
          }).then(() => {
            this.getAll().subscribe(users => {
              this.usuarios = users;
            });

          });

// Adicionando evento ao botão depois do alerta ser renderizado
          setTimeout(() => {
            const copyButton = document.getElementById('copyPix');
            const copyMessage = document.getElementById('copyMessage');

            if (copyButton && copyMessage) {
              copyButton.addEventListener('click', () => {
                navigator.clipboard.writeText(this.urlpix.emv).then(() => {
                  copyMessage.style.display = 'block'; // Exibe a mensagem "Código copiado!"
                }).catch(err => {
                  console.error('Erro ao copiar:', err);
                });
              });
            }
          }, 100);
        } ,(err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
          });
        });



      } else {
        // Ação se o usuário clicar em "Não"
        console.log('Chave PIX não gerada');
      }
    });



  }
  getpix(token:any){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/gerarpix/` + token);
  }

}
