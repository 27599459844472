


<div class="col-md-6 offset-md-4" *ngIf="user.google2fa_secret" style="margin-top: 80px;">

  <h2>Para ativar a autenticação de 2 fatores, leia o codigo abaixo com o app <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR">Google authenticator</a> </h2>
<div style="width: 100%; text-align: center;">
  <p style="margin-top: 50px;" [innerHtml]="svg"></p>
</div>


</div>

<div class="col-md-6 offset-md-4" *ngIf="!user.google2fa_secret" style="margin-top: 80px;">

  <h1>Para ativar a autenticação de 2 fatores, clique em ativar </h1>
  <div style="width: 100%; text-align: center;">
    <a class="btn btn-primary" (click)="ativar()"
       style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Ativar</a>
  </div>


</div>
