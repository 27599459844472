<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por Nome, email, telefone, cidade...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('pendente')" type="button" [ngClass]="{'btn-selecinado': filtro === 'pendente'}" class="btn btn-light2 " style="margin-left: 10px;">Pendentes</button>
    <button (click)="fitrar('enviado')" type="button" [ngClass]="{'btn-selecinado': filtro === 'enviado'}" class="btn btn-light2" style="margin-left: 10px;">Enviados</button>

    <button (click)="enviartodos()" *ngIf="select.length > 1 " type="button"  class="btn btn-light2 float-right btn_bluedark" style=""> Enviar convites </button>

  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >
      <td>

        <input type="checkbox" class="form-check-input" id="Sim" name="prazoTermino" value="Sim" (change)="selecionartudo( $event )" >

      </td>
      <td  *ngIf="order == 'pedir_convites.id' && order_tipo == 'asc' || order != 'pedir_convites.id'">ID <span  (click)="ordenar('pedir_convites.id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'pedir_convites.id' && order_tipo == 'desc'">ID <span  (click)="ordenar('pedir_convites.id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'pedir_convites.name' && order_tipo == 'asc' || order != 'pedir_convites.name'">NOME <span (click)="ordenar('pedir_convites.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'pedir_convites.name' && order_tipo == 'desc'">NOME <span (click)="ordenar('pedir_convites.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td >TELEFONE</td>
      <td >EMAIL</td>

      <td  *ngIf="order == 'cities.name' && order_tipo == 'asc' || order != 'cities.name'" >CIDADE <span (click)="ordenar('cities.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'cities.name' && order_tipo == 'desc'" >CIDADE <span (click)="ordenar('cities.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td>
        <!-- Example split danger button -->


        <input type="checkbox" class="form-check-input" style="margin-top: 10px;" [(ngModel)]="usuario.selected"  name="{{ usuario.id }}" id="{{usuario.id}}" (change)="onChange(usuario.id, $event)">


      </td>
      <td >{{usuario.id}}</td>
      <td>
      <span>{{usuario.name}}</span>

      </td>
      <td>{{usuario.celular }}</td>
      <td>{{usuario.email }}</td>
      <td>{{usuario.cidade }}</td>

      <td>
        <button *ngIf="usuario.status == 'enviado'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Enviado </span></button>
        <button *ngIf="usuario.status == 'pendente'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red;"></div> <span style="margin-left: 10px; font-size: 12px;">Pendente</span></button>
      </td>
      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a (click)="enviar(usuario.id)" class="dropdown-item" >Enviar</a>
            <a (click)="deletarmodal(usuario.id)" class="dropdown-item" >Deletar</a>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>
      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(1)" >1</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page-4  > 1" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>


      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li *ngIf="usuarios.current_page+5 >= i && usuarios.current_page-5 <= i" class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link"  >...</a>
      </li>

      <li class="page-item" *ngIf="usuarios.current_page + 6 < usuarios.last_page" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.last_page)" >{{usuarios.last_page}}</a>
      </li>



      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>


  <div *ngIf="modal.erro == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> Você têm certeza que deseja deletar?</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-primary">Cancelar</button>
          <button type="button" (click)="deletar()"  class="btn btn-danger">Deletar</button>
        </div>
      </div>
    </div>
  </div>



</div>
