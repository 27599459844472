<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 " id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/usuarios/criar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Usuario</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/usuarios/criarpermissoes']">

          <i class="fa fa-lock" aria-hidden="true" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Permissões</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>




    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Editar Conta</h2>
    <div class="text-center">
      <div class="card-body" style="position: relative;">

        <label class="hoverable" for="fileInput">
          <img src="{{editarConta.avatar}}" class="rounded-circle " style="width: 120px; height: 120px; border: 2px solid #4AD9A4;" alt="Card image">
          <div class="hover-text" style="margin-left: 10px;"> <i class="fa fa-edit" style="font-size: 35px; color: #FFF;  "></i></div>
          <div class="background"></div>
        </label>


        <div style="background-color: #4AD9A4; padding: 10px;
                  border-radius: 20px; width: 40px; position: absolute; margin-left: 50%; top: 60%; " (change)="onSelectFile( $event )">
          <i class="fa fa-edit" style="font-size: 18px; color: #FFF;  "></i>
          <input id="fileInput" type='file' >
        </div>

      </div>


    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="name">Nome</label>
        <input type="text" class="form-control form-control-user" name="name" id="name" [(ngModel)]="editarConta.name"
               placeholder="Nome">
      </div>
      <div class="col-md-6">
        <label for="aniversario">Aniversário</label>
        <input type="text" class="form-control form-control-user" name="dp1" id="aniversario"    [(ngModel)]="editarConta.aniversario" mask="00/00/0000"
               placeholder="Aniversário"   required="required"  >







      </div>
    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input type="email" class="form-control form-control-user" id="email"  name="email" [(ngModel)]="editarConta.email"
               placeholder="Email ">
      </div>
      <div class="col-md-6">
        <label for="sexo">Sexo</label>
        <select name="sexo"  class="form-control" [(ngModel)]="editarConta.sexo" id="sexo">
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <span style="color: red;"  *ngIf="errosenha == 0">As senhas não são iguais <br></span>
        <label for="senha">Senha</label>
        <input type="password" class="form-control form-control-user" id="senha"  name="email" [(ngModel)]="editarConta.password"
               placeholder="****">
      </div>
      <div class="col-md-6">
        <span style="color: red;"  *ngIf="errosenha == 0">As senhas não são iguais <br></span>
        <label for="senha1">Confimar Senha</label>
        <input type="password" class="form-control form-control-user" id="senha1"  name="email" [(ngModel)]="confimarsenha"
               placeholder="**** ">
      </div>

    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.telefone" id="telefone" mask="(00) 00000-0000"  name="telefone"
               placeholder="Telefone ">
      </div>

    </div>





    <a class="btn btn-primary"  (click)="atualizarConta()" style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Atualizar</a>
  </div>


</div>
