import { Component, OnInit } from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.css']
})
export class PermissoesComponent implements OnInit {



  model: NgbDateStruct | any;
  todosperfil: any = false;
  todoscarteira: any = false;

  usuarios: any = '';
  carteiras: any = [];
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', aniversario: '', email: '', avatar: '', telefone: '', nacionalidade: '', sexo: '', cep: '', cpf: '', estado: '1', cidade: '', perfil_usuario: '', carteira_id: '' };
  cidades: any;
  erro: any = 0;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  permissoesperfil: any = {usuario: false, relatorio: false, };
  permissoescarteira: any = {editar: false, desativar: false, };
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient,  private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.url = environment.url;
    // @ts-ignore
    this.editarConta = JSON.parse(  localStorage.getItem('editar_conta_dados')) ;
    this.editarConta.avatar =  this.url +  this.editarConta.avatar;
    console.log(this.editarConta);

    // tslint:disable-next-line:triple-equals
    if (this.editarConta.avatar == this.url){

      this.editarConta.avatar = this.url + 'img/avatar/avatar.jpg';
    }

    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals


    });


    this.getestados().subscribe(estados => {
      this.dado_estados = estados;
      // tslint:disable-next-line:triple-equals


    });
    this.getAll().subscribe(users => {
      this.carteiras = users;
      console.log(this.carteiras);
      // tslint:disable-next-line:triple-equals
      if (this.carteiras.status){
        this.router.navigate(['login']);
      }

    });
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }


  // tslint:disable-next-line:typedef
  getAll() {
    return this.http.get(`${environment.apiCarteiras}/getall2?` + 'order=id&order_tipo=asc');
  }

  // tslint:disable-next-line:typedef
  openDialog() {
    this.dialog.open(DialogContentExampleDialog);

  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.editarConta.avatar = myReader.result;
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }
  // // tslint:disable-next-line:typedef
  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //
  //     reader.onload = (ev: any) => {
  //       this.editarConta.avatar = ev.target.result;
  //     };
  //    // reader.readAsDataURL(event.target.files[0]);

  //   }
  // }

  // tslint:disable-next-line:typedef
  getcidade(){
    return this.http.get(`${environment.api}/cidades/` + this.editarConta.estado );
  }

  // tslint:disable-next-line:typedef
  postavatar(){
    return this.http.post(`${environment.apiUsuarios}/avatar` , this.editarConta );
  }
  // tslint:disable-next-line:typedef
  getuser(){
    return this.http.get(`${environment.apiUsuarios}/editar/` + this.editarConta.estado );
  }
  // tslint:disable-next-line:typedef
  getestados(){
    return this.http.get(`${environment.api}/estados` );
  }
  // tslint:disable-next-line:typedef
  onAddCidade(){
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }

  // tslint:disable-next-line:typedef
  atualizarConta(){

    this.enviarDadosConta().subscribe(dadosconta => {
      console.log(dadosconta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
        this.erro = 1;
      }else{
        this.erro = 0;
        this.router.navigate(['usuarios']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiUsuarios}/updateper/` + this.editarConta.id, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  checkUncheckAll() {
    if ( this.todosperfil === true){
      this.permissoesperfil.relatorio = true;
      this.permissoesperfil.usuario = true;
    }else{
      this.permissoesperfil.relatorio = false;
      this.permissoesperfil.usuario = false;
    }

  }
  // tslint:disable-next-line:typedef
  checkUncheckAllCarteira() {
    if ( this.todoscarteira === true){
      this.permissoescarteira.editar = true;
      this.permissoescarteira.desativar = true;
    }else{
      this.permissoescarteira.editar = false;
      this.permissoescarteira.desativar = false;
    }

  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogContentExampleDialog {

}
