import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-documentos-cadastro',
  templateUrl: './documentos-cadastro.component.html',
  styleUrls: ['./documentos-cadastro.component.css']
})
export class DocumentosCadastroComponent implements OnInit {


  usuarios: any = '';
  menu: any = 'vd';
  tipo: any = 'inativo';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', aniversario: '', email: '', avatar: '', telefone: '',
    nacionalidade: '', sexo: '', cep: '', cpf: '', estado: '1', cidade: '', limiteSaque: '', limiteTrasacao: '', limitePagamento: ''};
  cidades: any;
  erro: any = 0;
  pergunta: any = '1';
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  documentos: any = {docFrente: '', docVerso: '', selfie: ''} ;
  private person: any;
  modal = {erro: false, msg: '' };
  modalerro = {erro: false, msg: '' };
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.url = environment.url;
    // @ts-ignore
    this.editarConta = JSON.parse( localStorage.getItem('editar_conta_dados')) ;
    this.getdoc().subscribe(dadosimg => {
      console.log(dadosimg);
      this.documentos = dadosimg;
    });
  }


  // tslint:disable-next-line:typedef
  atualizarConta(tipo: string){
    console.log(tipo);
    this.tipo = tipo;

  }
  // tslint:disable-next-line:typedef
  mudar(tipo: any){
    console.log(this.pergunta);
    this.mudarstatus(tipo, this.pergunta).subscribe(dadosconta => {
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 1){
        this.erro = 0;
        this.tipo = 'inativo';
        this.modal.erro = true;
        this.modal.msg = 'Status alterado com sucesso.';
      }else{
        this.erro = 1;
        this.tipo = 'inativo';
        this.modalerro.erro = true;
        this.modalerro.msg = 'Erro ao  alterar, tente novamente mais tarde.';
      }
      // tslint:disable-next-line:triple-equals
    });
  }

  // tslint:disable-next-line:typedef
  cancelar(){
    this.modal.erro = false;
    this.modalerro.erro = false;
    this.router.navigate(['cadastros']);
  }
  // tslint:disable-next-line:typedef
  getdoc(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/get/documentos/` + this.editarConta.id + '/' + this.editarConta.status,  this.editarConta);

  }
  // tslint:disable-next-line:typedef
  mudarstatus(tipo: any, perguta: any){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/cadastros/status/documentos/` + this.editarConta.id + '/' + tipo + '/' + perguta, this.editarConta);

  }
  // tslint:disable-next-line:typedef
  mudarmenu(tipo: any){
    this.menu = tipo;
  }

}
