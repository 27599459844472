<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/editar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Conta</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/documentos']">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.25 17.4168H4.74999C3.87554 17.4168 3.16666 16.7079 3.16666 15.8335V3.16683C3.16666 2.29238 3.87554 1.5835 4.74999 1.5835H10.2917C10.3003 1.58387 10.3088 1.58547 10.317 1.58825C10.3247 1.59063 10.3327 1.59223 10.3407 1.593C10.4106 1.59747 10.4795 1.61104 10.5458 1.63337L10.5679 1.6405C10.5859 1.64658 10.6034 1.65399 10.6202 1.66266C10.7064 1.701 10.785 1.75464 10.8522 1.821L15.6022 6.571C15.6685 6.63812 15.7222 6.71671 15.7605 6.80295C15.7684 6.82037 15.7739 6.83858 15.7803 6.85679L15.7874 6.87737C15.8095 6.94339 15.8226 7.0121 15.8262 7.08162C15.8274 7.08889 15.8293 7.09604 15.8317 7.103C15.8331 7.1103 15.8337 7.11774 15.8334 7.12516V15.8335C15.8334 16.7079 15.1244 17.4168 14.25 17.4168ZM4.74999 3.16683V15.8335H14.25V7.91683H10.2917C9.85443 7.91683 9.49999 7.56239 9.49999 7.12516V3.16683H4.74999ZM11.0833 4.28625V6.3335H13.1306L11.0833 4.28625Z"
              fill="#A7A9C0"/>
          </svg>

          <span class=" d-sm-inline" style="margin-left: 20px;">Documentos </span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/limite']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Limites</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/saldobeneficios']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Saldo beneficios</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>

    </ul>
  </div>


  <div *ngIf="editarConta.contaPfPj == 'PF'" class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Editar Conta</h2>
    <div class="text-center">
      <div class="card-body" style="position: relative;">


        <img src="{{avatar}}" class="rounded-circle " style="width: 120px; height: 120px; border: 2px solid #4AD9A4;"
             alt="Card image">


      </div>


    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="name">Nome</label>
        <input type="text" class="form-control form-control-user" name="name" id="name" [(ngModel)]="editarConta.namecorreto"
               placeholder="Nome">
      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="nomepesonalizado">Nome Personalizado</label>
        <input type="text" class="form-control form-control-user" name="name" id="nomepesonalizado" [(ngModel)]="editarConta.name"
               placeholder="Nome">
      </div>

    </div>



    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input type="email" class="form-control form-control-user" id="email" name="email"
               [(ngModel)]="editarConta.email"
               placeholder="Email ">
      </div>

      <div class="col-md-6">
        <label for="cpf">CPF</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cpf" name="cpf" id="cpf"
               mask="000.000.000-00" placeholder="CPF">
      </div>

    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="nomeMae">Nome da mãe</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.nomeMae" name="nomeMae" id="nomeMae"
               placeholder="Nome da mãe">
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-6">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.telefone" id="telefone"
               mask="(00) 0 0000-0000" name="telefone"
               placeholder="Telefone ">
      </div>

      <div class="col-md-6">
        <label for="sexo">Sexo</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.sexo" id="sexo">
          <option value="m">Masculino</option>
          <option value="f">Feminino</option>
          <option value="p">Prefiro não dizer</option>
        </select>
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label for="sexo">Tipo de Usuario</label>
        <select name="tipo" class="form-control" [(ngModel)]="editarConta.role" id="tipo">
          <option value="User">Cliente</option>
          <option value="OPublico">Orgão Publico</option>
          <option value="Parceiro">Lojista</option>
          <option *ngIf="user.perfil_usuario == 'Senior'" value="Admin">Admin</option>
        </select>
      </div>


      <div class="col-md-4">
        <div *ngIf="editarConta.role == 'Parceiro'">
          <label for="segmento">Segmento</label>
          <select name="sexo" class="form-control" [(ngModel)]="editarConta.segmento" id="segmentos">
            <option *ngFor=" let segmento of segmentos" value="{{segmento.id}}">{{segmento.name}}</option>
          </select>
        </div>

      </div>

      <div class="col-md-4">
        <label for="BR">Nacionalidade</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="BR" name="nacionalidade" value="BR"
                 [(ngModel)]="editarConta.nacionalidade">
          <label class="form-check-label" for="BR">Brasileiro</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="nacionalidade" class="form-check-input" value="ES"
                 [(ngModel)]="editarConta.nacionalidade" id="estrageiro">

          <label class="form-check-label" for="estrageiro">Estrageiro</label>
        </div>


      </div>

      <div class="col-md-4" style="margin-top: 20px; margin-bottom: 20px;">
        <label for="BR">Ativar Pix dessa conta.</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="sim" name="iscontapix" value="1"
                 [(ngModel)]="editarConta.iscontapix">
          <label class="form-check-label" for="sim">Sim</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="iscontapix" class="form-check-input" value="0"
                 [(ngModel)]="editarConta.iscontapix" id="nao">

          <label class="form-check-label" for="nao">Não</label>
        </div>


      </div>



    </div>

    <div class="form-group row">

      <div class="col-md-6">
        <label for="aniversario">Aniversário</label>
        <input type="text" class="form-control form-control-user" name="dp1" id="aniversario"
               [(ngModel)]="editarConta.nascimento" mask="00/00/0000"
               placeholder="Aniversário">
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="cep">CEP</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cep" name="cep" id="cep"
               placeholder="CEP">
      </div>


    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="estado">ESTADO</label>
        <select name="estados" [(ngModel)]="editarConta.estado" id="estado" class="form-control"
                (ngModelChange)="onAddCidade()">
          <option *ngFor="let estado of dado_estados ; let i = index"
                  value="{{estado.abbreviation}}">{{estado.name}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="cidade">CIDADE</label>
        <select name="cidades" [(ngModel)]="editarConta.cidade" id="cidade" class="form-control">
          <option *ngFor="let cidade of cidades ; let i = index" value="{{cidade.ibge_code}}">{{cidade.name}}</option>
        </select>
      </div>
    </div>

    <a class="btn btn-primary" (click)="atualizarConta()"
       style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Atualizar</a>
  </div>




  <div *ngIf="editarConta.contaPfPj == 'PJ'"  class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Editar Conta</h2>
    <div class="text-center">
      <div class="card-body" style="position: relative;">


        <img src="{{avatar}}" class="rounded-circle " style="width: 120px; height: 120px; border: 2px solid #4AD9A4;"
             alt="Card image">


      </div>


    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="razaoSocial">Razão social</label>
        <input type="text" class="form-control form-control-user" name="name" id="razaoSocial"
               [(ngModel)]="editarConta.razaoSocial"
               placeholder="Razão social">
      </div>
      <div class="col-md-6" >
        <label for="cnpj">CNPJ</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cnpj" name="cnpj" id="cnpj"
               mask="00.000.000/0000-00" placeholder="CNPJ">
      </div>

    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="nomeMae">Nome da mãe</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.nomeMae" name="nomeMae" id="nomeMae"
               placeholder="Nome da mãe">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input type="email" class="form-control form-control-user" id="email" name="email"
               [(ngModel)]="editarConta.email"
               placeholder="Email ">
      </div>
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="nomeFatasia">Nome Fantasia</label>
        <input type="text" class="form-control form-control-user" name="name" id="nomeFatasia"
               [(ngModel)]="editarConta.nomeFatasia"
               placeholder="Nome Fantasia">
      </div>

    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="namelegal">Representante Legal</label>
        <input type="text" class="form-control form-control-user" name="name" id="namelegal"
               [(ngModel)]="editarConta.name"
               placeholder="Representante Legal">
      </div>
      <div class="col-md-6" >
        <label for="cnpj">CPF do representante</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cpf_pj" name="cpf_pj" id="cpf_pj"
               mask="00.000.000/0000-00" placeholder="CPF do representante">
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Sexo</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.sexo" id="sexo">
          <option value="m">Masculino</option>
          <option value="f">Feminino</option>
          <option value="p">Prefiro não dizer</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="BR">Nacionalidade</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="BR" name="nacionalidade" value="BR"
                 [(ngModel)]="editarConta.nacionalidade">
          <label class="form-check-label" for="BR">Brasileiro</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="nacionalidade" class="form-check-input" value="ES"
                 [(ngModel)]="editarConta.nacionalidade" id="estrageiro">

          <label class="form-check-label" for="estrageiro">Estrageiro</label>
        </div>


      </div>

      <div class="col-md-4" style="margin-top: 20px; margin-bottom: 20px;">
        <label for="BR">Ativar Pix dessa conta.</label>
        <br>
        <div class="form-check form-check-inline">

          <input type="radio" class="form-check-input" id="sim" name="iscontapix" value="1"
                 [(ngModel)]="editarConta.iscontapix">
          <label class="form-check-label" for="sim">Sim</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" name="iscontapix" class="form-check-input" value="0"
                 [(ngModel)]="editarConta.iscontapix" id="nao">

          <label class="form-check-label" for="nao">Não</label>
        </div>


      </div>



    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="aniversario">Aniversário</label>
        <input type="text" class="form-control form-control-user" name="dp1" id="aniversario"
               [(ngModel)]="editarConta.nascimento" mask="00/00/0000"
               placeholder="Aniversário">
      </div>

      <div class="col-md-6">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.telefone" id="telefone"
               mask="(00) 0 0000-0000" name="telefone"
               placeholder="Telefone ">
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0">
        <label for="cep">CEP</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cep" name="cep" id="cep"
               placeholder="CEP">
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="estado">ESTADO</label>
        <select name="estados" [(ngModel)]="editarConta.estado" id="estado" class="form-control"
                (ngModelChange)="onAddCidade()">
          <option *ngFor="let estado of dado_estados ; let i = index"
                  value="{{estado.abbreviation}}">{{estado.name}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="cidade">CIDADE</label>
        <select name="cidades" [(ngModel)]="editarConta.cidade" id="cidade" class="form-control">
          <option *ngFor="let cidade of cidades ; let i = index" value="{{cidade.ibge_code}}">{{cidade.name}}</option>
        </select>
      </div>
    </div>


    <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0" >
        <label for="endereco"> Endereço <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="endereco" id="endereco"  [(ngModel)]="editarConta.endereco"
               placeholder="Rua A, Centro, 782" required>
      </div>

    </div>

    <div class="form-group row">

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="bairro"> Bairro <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="bairro" id="bairro" [(ngModel)]="editarConta.bairro"
               placeholder="Ex: Centro" required>

      </div>

      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="numero"> Numero <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" name="numero" id="numero"  [(ngModel)]="editarConta.numero"
               placeholder="Ex: 485"     required>
      </div>
    </div>





    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Tipo de Chave</label>
        <select name="tipochave" class="form-control" [(ngModel)]="editarConta.tipochave" id="tipochave">
          <option value="CPF">CPF</option>
          <option value="CNPJ">CNPJ</option>
          <option value="EVP">Chave Aleatoria</option>
          <option value="EMAIL">EMAIL</option>
          <option value="TELEFONE">TELEFONE</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="segmento">Chave</label>
        <input type="text" class="form-control form-control-user"  [(ngModel)]="editarConta.chave" name="chave" id="chave"
               placeholder="Chave">
      </div>
    </div>



    <div class="form-group row">
      <div class="col-md-6">
        <label for="sexo">Tipo de Usuario</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.role" id="role">
          <option value="User">Cliente</option>
          <option value="OPublico">Orgão Publico</option>
          <option value="Parceiro">Lojista</option>
          <option *ngIf="user.perfil_usuario == 'Senior'" value="Admin">Admin</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="segmento">Segmento</label>
        <select name="sexo" class="form-control" [(ngModel)]="editarConta.segmento" id="segmento">
          <option *ngFor=" let segmento of segmentos" value="{{segmento.id}}">{{segmento.name}}</option>

        </select>
      </div>
    </div>

    <a class="btn btn-primary" (click)="atualizarConta()"
       style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Atualizar</a>
  </div>


</div>
