<div class="row" style="background-color: #FFF; margin: 10px!important; height: 80vh;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/beneficiarios']">
          <span class=" d-sm-inline" style="margin-left: 20px;"> Beneficiários Vinculados</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/beneficios/vincular']">
          <span class=" d-sm-inline" style="margin-left: 20px;"> Vincular Beneficiários </span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>



    </ul>
  </div>


  <div class="col-md-8" style="padding: 30px;" >


    <h2 style="color: #33343E; font-weight: normal;"> Vincular benefíciário</h2>
    <div class="form-group row" style="margin-top: 50px; margin-bottom: 50px;">
      <div class="col-md-12 mb-3 mb-sm-0" >
        <label for="name">CPF do benefíciario <span style="color: red;">*</span></label>
        <input type="text" class="form-control form-control-user" mask="000.000.000-00" name="name" id="name" [formControl]="cpf" [(ngModel)]="editarConta.cpf"
               placeholder="CPF" required>
        <div *ngIf="cpf.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 50px;">
      <a  class="btn btn-primary" (click)="abrirsenha()"   style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;" >
        Salvar
      </a>
      <a class="btn "  (click)="cancelar()" style="width: 200px; color: #FFF; height: 40px; color: red;">Cancelar</a>
    </div>


  </div>

  <div *ngIf="modal == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> Usuario vinculado com sucesso!</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="fechar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> Usuario não existe, tente novamente mais tarde!</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="fechar()"  class="btn btn-danger">Ok</button>
        </div>
      </div>
    </div>
  </div>
  <app-password *ngIf="passwordativo == true" (senha)="Password($event)"></app-password>

</div>







