import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
@Component({
  selector: 'app-homeadm',
  templateUrl: './homeadm.component.html',
  styleUrls: ['./homeadm.component.css']
})
export class HomeadmComponent implements OnInit {

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [30, 10, 35, 90, 30, 88, 44], label: 'Series B' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // @ts-ignore
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#1BA9EA',
      backgroundColor: 'rgba(27,169,234,0.3)',
    },  {
      borderColor: '#4AD9A4',
      backgroundColor: 'rgba(74,217,164,0.3)',
    },
  ];
  public lineChartLegend = true;
   lineChartType: any = 'line';
  public lineChartPlugins = [];
dados: any = { usuativo: '' , usuarionovo: '', movimetacao: ''};
  constructor(private http: HttpClient, private router: Router) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {

    this.getdados().subscribe(valor => {
      this.dados = valor;
      console.log(this.dados);
    });
  }

  // tslint:disable-next-line:typedef
  getdados(){
    return this.http.get(`${environment.api}/appdados/admin/drashbord`);
  }
}
