<div class="row" style="background-color: #FFF; margin: 10px!important; min-width: 500px; height: 500px;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important;  border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item "  style="width: 100%; border-bottom: 1px solid #E7E8F2;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" >
          <span class=" d-sm-inline" style="margin-left: 20px;">Relação de clientes ativos</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; "></i>
        </a>
      </li>

      <li class="nav-item " (click)="abrirmodal('beneficiarios')" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" >
          <span class=" d-sm-inline" style="margin-left: 20px;"> Relação de Beneficiários</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; "></i>
        </a>
      </li>
      <li class="nav-item " style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" >
          <span class=" d-sm-inline" style="margin-left: 20px;"> Relação de serviços mais vendidos</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; "></i>
        </a>
      </li>

    </ul>
  </div>




</div>



<div *ngIf="modal == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >
       <span style="color: #33343E;font-size: 20px;">Gerar relatório</span>
        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" >


        <div class="form-group row">

          <div class="col-md-6">
            <label for="carteira">Carteira <span style="color: red;">*</span></label>

            <select name="sexo"  required  class="form-control" [(ngModel)]="dadosrelatorio.carteira_id" id="carteira">
              <option selected value="Todos">Todas</option>

              <option *ngFor="let b of cateiras.data ; let i = index"  value="{{b.id}}">{{b.nome}}</option>


            </select>

          </div>
          <div class="col-md-6">
            <label for="beneficio_id">Benefício  <span style="color: red;">*</span></label>

            <select name="sexo"  required  class="form-control" [(ngModel)]="dadosrelatorio.beneficio_id" id="beneficio_id">
              <option selected value="Todos">Todos</option>
              <option *ngFor="let b of bene.data ; let i = index"  value="{{b.id}}">{{b.name}}</option>

            </select>

          </div>



        </div>


        <div class="form-group row">
          <div class="col-md-12">
            <label for="status">Status  <span style="color: red;">*</span></label>

            <select name="sexo"  required  class="form-control" [(ngModel)]="dadosrelatorio.status" id="status">
              <option selected value="Todos">Todos</option>
              <option value="ativo">Ativos</option>
              <option value="bloqueadas">Bloqueados</option>

            </select>

          </div>


        </div>



      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="gerarpdf()"  class="btn " style="background-color: #2A5D67; color: #FFF;">Gerar pdf</button>
      </div>
    </div>
  </div>
</div>


