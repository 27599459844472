<form class="row" #registerForm="ngForm" (ngSubmit)="atualizarConta(registerForm)"  style="background-color: #FFF; margin: 10px!important;" novalidate>


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 " id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">

      <li class="nav-item ativo_editar"  *ngIf="pagina == 'Editar'">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" (click)="mudarpagina('Editar',registerForm)">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Nova Carteira</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item "  *ngIf="pagina != 'Editar'">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" (click)="mudarpagina('Editar',registerForm)">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Nova Carteira</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>

      <li class="nav-item ativo_editar"  *ngIf="pagina == 'Editarp'">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" (click)="mudarpagina('Editarp',registerForm)">

          <i class="fa fa-rocket" aria-hidden="true" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Personalização</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item "  *ngIf="pagina != 'Editarp'">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" (click)="mudarpagina('Editarp',registerForm)">

          <i class="fa fa-rocket" aria-hidden="true" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Personalização</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>




    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;" id="editar">


    <h2 style="color: #33343E; font-weight: normal;"> Nova Carteira</h2>
    <div class="text-center">
      <div class="card-body" style="position: relative;">

        <label class="hoverable" for="fileInput">
          <img src="{{editarConta.avatar}}" class="rounded-circle " style="width: 120px; height: 120px; border: 2px solid #4AD9A4;" alt="Card image">
          <div class="hover-text" style="margin-left: 10px;"> <i class="fa fa-edit" style="font-size: 35px; color: #FFF;  "></i></div>
          <div class="background"></div>
        </label>


        <div style="background-color: #4AD9A4; padding: 10px;
                  border-radius: 20px; width: 40px; position: absolute; margin-left: 50%; top: 60%; " (change)="onSelectFile( $event )">
          <i class="fa fa-edit" style="font-size: 18px; color: #FFF;  "></i>
          <input id="fileInput" type='file' >
        </div>

      </div>


    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="nome">NOME</label>
        <input type="text" class="form-control form-control-user" name="nome" id="nome" [(ngModel)]="editarConta.nome"
               placeholder="Nome" required="required" >

      </div>
      <div class="col-md-6">
        <label for="cnpj">CNPJ</label>
        <input type="text" class="form-control form-control-user" name="dp1"  id="cnpj"   [(ngModel)]="editarConta.cnpj"
               placeholder="CNPJ"   required="required"  mask="CPF_CNPJ">







      </div>
    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">EMAIL</label>
        <input type="email" class="form-control form-control-user"  id="email" name="email" [(ngModel)]="editarConta.email"
               placeholder="Email " email required="required"  >
      </div>
      <div class="col-md-6">
        <label for="telefone">TELEFONE</label>
        <input type="text" class="form-control form-control-user" id="telefone" [(ngModel)]="editarConta.telefone"  mask="(00) 00000-0000"  name="telefone"
               placeholder="Telefone " required="required"  >
      </div>
    </div>



    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="nome">CEP</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.cep" mask="00-000-000" name="cep" id="cep"
               placeholder="CEP" required="required"  >
      </div>
      <div class="col-md-6">
        <label for="endereco">ENDEREÇO</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.endereco" name="endereco" id="endereco"
               placeholder="ENDEREÇO" required="required"  >
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="nome">ESTADO</label>
        <select name="estado_id" [(ngModel)]="editarConta.estado_id" class="form-control"  (ngModelChange)="onAddCidade()" required="required">
          <option value="">Selecione um Estado</option>
          <option *ngFor="let estado of dado_estados ; let i = index" [ngValue]="estado.id" value="{{estado.id}}">{{estado.name}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="cidade">CIDADE</label>
        <select name="cidade_id" [(ngModel)]="editarConta.cidade_id" class="form-control" id="cidade" >
          <option *ngFor="let cidade of cidades ; let i = index" [ngValue]="cidade.id"  value="{{cidade.id}}">{{cidade.name}}</option>
        </select>
      </div>
    </div>

    <a class="btn btn-primary"  (click)="mudarpagina('Editarp',registerForm)" style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Salvar</a>
  </div>

  <div class="col-md-9" id="editarp" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal;"> Personalizar Carteira</h2>
    <div class="text-center">
      <div class="card-body" style="position: relative;">

        <label class="hoverable" for="fileInput">
          <img src="{{editarConta.avatar}}" class="rounded-circle " style="width: 120px; height: 120px; border: 2px solid #4AD9A4;" alt="Card image">
          <div class="hover-text" style="margin-left: 10px;"> <i class="fa fa-edit" style="font-size: 35px; color: #FFF;  "></i></div>
          <div class="background"></div>
        </label>


        <div style="background-color: #4AD9A4; padding: 10px;
                  border-radius: 20px; width: 40px; position: absolute; margin-left: 50%; top: 60%; " (change)="onSelectFile( $event )">
          <i class="fa fa-edit" style="font-size: 18px; color: #FFF;  "></i>
          <input id="fileInput2" type='file' >
        </div>

      </div>


    </div>
    <div class="form-group row">
      <div class="col-md-12 mb-3 mb-sm-0">
        <label for="site">WEBSITE</label>
        <input type="text" class="form-control form-control-user" name="site" id="site" [(ngModel)]="editarConta.site"
               placeholder="WEBSITE URL" >
      </div>

    </div>
    <div *ngIf="erro == 1" class="alert alert-danger" role="alert">
      O email já existe no banco de dados
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="corPrimaria">COR PRIMÁRIA</label>
        <input type="email" class="form-control form-control-user demo"  [(colorPicker)]="editarConta.corPrimaria" [style.background]="editarConta.corPrimaria"  id="corPrimaria" name="corPrimaria" [(ngModel)]="editarConta.corPrimaria"
               placeholder="COR PRIMÁRIA"  >
      </div>
      <div class="col-md-6">
        <label for="corSecundaria">COR SECUNDÁRIA</label>
        <input type="text" class="form-control form-control-user demo" id="corSecundaria" [(ngModel)]="editarConta.corSecundaria" [(colorPicker)]="editarConta.corSecundaria" [style.background]="editarConta.corSecundaria"   name="corSecundaria"
               placeholder="COR SECUNDÁRIA "  >
      </div>
    </div>



    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="versaoAppAndroid">Versão do Aplicativo Android</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.versaoAppAndroid" name="versaoAppAndroid" id="versaoAppAndroid"
               placeholder="Versão do Aplicativo Android" >
      </div>
      <div class="col-md-6">
        <label for="versaoAppIos">Versão do Aplicativo IOS</label>
        <input type="text" class="form-control form-control-user" [(ngModel)]="editarConta.versaoAppIos" name="versaoAppIos" id="versaoAppIos"
               placeholder="Versão do Aplicativo IOS" >
      </div>
    </div>



    <button class="btn btn-primary"   [disabled]="!registerForm.form.valid" style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;">Salvar</button>
  </div>

</form>
