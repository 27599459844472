<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-3 collapse show d-md-flex bg-light pt-2 pl-0 " id="sidebar"
       style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/editar']">
          <i class="fa fa-edit" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Conta</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/documentos']">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 17.4168H4.74999C3.87554 17.4168 3.16666 16.7079 3.16666 15.8335V3.16683C3.16666 2.29238 3.87554 1.5835 4.74999 1.5835H10.2917C10.3003 1.58387 10.3088 1.58547 10.317 1.58825C10.3247 1.59063 10.3327 1.59223 10.3407 1.593C10.4106 1.59747 10.4795 1.61104 10.5458 1.63337L10.5679 1.6405C10.5859 1.64658 10.6034 1.65399 10.6202 1.66266C10.7064 1.701 10.785 1.75464 10.8522 1.821L15.6022 6.571C15.6685 6.63812 15.7222 6.71671 15.7605 6.80295C15.7684 6.82037 15.7739 6.83858 15.7803 6.85679L15.7874 6.87737C15.8095 6.94339 15.8226 7.0121 15.8262 7.08162C15.8274 7.08889 15.8293 7.09604 15.8317 7.103C15.8331 7.1103 15.8337 7.11774 15.8334 7.12516V15.8335C15.8334 16.7079 15.1244 17.4168 14.25 17.4168ZM4.74999 3.16683V15.8335H14.25V7.91683H10.2917C9.85443 7.91683 9.49999 7.56239 9.49999 7.12516V3.16683H4.74999ZM11.0833 4.28625V6.3335H13.1306L11.0833 4.28625Z" fill="#A7A9C0"/>
          </svg>

          <span class=" d-sm-inline" style="margin-left: 20px;">Documentos </span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/limite']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Limites</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>
      <li class="nav-item " [routerLinkActive]="['ativo_editar']">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/clientes/saldobeneficios']">
          <i class="fa fa-usd" style="font-size: 18px;"></i>
          <span class=" d-sm-inline" style="margin-left: 20px;">Saldo beneficios</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; margin-left: 30px;display: none;"></i>
        </a>
      </li>

    </ul>
  </div>


  <div class="col-md-9" style="padding: 30px;">


    <h2 style="color: #33343E; font-weight: normal; margin-bottom: 60px;"> Saldo dos Beneficios</h2>

    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;">Saldo Geral</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <div class="input-group">
          <div class="input-group-prepend" style="background-color: #FFF!important;">
            <div class="input-group-text" style="background-color: #FFF!important; border: solid 2px #4AD9A4; height: 50px; border-right: none;">R$:</div>
          </div>
          <input type="text" class="form-control form-control-user" disabled value="{{editarConta.saldogeral}}"  currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" style="border: solid 2px #4AD9A4; height: 50px; border-left: none;" name="limiteSaque" id="uuuu"
                 placeholder="700,00">

        </div>
      </div>
    </div>


    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;">Saldo Alimentação</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <div class="input-group">
          <div class="input-group-prepend" style="background-color: #FFF!important;">
            <div class="input-group-text" style="background-color: #FFF!important; border: solid 2px #4AD9A4; height: 50px; border-right: none;">R$:</div>
          </div>
          <input type="text" class="form-control form-control-user" disabled value="{{editarConta.saldovalealimetacao}}"  currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" style="border: solid 2px #4AD9A4; height: 50px; border-left: none;" name="limiteSaque" id="limiteSaque"
                 placeholder="700,00">

        </div>
      </div>
    </div>


    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;">Saldo Refeição</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <div class="input-group">
          <div class="input-group-prepend" style="background-color: #FFF!important;">
            <div class="input-group-text" style="background-color: #FFF!important; border: solid 2px #4AD9A4; height: 50px; border-right: none;">R$:</div>
          </div>
          <input type="text" class="form-control form-control-user" disabled value="{{editarConta.saldorefeicao}}"  currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' } "style="border: solid 2px #4AD9A4; height: 50px; border-left: none;" name="limiteSaque" id="limiteSaque"
                 placeholder="700,00">

        </div>
      </div>
    </div>



    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;"> Saldo Saúde</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <div class="input-group">
          <div class="input-group-prepend" style="background-color: #FFF!important;">
            <div class="input-group-text" style="background-color: #FFF!important; border: solid 2px #4AD9A4; height: 50px; border-right: none;">R$:</div>
          </div>
          <input type="text" class="form-control form-control-user" disabled value="{{editarConta.saldosaude}}"  currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" style="border: solid 2px #4AD9A4; height: 50px; border-left: none;" name="limiteSaque" id="limiteSaque"
                 placeholder="700,00">

        </div>
      </div>
    </div>


    <div class="form-group row" style="margin-top: 30px;">
      <div class="col-md-12 ">
        <h1 style="font-weight: bold; font-size: 16px; margin-bottom: 2px;">Saldo Social</h1>
        <div class="progress progress-sm mr-2" style="height: 7px; margin-bottom: 20px; ">
          <div class="progress-bar bg-success" role="progressbar"  style="width: 20%; background-color: #4AD9A4!important;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-sm-0">
        <div class="input-group">
          <div class="input-group-prepend" style="background-color: #FFF!important;">
            <div class="input-group-text" style="background-color: #FFF!important; border: solid 2px #4AD9A4; height: 50px; border-right: none;">R$:</div>
          </div>
          <input type="text" class="form-control form-control-user" disabled value="{{editarConta.saldosocial}}"  currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" style="border: solid 2px #4AD9A4; height: 50px; border-left: none;" name="limiteSaque" id="limiteSaque"
                 placeholder="700,00">

        </div>
      </div>
    </div>





  </div>


</div>
