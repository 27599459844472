import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-criarpermissao',
  templateUrl: './criarpermissao.component.html',
  styleUrls: ['./criarpermissao.component.css']
})
export class CriarpermissaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
