import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-trasacoes',
  templateUrl: './trasacoes.component.html',
  styleUrls: ['./trasacoes.component.css']
})
export class TrasacoesComponent implements OnInit {


  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;
  tipotrans: any  = [
    '',
    {tipo: '', descri: '', icon: ''}, // 1


  ];


  inicio: number | undefined;
  limite: number | undefined;

  editarConta = { email: '' , id: '', password: '' };

  public filtro = 'tudo';
  pesquisa = {texto: '', carteira_id: '1'};
  usuarios: any = [];
  carteiras: any = [];
  dados: any = {trasacoes: '', carteiras: ''};
  usu: any = {novasenha: '', password: ''};
  tipo: any = 'email';
  url: any;
  tiposenha: any = '';
  confimarsenha: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'desc';
  modal = {erro: false, msg: '' };
  modaldetalhes = false;
  detalhes: any = {id: '', };
  modalerro = {erro: false, msg: '' };
  passwordativo = false;
  errocamposenha = {erro: false, tipo: ''};
  erroform: any =  '0';
  // tslint:disable-next-line:typedef
 async ngOnInit() {
    // @ts-ignore
    this.getdatipotrasacao().subscribe( usuario => {
      this.tipotrans = usuario;
      // tslint:disable-next-line:triple-equals
    });
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    if ( sessionStorage.getItem('filtro')){
      // @ts-ignore
      this.filtro = sessionStorage.getItem('filtro');
    }
    if ( sessionStorage.getItem('pesquisar')){
      // @ts-ignore
      this.pesquisa.texto = sessionStorage.getItem('pesquisar');
    }
    if ( sessionStorage.getItem('ordenar')){
      // @ts-ignore
      this.order = sessionStorage.getItem('ordenar');
    }
    if ( sessionStorage.getItem('order_tipo')){
      // @ts-ignore
      this.order_tipo = sessionStorage.getItem('order_tipo');
    }
    if ( sessionStorage.getItem('paginaatual')){
      // @ts-ignore
      this.paginaAtual = sessionStorage.getItem('paginaatual');
    }


    this.getAll().subscribe(async users => {
      this.dados = users;
      this.usuarios = this.dados.trasacoes;
      this.carteiras = this.dados.carteiras;
      // tslint:disable-next-line:triple-equals
      console.log(this.dados );

    });
  }
  // tslint:disable-next-line:typedef
  getAll(){
    // return this.http.get(`${environment.apiContas}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/transacoes/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);

  }
  // tslint:disable-next-line:typedef
 async mudarcarteira(id: any){
    this.paginaAtual = 1;
    this.pesquisa.carteira_id = id.value;
    console.log(id);
    this.getfiltro().subscribe(async users => {
     this.dados = users;
     this.usuarios = this.dados.trasacoes;
     // tslint:disable-next-line:triple-equals
     console.log(this.dados );

   });
  }

  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/transacoes/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
// @ts-ignore
    sessionStorage.setItem('paginaatual', this.paginaAtual);
    this.getfiltro().subscribe(users => {
      this.dados = users;
      this.usuarios = this.dados.trasacoes;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;
    sessionStorage.setItem('filtro', this.filtro);
    sessionStorage.setItem('paginaatual', '1' );
    this.paginaAtual = 1;
    this.getfiltro().subscribe(users => {
      this.dados = users;
      this.usuarios = this.dados.trasacoes;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    sessionStorage.setItem('ordenar', this.order);
    this.paginaAtual = 1;
    sessionStorage.setItem('paginaatual', '1' );
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    sessionStorage.setItem('order_tipo', this.order_tipo);
    this.getfiltro().subscribe(users => {
      this.dados = users;
      this.usuarios = this.dados.trasacoes;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    sessionStorage.setItem('pesquisar', this.pesquisa.texto);
    sessionStorage.setItem('paginaatual', '1' );
    this.paginaAtual = 1;
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.dados = users;
      this.usuarios = this.dados.trasacoes;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef



  numberOnly( event: any ): boolean {
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // tslint:disable-next-line:typedef
  cancelar(){
    this.modaldetalhes = false;
  }
  // tslint:disable-next-line:typedef
  abrirmodaldetalhes(detalhes: any){
    this.modaldetalhes = true;
    this.detalhes = detalhes;
    console.log(detalhes);
  }

  // tslint:disable-next-line:typedef
  getdatipotrasacao(){
    return this.http.get(`${environment.api}/app/gettipostransacao`);
  }

}
