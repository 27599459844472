import { Component, OnInit, Output, EventEmitter, AfterViewInit, Directive, HostListener, ElementRef, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})

export class PasswordComponent implements OnInit {
  @Output() senha = new EventEmitter();
  // @ts-ignore
  @ViewChild('yourInput', {static: false}) yourInput: ElementRef;
  register: any = {password: ''};
  erroname = { erro: false  };
  tamanho = 0;
  constructor(private el: ElementRef, private http: HttpClient) {
  }
  @HostListener('document:keypress', ['$event'])
  // tslint:disable-next-line:typedef
  handleKeyboardEvent(event: KeyboardEvent) {
   console.log(event.key);
   this.yourInput.nativeElement.focus();
  }
  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  irCampo(){
    this.yourInput.nativeElement.focus();
  }
  // tslint:disable-next-line:typedef
  feedback(tipo: any) {
    this.erroname.erro = false;
    // tslint:disable-next-line:triple-equals
    if(tipo == 'confimar'){
      this.getsenha().subscribe(dadossenha => {
        // console.log(dadosconta);
        // tslint:disable-next-line:triple-equals
        if ( dadossenha == '1'){
          this.senha.emit({ password: this.register.password , tipoa: tipo});
        }else{
          this.erroname.erro = true;
          // this.router.navigate(['beneficios']);
        }
        // tslint:disable-next-line:triple-equals
      });
    }else{
      this.senha.emit({ password: this.register.password , tipoa: tipo});
    }
  }
  // tslint:disable-next-line:typedef
   mudar(event: any, valor: any) {
    this.tamanho = valor.value.length;
    this.erroname.erro = false;
    this.register.password = valor.value;

    if (valor.value.length === 6) {
      }
    }
  // tslint:disable-next-line:typedef
  getsenha(){
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/app/verificar/password`, this.register);
  }
}
