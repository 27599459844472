import { Component, ViewChild, ElementRef, OnInit, Input, EventEmitter} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { map, filter, scan } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { DatePipe } from '@angular/common'; // import do datePipe
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ErrorStateMatcher} from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-criar-contapj',
  templateUrl: './criar-contapj.component.html',
  styleUrls: ['./criar-contapj.component.css'],
  providers: [DatePipe]
})
export class CriarContapjComponent implements OnInit {
  model: NgbDateStruct | any;
  errosgeral: any = {
    cpf: [],
    cnpj: [],
    email: [],
    password: [],
    razaoSocial: [],
    nomeFatasia: [],
    endereco: [],
    numero: [],
    bairro: [],
    cidade: [],
    estado: [],
    sexo: [],
    cep: [],
    nascimento: [],
    telefone: [],
    nomeMae: []
  };

  getdadoscep: any = {logradouro: '', bairro: ''};
  errocep: any = false;
  avatar: any = '../../assets/img/avatar.jpg';
  usuarios: any = '';
  erroform: any = '0';
  erroformsenhavazia: any = 0;
  erroformsenha: any = 0;
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {
    name: '', nascimento: 'hhhh', email: '', role: 'User', avatar: '',
    namecorreto: '', telefone: '', nacionalidade: '', sexo: 'm', cep: '', cpf: '', estado: '1'
    , cidade: '', cpf_pj: '', nomeFatasia: '', segmento: 1, nomeMae: '', password: '', passwordc: '', chave: '', tipochave: '',numero:'',bairro:'',endereco:''
  };
  cidades: any;
  segmentos: any;
  erro: any = 0;
  user: any = [];
  // tslint:disable-next-line:variable-name
  dado_estados: any;
  private person: any;

  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private router: Router) {
  }

  ngOnInit(): void {

    this.getsegmentos().subscribe(seg => {
      this.segmentos = seg;
      // tslint:disable-next-line:triple-equals


    });


    this.getestados().subscribe(estados => {
      this.dado_estados = estados;
      // tslint:disable-next-line:triple-equals


    });
    this.editarConta.cidade = this.editarConta.cidade;
  }

  getcidade() {
    return this.http.get(`${environment.api}/app/cidades/` + this.editarConta.estado);
  }

  getestados() {
    return this.http.get(`${environment.api}/estados`);
  }

  getsegmentos() {
    return this.http.get(`${environment.api}/segmentos`);
  }

  atualizarConta() {
    this.erroformsenhavazia = 0;
    this.erroformsenha = 0;
    if (this.editarConta.password.length === 6) {
    if (this.editarConta.password === this.editarConta.passwordc) {

        this.enviarDadosConta().subscribe(dadosconta => {
          console.log(dadosconta);
          // tslint:disable-next-line:triple-equals
          if (dadosconta == 0) {
            this.erro = 1;
          } else {
            this.erro = 0;
            this.router.navigate(['clientes']);
          }
          // tslint:disable-next-line:triple-equals
        });
      } else {
      this.erroformsenha = 1;
      }
    } else {
      this.erroformsenhavazia = 1;

    }


  }

  enviarDadosConta() {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/contas/criarcontapj`, this.editarConta);
  }

  onAddCidade() {
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }
  buscarCep() {
    const cep = this.editarConta.cep.replace(/\D/g, ''); // Remove não números

    if (cep.length === 8) {
      this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`)
        .subscribe(dados => {
          if (!dados.erro) {
            this.editarConta.endereco = dados.logradouro;
            this.editarConta.bairro = dados.bairro;
            this.editarConta.cidade = dados.localidade;
            this.editarConta.estado = dados.uf;


            this.getcidade().subscribe(cidade => {
              this.cidades = cidade;

            });

          } else {
            alert('CEP não encontrado!');
          }
        }, erro => {
          console.error('Erro ao buscar o CEP', erro);
        });
    }
  }
   // @ts-ignore
  getCep (event: any, input: any) {


    console.log(this.editarConta.cep.length);
    console.log(this.editarConta.cep);
    // eslint-disable-next-line eqeqeq
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.cep.length != 8){
      this.errocep = true;
      return false;
    }else{
      this.errocep = false;
    }

    if ( input.value.length === 9 ){
      // @ts-ignore
      this.getcepdados(input.value).subscribe(async dados => {
        this.getdadoscep = dados;
        // tslint:disable-next-line:triple-equals
        this.editarConta.endereco = this.getdadoscep.logradouro;
        if (this.editarConta.endereco.length == null){this.editarConta.endereco = ''; }
        this.editarConta.bairro = this.getdadoscep.bairro;
        if (this.editarConta.bairro.length == null){this.editarConta.bairro = ''; }
        this.editarConta.estado = this.getdadoscep.uf;
        this.getcidade().subscribe(cidade => {
          this.cidades = cidade;
          // tslint:disable-next-line:triple-equals
          console.log(this.cidades);
          this.editarConta.cidade = this.getdadoscep.ibge;
        });
      });
    }

  }

  // tslint:disable-next-line:typedef
  getcepdados(cep: any){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/app/getcep/${cep}`);
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
