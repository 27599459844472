import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-conta-transacao',
  templateUrl: './conta-transacao.component.html',
  styleUrls: ['./conta-transacao.component.css']
})
export class ContaTransacaoComponent implements OnInit {



  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;




  inicio: number | undefined;
  limite: number | undefined;



  public filtro = 'tudo';
  pesquisa = {texto: '', id: ''};
  erro: any = 0;
  pergunta: any = '1';
  usuarios: any = [];
  usu: any = {novasenha: '', password: ''};
  tipo: any = 'email';
  tipostatus: any = 'inativo';
  url: any;
  tiposenha: any = '';
  confimarsenha: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  modal = {erro: false, msg: '' };
  modalmudarsenha = false;
  modalsenhamudar = false;
  modalacao = false;
  modalacaotexto = '';
  modalerro = {erro: false, msg: '' };
  passwordativo = false;
  errocamposenha = {erro: false, tipo: ''};
  erroform: any =  '0';
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', aniversario: '', email: '', avatar: '', telefone: '',
    nacionalidade: '', sexo: '', cep: '', cpf: '', estado: '1', cidade: '', limiteSaque: '', limiteTrasacao: '', limitePagamento: ''};

  tipotrans: any  = [
    '',
    {tipo: '', descri: '', icon: ''}, // 1


  ];
  ngOnInit(): void {

    // @ts-ignore
    this.getdatipotrasacao().subscribe( usuario => {
      this.tipotrans = usuario;
      // tslint:disable-next-line:triple-equals
      console.log(  this.tipotrans );
    });


    this.avatar_padrao = 'img/avatar/avatar.jpg';
// @ts-ignore
    this.editarConta = JSON.parse(  localStorage.getItem('editar_conta_dados'));
    this.pesquisa.id = this.editarConta.id;

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    if ( sessionStorage.getItem('Conta_transa_filtro')){
      // @ts-ignore
      this.filtro = sessionStorage.getItem('Conta_transa_filtro');
    }
    if ( sessionStorage.getItem('Conta_transa_pesquisar')){
      // @ts-ignore
      this.pesquisa.texto = sessionStorage.getItem('Conta_transa_pesquisar');
    }
    if ( sessionStorage.getItem('Conta_transa_ordenar')){
      // @ts-ignore
      this.order = sessionStorage.getItem('Conta_transa_ordenar');
    }
    if ( sessionStorage.getItem('Conta_transa_order_tipo')){
      // @ts-ignore
      this.order_tipo = sessionStorage.getItem('Conta_transa_order_tipo');
    }
    if ( sessionStorage.getItem('Conta_transa_paginaatual')){
      // @ts-ignore
      this.paginaAtual = sessionStorage.getItem('Conta_transa_paginaatual');
    }


    this.getAll().subscribe(async users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
      if (this.usuarios.status){
        this.router.navigate(['login']);
      }

    });
  }
  // tslint:disable-next-line:typedef
  getAll(){
    // return this.http.get(`${environment.apiContas}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
    // tslint:disable-next-line:max-line-length
   return this.http.post(`${environment.api}/contas/getalltransacao?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);

  }
  // tslint:disable-next-line:typedef
  criarnovo(){
    this.router.navigate(['clientes/criar']);
  }

  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/contas/getalltransacao?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  proximo(pagina: number){
    this.paginaAtual = pagina;
// @ts-ignore
    sessionStorage.setItem('Conta_transa_paginaatual', this.paginaAtual);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
    console.log(pagina);
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;
    sessionStorage.setItem('Conta_transa_filtro', this.filtro);
    sessionStorage.setItem('Conta_transa_paginaatual', '1' );
    this.paginaAtual = 1;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    sessionStorage.setItem('Conta_transa_ordenar', this.order);
    this.paginaAtual = 1;
    sessionStorage.setItem('Conta_transa_paginaatual', '1' );
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    sessionStorage.setItem('Conta_transa_order_tipo', this.order_tipo);
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    sessionStorage.setItem('Conta_transa_pesquisar', this.pesquisa.texto);
    sessionStorage.setItem('Conta_transa_paginaatual', '1' );
    this.paginaAtual = 1;
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  editar(id: any){
    console.log('çasçsaçsalçs');

    this.router.navigate(['clientes/editar']);
    // @ts-ignore
    localStorage.setItem('editar_conta_dados', JSON.stringify(id));
  }
  // tslint:disable-next-line:typedef
  desativar(id: any){
    console.log(id);
    this.editarConta.id_transacao = id;
    this.editarConta.tipo_transacao = 'desativar';
    this.abrirsenha();
  }
  // tslint:disable-next-line:typedef
  ativar(id: any){
    console.log(id);
    this.editarConta.id_transacao = id;
    this.editarConta.tipo_transacao = 'ativar';
    this.abrirsenha();
  }
  // tslint:disable-next-line:typedef
  // @ts-ignore
  cadatrarsenha(): any{
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    // tslint:disable-next-line:triple-equals
    if (this.usu.novasenha.length != 6){
      this.errocamposenha.tipo = 'obrigatorio';
      this.errocamposenha.erro = true;
      return false;
    }
    // tslint:disable-next-line:triple-equals
    if (this.usu.novasenha != this.confimarsenha){
      this.errocamposenha.tipo = 'senhasdiferente';
      this.errocamposenha.erro = true;
      return false;
    }

    this.modalmudarsenha = false;
    this.modalsenhamudar = false;
    this.tiposenha = 'trocarsenha';
    this.passwordativo = true;
  }
  // tslint:disable-next-line:typedef
  abrirususenha(dados: any){
    this.usu = dados;
    this.modalmudarsenha = true;
  }

  // tslint:disable-next-line:typedef
  cancelar(){
    this.modal.erro = false;
    this.modalerro.erro = false;
    this.modalacao = false;
    this.modalmudarsenha = false;
    this.modalsenhamudar = false;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }

  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0'){
      this.tiposenha = 'desativar';
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;
    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      // tslint:disable-next-line:triple-equals
      if (this.tiposenha == 'desativar') {
           ///////////////////////////////////////////////////////
        this.desativarapi().subscribe(users => {
          // tslint:disable-next-line:triple-equals
          console.log('dddddd', users);
          // tslint:disable-next-line:triple-equals
          if (users == '1'){
            this.modal.erro = true;
            // tslint:disable-next-line:triple-equals
            if (this.editarConta.tipo_transacao == 'ativar'){
              this.modal.msg = 'Ativado com sucesso!';
            }
            // tslint:disable-next-line:triple-equals
            if (this.editarConta.tipo_transacao == 'desativar'){
              this.modal.msg = 'Desativado com sucesso!';
            }
          }else{
            this.modalerro.erro = true;
            // tslint:disable-next-line:triple-equals
            if (this.editarConta.tipo_transacao == 'ativar'){
              this.modalerro.msg = 'Erro ao Ativar, tente novamente mais tarde!';
            }
            // tslint:disable-next-line:triple-equals
            if (this.editarConta.tipo_transacao == 'desativar'){
              this.modalerro.msg = 'Erro ao Desativar, tente novamente mais tarde!';
            }
          }
        });
        this.passwordativo = false;
      }
 else{
        this.passwordativo = false;
      }
    }
    console.log( senha);
  }



  numberOnly( event: any ): boolean {
    this.errocamposenha.tipo = '';
    this.errocamposenha.erro = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // tslint:disable-next-line:typedef
  desativarapi(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/contas/transacao/mudarstatus`, this.editarConta);
  }

  // tslint:disable-next-line:typedef
  atualizarConta(tipo: string){
    console.log(tipo);
    this.tipostatus = tipo;

  }

  // tslint:disable-next-line:typedef
  mudar(tipo: any){
    console.log(this.pergunta);
    this.mudarstatus(tipo, this.pergunta).subscribe(dadosconta => {
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 1){
        this.erro = 0;
        this.tipo = 'inativo';
        this.modal.erro = true;
        this.modal.msg = 'Status alterado com sucesso.';
      }else{
        this.erro = 1;
        this.tipo = 'inativo';
        this.modalerro.erro = true;
        this.modalerro.msg = 'Erro ao  alterar, tente novamente mais tarde.';
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  mudarstatus(tipo: any, perguta: any){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiContas}/status/documentos/` + this.editarConta.id + '/' + tipo + '/' + perguta, this.editarConta);

  }
  // tslint:disable-next-line:typedef
  getdatipotrasacao(){
    return this.http.get(`${environment.api}/app/gettipostransacao`);
  }
}
