<!-- Page Wrapper -->

    <div id="wrapper">

      <!-- Sidebar -->
      <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion   " style="padding: 0; margin: 0;" [ngClass]="{'toggled': menuaberto === false}"  id="accordionSidebar">



  <div class="row" style="position: fixed; width: 220px;">
    <div class="col-md-10 col-sm-6" style="padding: 0px!important;">
      <a class="sidebar-brand d-flex align-items-center justify-content-center" style="padding-top: 30px; margin-left: 30px; " [routerLink]="['home']">

        <div *ngIf="menuaberto === false" style="color: #2019e5; font-weight: bold; font-size: 20px; margin-left: -100px; " class="sidebar-brand-icon ">
          <img width="40" src="assets/img/icon.svg" alt="">
        </div>

        <div  style="color: #2019e5; font-weight: bold; font-size: 20px; display: none; margin-left: -100px; " class="sidebar-brand-icon "  >
          <img width="40" src="assets/img/icon.svg" alt="">
        </div>


        <div *ngIf="menuaberto === true" style="color: #2019e5; font-weight: bold;
            font-size: 22px; text-transform:capitalize; text-align: center;"
             class=" sidebar-brand-text"><img width="160" src="assets/img/logo.svg" alt="">
        </div>




      </a>
    </div>
    <div class="col-md-2 col-sm-6" style="padding: 0px!important;">
      <a class=" escodericone " type="button" *ngIf="menuaberto === true"  (click)="fecharabrirmenu()"  id="sidebarToggle" style="font-size: 20px; line-height: 70px;  color: #A7A9C0; ">
        <i class="fa fa-chevron-left"  aria-hidden="true"></i>

      </a>

    </div>
  </div>
        <a class=" "  type="button" *ngIf="menuaberto === false"  (click)="fecharabrirmenu()"   style="font-size: 20px; position: fixed; z-index: 10000; top: 25px; left: 75px;   color: #A7A9C0; ">
          <i class="fa fa-chevron-right"  aria-hidden="true"></i>

        </a>



        <!-- Sidebar - Brand -->


        <div style="position: fixed; height: 90vh; top: 70px;  overflow: scroll; padding: 0!important; margin: 0;">


        <div class="sidebar-heading"  style="color: #2019e5;  font-size: 11px!important; border-top: 2px solid #EDEBEB; border-bottom: 2px solid #EDEBEB; padding-bottom: 10px; padding: 5px;">

         <span style="display: none;" [routerLinkActive]="['nomeativo']" > <span [routerLink]="['carteiras']"> </span>
           <button (click)="criarnovo('carteiras')"   class="btn btn-success btn-sm"  >New</button>
         </span>

         <span style="display: none;" [routerLinkActive]="['nomeativo']" > <span [routerLink]="['usuarios']"> </span>
           <button (click)="criarnovo('usuarios')"   class="btn btn-success btn-sm"  >New</button>
         </span>
         <span style="display: none;" [routerLinkActive]="['nomeativo']" > <span [routerLink]="['tarifas']"> </span>
           <button (click)="criarnovo('tarifas')"   class="btn btn-success btn-sm"  >New</button>
         </span>
          <span class="fonte_menu" style="color: #306C73;display: block; font-size: 20px; padding: 10px; font-weight: normal;">{{valor | currency:'BRL':true}}</span>
          <div style="text-align: left; padding: 1px 10px; width: 60%;">
            <a class="fonte_menu_a" href="" style="text-align: center; text-decoration: none; font-weight: normal; color: #A5A4AE;"> Saldo Total <i style="margin-left: 10px;" class="fas fa-arrow-right" aria-hidden="true"></i></a>
          </div>

        </div>


        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item " [routerLinkActive]="['active']" >
          <a class="nav-link" [routerLink]="['home']" style="font-size: 18px!important;">
            <i class="fas fa-fw fa-th-large" ></i>
            <span style="margin-left: 10px;">Dashboard</span></a>
        </li>

<!--          <li class="nav-item" [routerLinkActive]="['active']">-->
<!--            <a class="nav-link " href="#" [routerLink]="['/convites']" >-->
<!--              <i class="fas fa-calendar"></i>-->
<!--              <span style="margin-left: 10px;">Convites</span>-->
<!--            </a>-->

<!--          </li>-->



        <!-- Nav Item - Utilities Collapse Menu -->
<!--        <li class="nav-item" [routerLinkActive]="['active']" >-->
<!--          <a class="nav-link " [routerLink]="['/cadastros']"  href="#" >-->
<!--            <i class="fas fa-address-card  "></i>-->
<!--            <span style="margin-left: 10px;">Cadastros</span>-->
<!--          </a>-->

<!--        </li>-->
        <!-- Nav Item - Utilities Collapse Menu -->
        <li class="nav-item" [routerLinkActive]="['active']" >
          <a class="nav-link " [routerLink]="['/clientes']"  href="#" >
            <i class="fas fa-address-card  "></i>
            <span style="margin-left: 10px;">Clientes</span>
          </a>

        </li>
          <!-- Nav Item - Utilities Collapse Menu -->
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link " href="#" [routerLink]="['/transacoes']" >
              <i class="fas fa-calendar"></i>
              <span style="margin-left: 10px;">Transações</span>
            </a>

          </li>

        <!-- Nav Item - Utilities Collapse Menu -->
        <li class="nav-item" [routerLinkActive]="['active']" >
          <a class="nav-link " [routerLink]="['/beneficios']"  href="#" >
            <i class="fas fa-heart"></i>
            <span style="margin-left: 10px;">Benefícios</span>
          </a>

        </li>
        <!-- Nav Item - Utilities Collapse Menu -->
<!--        <li class="nav-item" [routerLinkActive]="['active']">-->
<!--          <a class="nav-link " [routerLink]="['/parceiros']" >-->
<!--            <i class="fas  fa-cube"></i>-->
<!--            <span style="margin-left: 10px;">Parceiros</span>-->
<!--          </a>-->

<!--        </li>-->


        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link " href="#" [routerLink]="['/carteiras']" >
            <i class="fas fa-server"></i>
            <span style="margin-left: 10px;">Carteiras</span>
          </a>

        </li>

        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link " href="#" [routerLink]="['/tarifas']" >
            <i class="fas fa-usd"></i>
            <span style="margin-left: 10px;">Tarifas</span>
          </a>

        </li>
        <!-- Nav Item - Utilities Collapse Menu -->

          <!-- Nav Item - Pages Collapse Menu -->
          <li class="nav-item " [routerLinkActive]="['active']">
            <a class="nav-link " [routerLink]="['/relatorios']" >
              <i class="fa fa-pie-chart "></i>
              <span style="margin-left: 10px;">Relatórios
</span>
            </a>

          </li>
        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading"  style="color: #2019e5; margin-top: 50px; font-size: 11px!important;">

        </div>

        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link " href="#" [routerLink]="['/usuarios']">
            <i class="fa fa-fw fa-user"></i>
            <span style="margin-left: 10px;">Usuários</span>
          </a>

        </li>

        <!-- Nav Item - Charts -->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="charts.html">-->
<!--            <i class="fas fa-fw fa-question-circle"></i>-->
<!--            <span style="margin-left: 10px;">Suporte</span></a>-->
<!--        </li>-->

        <!-- Nav Item - Tables -->
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" href="#" [routerLink]="['/config']">
            <i class="fas fa-fw fa-cog"></i>
            <span style="margin-left: 10px;">Configurações</span></a>
        </li>

        <!-- Nav Item - Tables -->
        <li style="margin-top: 20px;" >
          <a href="#" (click)="abrirmodalsair()" style="background-color: #EEEFF7; width: 80%; margin-left: 10%; font-size: 16px!important; padding: 10px 30px; margin-top: 40px!important; color: #33343D; text-decoration: none; ">
            <i  class=" fas fa-sign-out-alt"></i>
            <span style="margin-left: 10px;" >Sair</span></a>

        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">
</div>

      </ul>
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column sombra">

        <!-- Main Content -->
        <div id="content">

          <!-- Topbar -->
          <nav class="navbar navbar-expand navbar-light  topbar mb-4 static-top sombra2" >



            <!-- Sidebar Toggle (Topbar) -->
<!--            <button id="sidebarToggleTop" *ngIf="menuaberto === false"  (click)="fecharabrirmenu()"  class="btn btn-link d-md-none rounded-circle mr-3">-->
<!--              <i class="fa fa-chevron-right"  aria-hidden="true"></i>-->
<!--            </button>-->

            <!-- Topbar Search -->
            <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" style="margin-left: 0px!important;">
              <div class="input-group">
                <div class="input-group-append">



                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['home']">Dashboard</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['clientes']">clientes</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['relatorios']">Relatorios</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['beneficios']">Benefícios</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['parceiros']">Parceiros</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['carteiras']">Carteiras</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['convites']">Convites</a> </span>


                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['usuarios']">Usuarios</a> </span>


                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['tarifas']">Tarifas</a> </span>

                  <span style="font-size: 14px; margin-left: 20px; margin-top: 2px; color: #000; display: none; font-weight: bold;" [routerLinkActive]="['nomeativo']"   >
                    <a style="color: #000; font-size: 14px; text-decoration: none;" [routerLink]="['transacoes']">Transações</a> </span>


                </div>
              </div>

            </form>

            <!-- Topbar Navbar -->
            <ul class="navbar-nav ml-auto">

              <!-- Nav Item - Search Dropdown (Visible Only XS) -->
              <li class="nav-item dropdown no-arrow d-sm-none">
                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                </a>
                <!-- Dropdown - Messages -->
                <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  <form class="form-inline mr-auto w-100 navbar-search">
                    <div class="input-group">
                      <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button">
                          <i class="fas fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              <li class="nav-item dropdown no-arrow mx-1">

             <div style="background-color: #F8FAFB; border-radius: 10px; padding: 10px 20px; margin-top: 15px;">
               <div style="width: 10px; height: 10px; border-radius: 10px;  margin-top: 5px; float: left;  background-color: #4AE3B5;"></div>
               <strong style="margin-left: 5px; font-weight: bold;">Saldo total: </strong>{{valor | currency:'BRL':true}}</div>

              </li>
              <!-- Nav Item - Alerts -->
              <li class="nav-item dropdown no-arrow mx-1">
                <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" style="color: #2019e5; font-size: 18px; " role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-bell fa-fw"></i>
                  <!-- Counter - Alerts -->
<!--                  <span class="badge badge-danger badge-counter"></span>-->
                </a>
                <!-- Dropdown - Alerts -->
                <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                  <h6 class="dropdown-header">
                    Alertas
                  </h6>

<!--                  <a class="dropdown-item d-flex align-items-center" href="#">-->
<!--                    <div class="mr-3">-->
<!--                      <div class="icon-circle bg-success">-->
<!--                        <i class="fas fa-donate text-white"></i>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <div class="small text-gray-500">December 7, 2019</div>-->
<!--                      $290.29 has been deposited into your account!-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a class="dropdown-item d-flex align-items-center" href="#">-->
<!--                    <div class="mr-3">-->
<!--                      <div class="icon-circle bg-warning">-->
<!--                        <i class="fas fa-exclamation-triangle text-white"></i>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <div class="small text-gray-500">December 2, 2019</div>-->
<!--                      Spending Alert: We've noticed unusually high spending for your account.-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>-->
                </div>
              </li>

              <!-- Nav Item - Messages -->


              <div class="topbar-divider d-none d-sm-block"></div>

              <!-- Nav Item - User Information -->
              <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{users.name}}</span>
                  <img class="img-profile rounded-circle" src="{{url}}{{users.avatar}}">
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Configurações
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" (click)="abrirmodalsair()">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Sair
                  </a>
                </div>
              </li>

            </ul>

          </nav>
          <!-- End of Topbar -->

          <!-- Begin Page Content -->
          <div class="container-fluid" style="margin-top: 10px;">

            <router-outlet></router-outlet>
          </div>
          <!-- /.container-fluid -->

        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright &copy; <a href="https://bankis.com.br" target="_blank"> Bankis </a>  {{getano()}}</span>
            </div>
          </div>
        </footer>
        <!-- End of Footer -->

      </div>
      <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>

<!-- Logout Modal-->
<div *ngIf="modalsair == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #4AD9A4; color: #FFF;">
        <h2 class="modal-title" id="exampleModalLabel">Tem certeza que pretende sair?</h2>
        <button class="close" type="button" (click)="cancelarsair()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" (click)="cancelarsair()">Cancelar</button>
        <a class="btn " style="background-color: #4AD9A4; color: #FFF;" (click)="sair()">Sair</a>
      </div>
    </div>
  </div>
</div>


