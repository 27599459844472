<!-- Começo do código da tabela -->
<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por cliente, número da conta, cartão de crédito...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>
    <button (click)="fitrar('bloqueadas')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Bloqueados</button>
  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >
      <td  *ngIf="order == 'id' && order_tipo == 'asc' || order != 'id'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'id' && order_tipo == 'desc'">ID <span  (click)="ordenar('id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'nome' && order_tipo == 'asc' || order != 'nome'">NOME <span (click)="ordenar('nome')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'nome' && order_tipo == 'desc'">NOME <span (click)="ordenar('nome')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



      <td  *ngIf="order == 'cities.name' && order_tipo == 'asc' || order != 'cities.name'" >CIDADE <span (click)="ordenar('cities.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'cities.name' && order_tipo == 'desc'" >CIDADE <span (click)="ordenar('cities.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>



      <td  *ngIf="order == 'states.abbreviation' && order_tipo == 'asc' || order != 'states.abbreviation'">ESTADO <span (click)="ordenar('states.abbreviation')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'states.abbreviation' && order_tipo == 'desc'">ESTADO <span (click)="ordenar('states.abbreviation')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


      <td  *ngIf="order == 'site' && order_tipo == 'asc' || order != 'site'">WEBSITE <span (click)="ordenar('site')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'site' && order_tipo == 'desc'">WEBSITE <span (click)="ordenar('site')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td >{{usuario.id}}</td>
      <td>

        <img *ngIf="usuario.avatar != null" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{usuario.avatar}}">
        <img *ngIf="usuario.avatar == null" class="img-profile rounded-circle" style="width: 40px; height: 40px;" src="{{url}}{{avatar_padrao}}"> <span style="margin-left: 10px;">{{usuario.nome}}</span>

      </td>
      <td>{{usuario.nome_cidade}}</td>

      <td><span style="=">{{usuario.nome_estado }}</span></td>
      <td><span style="=">{{usuario.site}}</span></td>

      <td>
        <button *ngIf="usuario.status == 'ativo'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Ativo</span></button>
        <button *ngIf="usuario.status == 'bloqueadas'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red;"></div> <span style="margin-left: 10px; font-size: 12px;">Bloqueado</span></button>
      </td>
      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a (click)="editar(usuario)" class="dropdown-item" >Editar</a>

          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>

      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>


      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>

</div>
