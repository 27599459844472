import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-editar-beneficios',
  templateUrl: './editar-beneficios.component.html',
  styleUrls: ['./editar-beneficios.component.css'],
  providers: [DatePipe]
})
export class EditarBeneficiosComponent implements OnInit {



  name = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  beneficio_tipo = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  periodicidade = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  fatorGerador = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  valorFator = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  diaPagamento = new FormControl('', [
    Validators.required,
    Validators.max(28)
  ]);
  // tslint:disable-next-line:variable-name
  mesPagamento = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  datafimpagamanto = new FormControl('', [
    Validators.required,
  ]);
  // @ts-ignore


  model: NgbDateStruct | any;
  avatar: any =  '../../assets/img/avatar.jpg';
  erroform: any =  '0';
  usuarios: any = '';
  imglogo: any = 'assets/logo_white_bankis.svg';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = { password: '', name: '', beneficio_tipo: 1, periodicidade: 'Mensal', fatorGerador: 'Mensal', valorFator: '', logoCartao: '', status: 'ativo', prazoTermino: 'Sim', diaPagamento: '', mesPagamento: '', datafimpagamanto: ''};
  cidades: any;
  erro: any = 0;
  modal = false;
  modalerro = false;
  passwordativo = false;


  // tslint:disable-next-line:variable-name
  tipobeneficios: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient,
              private datePipe: DatePipe, private router: Router) {
  }
  ngOnInit(): void {
    // @ts-ignore
    this.editarConta = JSON.parse(  localStorage.getItem('beneficio' )) ;
    this.imglogo = this.editarConta.logoCartao;
    console.log(this.editarConta );
    this.url = environment.url;
    // @ts-ignore
    console.log(this.teste);
    // this.editarConta.avatar =  this.url +  this.editarConta.avatar;
    this.editarConta.datafimpagamanto = this.datePipe.transform(this.editarConta.datafimpagamanto, 'dd/MM/yyyy');
    // tslint:disable-next-line:triple-equals

    this.getbeneficiostipo().subscribe(dadosbene => {
      console.log(dadosbene);
      this.tipobeneficios = dadosbene;
    });

  }
  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    console.log(this.name.hasError('required'));
    if (this.name.hasError('required')){ this.erroform = '1'; }
    if (this.beneficio_tipo.hasError('required')){ this.erroform = '1'; }
    if (this.periodicidade.hasError('required')){ this.erroform = '1'; }
    if (this.fatorGerador.hasError('required')){ this.erroform = '1'; }
    if (this.valorFator.hasError('required')){ this.erroform = '1'; }
    if (this.diaPagamento.hasError('required')){ this.erroform = '1'; }
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.periodicidade == 'Anual'){
      if (this.mesPagamento.hasError('required')){ this.erroform = '1'; }
    }
    if (this.datafimpagamanto.hasError('required')){ this.erroform = '1'; }
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.logoCartao == ''){ this.erroform = '2'; }
    if (this.editarConta.diaPagamento > 28){ this.erroform = '1'; }
    console.log(this.erroform);
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0'){
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;


    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      this.atualizarConta();
      this.passwordativo = false;
    }
    console.log( senha);
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imglogo = myReader.result;
      this.editarConta.logoCartao = myReader.result;
      this.erroform = '0';
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }





  // tslint:disable-next-line:typedef
  atualizarConta(){


    console.log(this.erroform);
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0') {
      this.enviarDadosConta().subscribe(dadosconta => {
        // console.log(dadosconta);
        // tslint:disable-next-line:triple-equals
        if ( dadosconta == 0){
          this.modal = false;
          this.modalerro = true;
        }else{
          this.modal = true;
          this.modalerro = false;
          // this.router.navigate(['beneficios']);
        }
        // tslint:disable-next-line:triple-equals
      });
    }
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/beneficios/update`, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  getbeneficiostipo(){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/beneficios/getbeneficiotipo`);
  }

  // tslint:disable-next-line:typedef
  cancelar(){

    // tslint:disable-next-line:max-line-length
    this.router.navigate(['beneficios']);
  }

  // tslint:disable-next-line:typedef
  fechar(){
    this.modalerro = false;
  }


}
