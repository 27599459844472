import { Component, OnInit } from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-criarusuarios',
  templateUrl: './criarusuarios.component.html',
  styleUrls: ['./criarusuarios.component.css'],
  providers: [DatePipe]
})
export class CriarusuariosComponent implements OnInit {



  model: NgbDateStruct | any;

  usuarios: any = '';
  confimarsenha: any = '';
  errosenha: any = 1;
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {id: '', name: '', aniversario: '', email: '', avatar: '', telefone: '', nacionalidade: '', sexo: '', cep: '', cpf: '', estado: '1', cidade: '', password: ''};
  cidades: any;
  erro: any = 0;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, private datePipe: DatePipe, private router: Router) { }

  ngOnInit(): void {
    this.url = environment.url;


    this.editarConta.aniversario = this.datePipe.transform(this.editarConta.aniversario, 'dd/MM/yyyy');
    // tslint:disable-next-line:triple-equals


    this.editarConta.avatar = this.url + 'img/avatar/avatar.jpg';


    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals


    });



    this.getestados().subscribe(estados => {
      this.dado_estados = estados;
      // tslint:disable-next-line:triple-equals


    });
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.editarConta.avatar = myReader.result;
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }
  // // tslint:disable-next-line:typedef
  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //
  //     reader.onload = (ev: any) => {
  //       this.editarConta.avatar = ev.target.result;
  //     };
  //    // reader.readAsDataURL(event.target.files[0]);

  //   }
  // }

  // tslint:disable-next-line:typedef
  getcidade(){
    return this.http.get(`${environment.api}/cidades/` + this.editarConta.estado );
  }

  // tslint:disable-next-line:typedef
  postavatar(){
    return this.http.post(`${environment.apiUsuarios}/avatar` , this.editarConta );
  }
  // tslint:disable-next-line:typedef
  getuser(){
    return this.http.get(`${environment.apiUsuarios}/editar/` + this.editarConta.estado );
  }
  // tslint:disable-next-line:typedef
  getestados(){
    return this.http.get(`${environment.api}/estados` );
  }
  // tslint:disable-next-line:typedef
  onAddCidade(){
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }

  // tslint:disable-next-line:typedef
  atualizarConta(){
    // tslint:disable-next-line:triple-equals
        if ( this.editarConta.password != this.confimarsenha){
              this.errosenha = 0;
              console.log(this.editarConta.password);
              console.log(this.confimarsenha);
        }else{
                    this.enviarDadosConta().subscribe(dadosconta => {
                      console.log(dadosconta);
                      // tslint:disable-next-line:triple-equals
                      if ( dadosconta == 0){
                        this.erro = 1;
                      }else{
                        this.erro = 0;
                        localStorage.setItem('editar_conta_dados', JSON.stringify(dadosconta));
                        this.router.navigate(['usuarios/permissoes']);
                      }
                      // tslint:disable-next-line:triple-equals
                    });
        }

  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiUsuarios}/store`, this.editarConta);
  }


}
