import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient, private router: Router) { }

  // tslint:disable-next-line:typedef
  async login(user: any) {
    const result = await this.http.post<any>(`${environment.api}/authenticate`, user).toPromise();
    console.log(result);
    if (result && result.token) {
      window.localStorage.setItem('token', result.token);

      return true;
    }

    if(result == '2fa'){
      return '2fa';
    }


    return false;
  }
    // tslint:disable-next-line:typedef
  async validar(user: any) {
    const result = await this.http.post<any>(`${environment.api}/authenticar2fa`, user).toPromise();
    console.log(result);
    if (result && result.token) {
      window.localStorage.setItem('token', result.token);

      return true;
    }


    return false;
  }




  // tslint:disable-next-line:typedef
  async refress() {
    const result = await this.http.get<any>(`${environment.api}/refresh`).toPromise();
    console.log(result);
    if (result && result.token) {
      window.localStorage.setItem('token', result.token);
      return true;
    }

    return false;
  }

  // async createAccount(account: any) {
  //   const result = await this.http.post<any>(`${environment.api}/users`, account).toPromise();
  //   return result;
  // }

  // tslint:disable-next-line:typedef
  getAuthorizationToken() {
    const token = window.localStorage.getItem('token');
    return token;
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }


    const date  = this.getTokenExpirationDate(token);
    if (date === undefined) {
      console.log('false');

      return false;
    }else{
      console.log('verdadeiro');
      // @ts-ignore
      if (date.valueOf() > new Date().valueOf()){
        return false;
     }else{
        return true;
      }
    }


  }

  // tslint:disable-next-line:typedef
  isUserLoggedIn() {
    const token = this.getAuthorizationToken();
    console.log(token, 'madara');
    if (!token) {
      return false;
    } else if (this.isTokenExpired(token)) {
      return false;
    }

    return true;
  }
}
