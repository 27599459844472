import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-novacarteiras',
  templateUrl: './novacarteiras.component.html',
  styleUrls: ['./novacarteiras.component.css']
})
export class NovacarteirasComponent implements OnInit {



pagina: any = 'Editar';
  usuarios: any = '';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = {nome: '', email: '', telefone: '', cidade_id: '', estado_id: '', status: '', cnpj: '', site: '', cep: '', endereco: '', bairro: '', numero: '', corPrimaria: '', corSecundaria: '', versaoAppAndroid: '', versaoAppIos: '', avatar: ''};
  cidades: any;
  erro: any = 0;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient,  private router: Router) { }

  ngOnInit(): void {






    this.url = environment.url;
    // @ts-ignore

    this.editarConta.avatar =  this.url ;
    console.log(this.editarConta);

    // tslint:disable-next-line:triple-equals
    if (this.editarConta.avatar == this.url){

      this.editarConta.avatar = this.url + 'img/avatar/avatar.jpg';
    }





    this.getestados().subscribe(estados => {
      this.dado_estados = estados;
      // tslint:disable-next-line:triple-equals


    });
    // @ts-ignore
    document.getElementById('editarp').style.display = 'none';
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.editarConta.avatar = myReader.result;
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }
  // // tslint:disable-next-line:typedef
  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //
  //     reader.onload = (ev: any) => {
  //       this.editarConta.avatar = ev.target.result;
  //     };
  //    // reader.readAsDataURL(event.target.files[0]);

  //   }
  // }

  // tslint:disable-next-line:typedef
  getcidade(){
    return this.http.get(`${environment.api}/cidades/` + this.editarConta.estado_id );
  }

  // tslint:disable-next-line:typedef
  postavatar(){
    return this.http.post(`${environment.apiCarteiras}/avatar` , this.editarConta );
  }
  // tslint:disable-next-line:typedef
  getuser(){
    return this.http.get(`${environment.apiCarteiras}/editar/` + this.editarConta.estado_id );
  }
  // tslint:disable-next-line:typedef
  getestados(){
    return this.http.get(`${environment.api}/estados` );
  }
  // tslint:disable-next-line:typedef
  onAddCidade(){
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }

  // tslint:disable-next-line:typedef
  atualizarConta(regForm: NgForm){



    this.enviarDadosConta().subscribe(dadosconta => {
      console.log(this.editarConta);
      // tslint:disable-next-line:triple-equals
      if ( dadosconta == 0){
        this.erro = 1;
      }else{
        this.erro = 0;
        this.router.navigate(['carteiras']);
      }
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiCarteiras}/store`, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  mudarpagina(pag: any, regForm: NgForm){
if (regForm.valid){


   this.pagina = pag;
    // tslint:disable-next-line:prefer-const

    // tslint:disable-next-line:triple-equals
   if (pag == 'Editar'){
     // @ts-ignore
      document.getElementById('editar').style.display = 'block';
     // @ts-ignore
      document.getElementById('editarp').style.display = 'none';
    }
    // tslint:disable-next-line:triple-equals
   if (pag == 'Editarp'){
     // @ts-ignore
      document.getElementById('editarp').style.display = 'block';
     // @ts-ignore
      document.getElementById('editar').style.display = 'none';
    }
}

}

// tslint:disable-next-line:typedef
Registers(regForm: NgForm){

}
}
