import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Router} from '@angular/router';
import {AccountService} from '../../account/shared/account.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  usu = {password: '', email: '',code:''};
  errologin = { erro: false, msg: ''  };

  senhaControlmail = new FormControl('', [
    Validators.required,
  ]);
  matcher = new MyErrorStateMatcher();

    is2fa = false;

  constructor( private accountService: AccountService, private router: Router) { }

  ngOnInit(): void {
    window.localStorage.removeItem('token');
  }

  // tslint:disable-next-line:typedef
   mostrarSenha(input: any): any {
     input.type = input.type === 'password' ? 'text' : 'password';
   }

  // tslint:disable-next-line:typedef
  async logar(){

    try {
      const result = await this.accountService.login(this.usu);
      console.log(`Login efetuado: ${result}`);
  // @ts-ignore
      // tslint:disable-next-line:triple-equals
      console.log(result);


      if ( result == false){
        console.log('true');
        this.errologin.erro = true;
        this.errologin.msg = 'Usuario ou senha Incorreto.';

      }
      else if(result == '2fa'){

        this.is2fa = true;

      }else{   console.log('false'); }


    } catch (error) {
      console.log(error);
    }


   }


   async validar(){

    try {
      const result = await this.accountService.validar(this.usu);
      console.log(`Login efetuado: ${result}`);
  // @ts-ignore
      // tslint:disable-next-line:triple-equals
      console.log(result);


      if ( result == false){
        console.log('true');
        this.errologin.erro = true;
        this.errologin.msg = 'Código não confere';

      }else{   console.log('false'); }

      // navego para a rota vazia novamente
      this.router.navigate(['']);
    } catch (error) {
      console.log(error);
    }


   }
}
