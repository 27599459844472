

<div id="exampleModalpassword" (click)="irCampo()" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="padding: 15px;">
      <div class="modal-header " style="color: #33343E; border-bottom: none!important;" >
<h2 style="width: 100%;">Digite sua senha</h2>
<p style="width: 100%;">Use sua senha de 6 dígitos para continuar</p>
        <button type="button" class="close" (click)="feedback('cancelar')" style="top: 10px; right: 10px; position: absolute;" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: left;">
        <input autofocus  [(ngModel)]="register.password" #yourInput  style="color: #fff; border:none;" maxlength="6" (keyup)="mudar($event,yourInput)"   type="tel" autocomplete="off"  >

        <div  class="row" style="margin-bottom: 30px; padding: 30px;">
          <div class="col-2" >
            <div class="centrazarbol"  style="height: 60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 1" style="width: 10px; height: 10px;  border-radius: 50%; background-color: black;"></div>
            </div>
          </div>
          <div class="col-2" >
            <div class="centrazarbol"  style="height: 60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 2" style="width: 10px; height: 10px;  border-radius: 50%; background-color: black;"></div>
            </div>
          </div>
          <div class="col-2" >
            <div class="centrazarbol"  style="height: 60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 3" style="width: 10px; height: 10px; border-radius: 50%; background-color: black;"></div>
            </div>
          </div>
          <div class="col-2" >
            <div class="centrazarbol"  style="height: 60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 4" style="width: 10px; height: 10px;  border-radius: 50%; background-color: black;"></div>
            </div>
          </div>
          <div class="col-2" >
            <div class="centrazarbol"  style="height: 60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 5" style="width: 10px; height: 10px; border-radius: 50%; background-color: black;"></div>
            </div>
          </div>
          <div class="col-2" >
            <div class="centrazarbol"  style="height:60px; width: 100%; border-radius: 10px; border: 2px solid #7A869A;">
              <div  *ngIf="tamanho >= 6" style="width: 10px; height: 10px; border-radius: 50%; background-color: black;"></div>
            </div>
          </div >
          <div *ngIf="erroname.erro === true" style="color: red; font-size: 12px;">A senha não confere. </div>
        </div>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <a  class="btn btn-primary" (click)="feedback('confimar')"   style=" padding: 10px 20px; color: #FFF;  background-color: #306C73;" >
          Confirmar
        </a>
        <a class="btn "  (click)="feedback('cancelar')" style=" padding: 10px; color: #FFF;  color: red;">Cancelar</a>

      </div>
    </div>
  </div>
</div>
