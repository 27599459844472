import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {


  constructor(private http: HttpClient, private router: Router) { }
  public paginaAtual = 1;
  public filtro = 'tudo';
  pesquisa = {texto: ''};
  usuarios: any = {current_page: 1, last_page: 1, data: []};
  url: any;
  urlbandeira: any;
  // tslint:disable-next-line:variable-name
  avatar_padrao: any;
  // tslint:disable-next-line:variable-name
  cartao_bandeira_padrao: any = 'img/bandeiras/banco_padrao.png';
  order: any = 'id';
  // tslint:disable-next-line:variable-name
  order_tipo: any = 'asc';
  ngOnInit(): void {
    this.avatar_padrao = 'img/avatar/avatar.jpg';

    this.url = environment.url + 'api/app/img/';
    this.urlbandeira = environment.url;
    this.getAll().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
      if (this.usuarios.status){
        this.router.navigate(['login']);
      }
      console.log(this.usuarios);
    });
  }
  // tslint:disable-next-line:typedef
  getAll(){
    return this.http.get(`${environment.apiUsuarios}/getall?` + 'order=' + this.order + '&order_tipo=' + this.order_tipo);
  }


  // tslint:disable-next-line:typedef
  getfiltro(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.apiUsuarios}/getall?page=` + this.paginaAtual + '&status=' + this.filtro + '&order=' + this.order + '&order_tipo=' + this.order_tipo, this.pesquisa);
  }

  // tslint:disable-next-line:typedef
  counter(i: number) {
    return new Array(i);
  }
  // tslint:disable-next-line:typedef
  async proximo(pagina: number){
    this.paginaAtual = pagina;
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
      console.log(this.usuarios.current_page);
    });
  }

  // tslint:disable-next-line:typedef
  fitrar( filtro: string){
    this.filtro = filtro;

    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });


  }
  // tslint:disable-next-line:typedef
  ordenar(event: any){
    this.order = event;
    // tslint:disable-next-line:triple-equals
    if (this.order_tipo == 'asc'){
      this.order_tipo = 'desc';
    }else{
      this.order_tipo = 'asc';
    }
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });
  }
  // tslint:disable-next-line:typedef
  async pesquisar(event: any) {
    console.log(this.pesquisa.texto);
    // tslint:disable-next-line:max-line-length
    this.getfiltro().subscribe(users => {
      this.usuarios = users;
      // tslint:disable-next-line:triple-equals
    });

  }
  // tslint:disable-next-line:typedef
  editar(id: any){
    console.log('çasçsaçsalçs');

    this.router.navigate(['usuarios/editar']);
    // @ts-ignore
    localStorage.setItem('editar_conta_dados', JSON.stringify(id));
  }

}
