import { Component, ViewChild, ElementRef, OnInit, Input, EventEmitter} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { map, filter, scan } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { DatePipe } from '@angular/common'; // import do datePipe
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ErrorStateMatcher} from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-criar-conta',
  templateUrl: './criar-conta.component.html',
  styleUrls: ['./criar-conta.component.css'],
  providers: [DatePipe]
})
export class CriarContaComponent implements OnInit {



  name = new FormControl('', [
    Validators.required,
  ]);

  cpf = new FormControl('', [
    Validators.required,
  ]);

  nomeMae = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  email = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  // tslint:disable-next-line:variable-name
  telefone = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  sexo = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  nascimento = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  profissao = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  datafimpagamanto = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  cep = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  cidade = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  estado = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  endereco = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  password = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  passwordc = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  bairro = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  numero = new FormControl('', [
    Validators.required,
  ]);
  // @ts-ignore

  // tslint:disable-next-line:max-line-length
  errosgeral: any = {cpf: [], cnpj: [], email: [], password: [], razaoSocial: [], nomeFatasia: [], endereco: [], numero: [], bairro: [], cidade: [], estado: [], sexo: [], cep: [], nascimento: [], telefone: [] , nomeMae: [] } ;

  model: NgbDateStruct | any;
  avatar: any =  '../../assets/img/avatar.jpg';
  erroform: any =  '0';
  usuarios: any = '';
  imglogo: any = 'assets/logo_white_bankis.svg';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = { password: '', passwordc: '', name: '', bairro: '', numero: '', email: '', telefone: '', sexo: 'm', nascimento: '', nacionalidade: 'BR', profissao: 'Servidor Público', cep: '', cidade: '', estado: '', endereco: '', nomeMae: ''};
  cidades: any;
  erro: any = 0;
  errocep: any = false;
  erroformsenha: any = '0';
  erroformcidade: any = '0';
  erroestado: any = '0';
  modal = false;
  modalerro = false;
  passwordativo = false;
  getdadoscep: any = {logradouro: '', bairro: ''};


  // tslint:disable-next-line:variable-name
  tipobeneficios: any ;
  private person: any;
  // tslint:disable-next-line:variable-name
  dado_estados: any ;
  constructor(private route: ActivatedRoute, private http: HttpClient,
              private datePipe: DatePipe, private router: Router) {
  }
  ngOnInit(): void {
    this.url = environment.url;
    // @ts-ignore
    console.log(this.teste);
    // this.editarConta.avatar =  this.url +  this.editarConta.avatar;
    // this.editarConta.nascimento = this.datePipe.transform(this.editarConta.nascimento, 'dd/MM/yyyy');
    // tslint:disable-next-line:triple-equals

    this.getbeneficiostipo().subscribe(dadosbene => {
      console.log(dadosbene);
      this.tipobeneficios = dadosbene;
    });
    this.getestados().subscribe(estados => {
      this.dado_estados = estados;
      // tslint:disable-next-line:triple-equals


    });
  }

  // tslint:disable-next-line:typedef
  onAddCidade(){
    this.getcidade().subscribe(cidade => {
      this.cidades = cidade;
      // tslint:disable-next-line:triple-equals
      console.log(this.editarConta);

    });

  }
  // tslint:disable-next-line:typedef
  // @ts-ignore
  // tslint:disable-next-line:typedef
  async getcep(event: any, input: any) {

console.log(this.editarConta.cep.length);
console.log(this.editarConta.cep);
      // eslint-disable-next-line eqeqeq
    // tslint:disable-next-line:triple-equals
if (this.editarConta.cep.length != 8){
        this.errocep = true;
        return false;
      }else{
        this.errocep = false;
      }

if ( input.value.length === 9 ){
      // @ts-ignore
        this.getcepdados(input.value).subscribe(async dados => {
        this.getdadoscep = dados;
          // tslint:disable-next-line:triple-equals
        this.editarConta.endereco = this.getdadoscep.logradouro;
        if (this.editarConta.endereco.length == null){this.editarConta.endereco = ''; }
        this.editarConta.bairro = this.getdadoscep.bairro;
        if (this.editarConta.bairro.length == null){this.editarConta.bairro = ''; }
        this.editarConta.estado = this.getdadoscep.uf;
        this.getcidade().subscribe(cidade => {
            this.cidades = cidade;
            // tslint:disable-next-line:triple-equals
            console.log(this.cidades);
            this.editarConta.cidade = this.getdadoscep.ibge;
          });
        });
    }

  }
  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    this.erroformsenha = '0';
    this.erroformcidade = '0';
    console.log(this.editarConta);
    if (this.name.hasError('required')){ this.erroform = '1'; }
    if (this.email.hasError('required')){ this.erroform = '1'; }
    if (this.telefone.hasError('required')){ this.erroform = '1'; }
    if (this.sexo.hasError('required')){ this.erroform = '1'; }
    if (this.nascimento.hasError('required')){ this.erroform = '1'; }
    if (this.profissao.hasError('required')){ this.erroform = '1'; }
    if (this.cep.hasError('required')){ this.erroform = '1'; }
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.cidade == ''){ this.erroform = '1'; this.erroformcidade = '1'; }
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.estado == ''){ this.erroform = '1';  this.erroestado = '1'; }
    if (this.endereco.hasError('required')){ this.erroform = '1'; }
    if (this.password.hasError('required')){ this.erroform = '1'; }
    if (this.bairro.hasError('required')){ this.erroform = '1'; }
    if (this.numero.hasError('required')){ this.erroform = '1'; }
    if (this.passwordc.hasError('required')){ this.erroform = '1'; }
    // tslint:disable-next-line:triple-equals
    if (this.editarConta.passwordc != this.editarConta.password){ this.erroform = '1'; this.erroformsenha = '1'; }


    console.log(this.erroform);
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0'){
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;


    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      this.atualizarConta();
      this.passwordativo = false;
    }
    console.log( senha);
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imglogo = myReader.result;
      this.editarConta.logoCartao = myReader.result;
      this.erroform = '0';
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }





  // tslint:disable-next-line:typedef
  atualizarConta(){


    console.log(this.erroform);
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0') {
      // @ts-ignore
      this.enviarDadosConta().subscribe(async result => {
        // console.log(dadosconta);
        // @ts-ignore
        if(result.erros){
          this.modal = false;
          this.modalerro = true;
          // @ts-ignore
          this.errosgeral = result.erros;
          return false;
        }else{
          this.modal = true;
          this.modalerro = false;
        }
      });
    }
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/contas/create`, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  getcepdados(cep: any){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/app/getcep/${cep}`);
  }
  // tslint:disable-next-line:typedef
  getbeneficiostipo(){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/appdados/beneficios/getbeneficiotipo`);
  }

  // tslint:disable-next-line:typedef
  cancelar(){
    this.modalerro = false;
    this.modal = false;
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['cadastros']);
  }
  // tslint:disable-next-line:typedef
  getcidade(){
    return this.http.get(`${environment.api}/app/cidades/` + this.editarConta.estado );
  }
  // tslint:disable-next-line:typedef
  getestados(){
    return this.http.get(`${environment.api}/estados` );
  }
  // tslint:disable-next-line:typedef
  fechar(){
    this.modalerro = false;
    this.modal = false;
  }
  numberOnly( event: any ): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
